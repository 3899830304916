@charset "UTF-8";
@font-face {
  font-family: "ArticulatCF";
  src: url(fonts/articulatcf-light.woff2) format("woff2"), url(fonts/articulatcf-light.woff) format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Adobe Clean Reg';
  src: url("../assets/fonts/AdobeClean-Regular.eot");
  src: url("../assets/fonts/AdobeClean-Regular.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/AdobeClean-Regular.woff2") format("woff2"), url("../assets/fonts/AdobeClean-Regular.woff") format("woff"), url("../assets/fonts/AdobeClean-Regular.ttf") format("truetype"), url("../assets/fonts/AdobeClean-Regular.svg#AdobeClean-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Paypal Big Light';
  src: url("../assets/fonts/paypal-big-light.eot");
  src: url("../assets/fonts/paypal-big-light.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/paypal-big-light.woff2") format("woff2"), url("../assets/fonts/paypal-big-light.woff") format("woff"), url("../assets/fonts/paypal-big-light.ttf") format("truetype"), url("../assets/fonts/paypal-big-light.svg#paypal-big-light") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Paypal Big Regular';
  src: url("../assets/fonts/paypal-big-reg.eot");
  src: url("../assets/fonts/paypal-big-reg.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/paypal-big-reg.woff2") format("woff2"), url("../assets/fonts/paypal-big-reg.woff") format("woff"), url("../assets/fonts/paypal-big-reg.ttf") format("truetype"), url("../assets/fonts/paypal-big-reg.svg#paypal-big-reg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

.footer {
  max-width: 1095px;
  width: 90%;
  margin: 0 auto;
  padding: 4rem 0; }
  .footer .columns {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    padding-bottom: 7rem; }
    .footer .columns .col1 {
      display: -ms-flexbox;
      display: flex; }
      .footer .columns .col1 .left {
        margin-right: 3rem; }
  .footer h3 {
    font-size: 16px;
    padding-bottom: 1.5rem; }
  .footer ul {
    list-style-type: none; }
    .footer ul li {
      margin-bottom: 0.35rem;
      color: #3A3A3A;
      font-size: 14px; }
      .footer ul li a {
        text-decoration: none;
        color: #3A3A3A;
        font-size: 14px;
        transition: all 0.3s ease; }
        .footer ul li a:hover {
          color: #000000;
          transition: all 0.3s ease; }
  .footer .nav .topNav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    border-bottom: 1px solid #3A3A3A;
    padding-bottom: 0.35rem; }
    .footer .nav .topNav ul {
      display: -ms-flexbox;
      display: flex; }
      .footer .nav .topNav ul li {
        margin-right: 1.5rem; }
        .footer .nav .topNav ul li a {
          font-weight: bold; }
        .footer .nav .topNav ul li:last-of-type {
          margin: 0; }
      @media screen and (max-width: 1050px) {
        .footer .nav .topNav ul {
          -ms-flex-pack: center;
              justify-content: center;
          -ms-flex-order: 2;
              order: 2; } }
    .footer .nav .topNav .flag {
      background: transparent url("../images/paypal/uk-flag.jpg") no-repeat center;
      background-size: 100%;
      display: inline-block;
      overflow: hidden;
      width: 32px;
      height: 26px;
      padding: 0;
      margin: 0;
      vertical-align: middle;
      text-indent: 999em;
      text-align: left;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center; }
      .footer .nav .topNav .flag a {
        display: block;
        height: 100%;
        width: 100%; }
    @media screen and (max-width: 1050px) {
      .footer .nav .topNav {
        -ms-flex-direction: column;
            flex-direction: column; } }
  .footer .nav .bottomNav {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -ms-flex-pack: justify;
        justify-content: space-between;
    padding-top: 0.35rem; }
    .footer .nav .bottomNav ul {
      display: -ms-flexbox;
      display: flex; }
      .footer .nav .bottomNav ul li {
        margin-right: 1.5rem;
        font-weight: bold; }
        .footer .nav .bottomNav ul li a {
          font-weight: bold; }
        .footer .nav .bottomNav ul li:last-of-type {
          margin: 0; }
      @media screen and (max-width: 1050px) {
        .footer .nav .bottomNav ul {
          -ms-flex-pack: center;
              justify-content: center; } }
    @media screen and (max-width: 1050px) {
      .footer .nav .bottomNav {
        -ms-flex-direction: column;
            flex-direction: column; } }

div.masthead .container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  max-width: 1095px;
  width: 97%;
  margin: 0 auto;
  padding: 1rem 0; }
  div.masthead .container .logo {
    max-width: 140px; }
    div.masthead .container .logo a {
      width: 100%;
      display: block; }
      div.masthead .container .logo a img {
        width: 100%; }
  div.masthead .container .button {
    background: #0070BA;
    color: white;
    text-decoration: none;
    border-radius: 1.5rem;
    padding: 0.5rem 1.75rem;
    font-size: 16px;
    font-family: 'Paypal Big Regular';
    transition: all 0.3s ease; }
    div.masthead .container .button:hover {
      background: #014d7f;
      transition: all 0.3s ease; }

/*!
 * applauncher v0.65.0 built on Tue, 15 Mar 2022 16:50:21 GMT
 *
 */
#feds-topnav .applauncher-element {
  display: none; }

@media screen and (min-width: 900px) {
  #feds-topnav .applauncher-element {
    display: -ms-flexbox;
    display: flex; } }

@media screen and (min-width: 600px) {
  #feds-header:not(.feds--smallDesktopEnabled) #feds-topnav .applauncher-element {
    display: -ms-flexbox;
    display: flex; } }

#feds-topnav .applauncher-element .app-launcher-container {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex; }

#feds-topnav .applauncher-element .app-launcher-container .react-spectrum-provider {
  position: static; }

html[dir=rtl] #feds-topnav .applauncher-element {
  display: none; }

#feds-topnav .feds-appLauncher .app-launcher-overlay-container {
  left: 0 !important;
  right: 0;
  top: 0 !important;
  width: auto; }

#feds-topnav .feds-appLauncher .app-launcher-popover {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  overflow: hidden; }

#feds-topnav .feds-appLauncher .spectrum-Popover-tip {
  display: none; }

#feds-topnav .feds-appLauncher.feds-focus-ring {
  border-color: transparent !important;
  outline: 2px solid #109cde;
  outline-offset: 2px; }

#feds-topnav:nth-last-child(2) .applauncher-element {
  position: relative; }

#feds-topnav:nth-last-child(2) .feds-appLauncher .app-launcher-popover {
  left: auto !important;
  margin-top: 0;
  max-height: calc(100% - 60px) !important;
  right: 0 !important;
  top: 60px !important;
  -ms-transform: none;
      transform: none; }

/*!
 * search v0.65.0 built on Tue, 15 Mar 2022 16:50:21 GMT
 *
 */
#feds-topnav .feds-search-template {
  display: none; }

#feds-topnav .feds-search {
  -webkit-font-smoothing: antialiased;
  -ms-flex-align: center;
      align-items: center;
  background-color: #fff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  font-family: inherit;
  padding: 20px;
  z-index: 11; }

#feds-topnav .feds-search.feds-search-rebrand {
  font-family: inherit; }

#feds-topnav .feds-search.has-results ~ [data-feds-keyboardnavigationenabled] {
  display: none; }

#feds-topnav .feds-search.has-noResults .feds-searchForm, #feds-topnav .feds-search.has-results .feds-searchForm {
  border-radius: 3px 3px 0 0; }

@media screen and (min-width: 1200px) {
  #feds-topnav .feds-search {
    display: none;
    left: 50%;
    padding: 20px 30px;
    position: absolute;
    top: 100%;
    -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    width: 100%; }
  #feds-topnav .feds-search.has-results ~ [data-feds-keyboardnavigationenabled], #feds-topnav .feds-search.is-open {
    display: -ms-flexbox;
    display: flex; } }

@media screen and (min-width: 900px) {
  .feds--smallDesktopEnabled #feds-topnav .feds-search {
    display: none;
    left: 50%;
    padding: 20px 30px;
    position: absolute;
    top: 100%;
    -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    width: 100%; }
  .feds--smallDesktopEnabled #feds-topnav .feds-search.has-results + [data-feds-keyboardnavigationenabled], .feds--smallDesktopEnabled #feds-topnav .feds-search.is-open {
    display: -ms-flexbox;
    display: flex; } }

#feds-topnav .feds-searchForm-wrapper {
  position: relative;
  width: 100%; }

@media screen and (min-width: 1200px) {
  #feds-topnav .feds-searchForm-wrapper {
    max-width: 1200px;
    padding: 0 20px; } }

@media screen and (min-width: 900px) {
  .feds--smallDesktopEnabled #feds-topnav .feds-searchForm-wrapper {
    max-width: 1200px;
    padding: 0 20px; } }

#feds-topnav .feds-searchForm {
  -ms-flex-align: center;
      align-items: center;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  padding: 7px 10px;
  position: relative;
  width: 100%; }

#feds-topnav .feds-searchForm--focused {
  border-color: #2680eb; }

#feds-topnav input.feds-searchInput {
  background-color: transparent;
  border: none;
  box-shadow: none;
  -ms-flex-positive: 1;
      flex-grow: 1;
  font-family: inherit;
  font-size: 16px;
  font-weight: 300;
  max-width: 100%;
  min-width: auto;
  padding: 0; }

#feds-topnav input.feds-searchInput:active, #feds-topnav input.feds-searchInput:focus, #feds-topnav input.feds-searchInput:hover {
  outline: none; }

#feds-topnav input.feds-searchInput::-ms-clear {
  display: none;
  height: 0;
  width: 0; }

#feds-topnav .feds-search-rebrand input.feds-searchInput {
  color: #2c2c2c;
  font-size: 17px;
  font-weight: 400; }

#feds-topnav .feds-searchIcons {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin-left: 10px;
  position: relative; }

html[dir=rtl] #feds-topnav .feds-searchIcons {
  margin-left: 0;
  margin-right: 10px; }

#feds-topnav .feds-searchIcons svg {
  display: block;
  height: 16px;
  width: 16px; }

#feds-topnav .feds-searchIcons svg path {
  fill: #2d2d2d; }

#feds-topnav .feds-searchIcons--hasInput svg {
  display: none;
  visibility: hidden; }

#feds-topnav .feds-searchIcons--hasInput .feds-searchClose {
  display: -ms-flexbox;
  display: flex;
  visibility: visible; }

#feds-topnav a.feds-searchClose {
  color: #2d2d2d;
  cursor: pointer;
  display: none;
  font-size: 16px;
  visibility: hidden; }

#feds-topnav a.feds-searchClose.feds-focus-ring {
  outline: 2px solid #109cde;
  outline-offset: -2px; }

#feds-topnav a.feds-searchClose:before {
  content: "\2715"; }

#feds-topnav a.feds-search-trigger {
  display: none; }

#feds-topnav a.feds-search-trigger.feds-focus-ring {
  outline: 2px solid #109cde;
  outline-offset: 2px; }

@media screen and (min-width: 1200px) {
  #feds-topnav a.feds-search-trigger {
    -ms-flex-item-align: center;
        align-self: center;
    border-radius: 4px;
    display: -ms-flexbox;
    display: flex;
    padding: 4px; }
  #feds-topnav a.feds-search-trigger svg {
    display: block;
    height: 20px;
    width: 20px; }
  #feds-topnav a.feds-search-trigger svg path {
    fill: #2d2d2d; }
  #feds-topnav a.feds-search-trigger:hover svg path {
    fill: #2b9af3; }
  #feds-topnav a.feds-search-trigger:hover .feds-search-close {
    color: #2b9af3; }
  #feds-topnav a.feds-search-trigger.is-open svg {
    display: none;
    visibility: hidden; }
  #feds-topnav a.feds-search-trigger.is-open .feds-search-close {
    display: -ms-flexbox;
    display: flex;
    visibility: visible; } }

@media screen and (min-width: 900px) {
  .feds--smallDesktopEnabled #feds-topnav a.feds-search-trigger {
    -ms-flex-item-align: center;
        align-self: center;
    border-radius: 4px;
    display: -ms-flexbox;
    display: flex;
    padding: 4px; }
  .feds--smallDesktopEnabled #feds-topnav a.feds-search-trigger svg {
    display: block;
    height: 20px;
    width: 20px; }
  .feds--smallDesktopEnabled #feds-topnav a.feds-search-trigger svg path {
    fill: #2d2d2d; }
  .feds--smallDesktopEnabled #feds-topnav a.feds-search-trigger:hover svg path {
    fill: #2b9af3; }
  .feds--smallDesktopEnabled #feds-topnav a.feds-search-trigger:hover .feds-search-close {
    color: #2b9af3; }
  .feds--smallDesktopEnabled #feds-topnav a.feds-search-trigger.is-open svg {
    display: none;
    visibility: hidden; }
  .feds--smallDesktopEnabled #feds-topnav a.feds-search-trigger.is-open .feds-search-close {
    display: -ms-flexbox;
    display: flex;
    visibility: visible; } }

#feds-topnav .feds-search-close {
  color: #2d2d2d;
  display: none;
  font-size: 26px;
  visibility: hidden; }

#feds-topnav .feds-search-close:before {
  content: "\2715"; }

#feds-topnav .feds-searchResults-wrapper {
  background-color: #fff;
  width: 100%; }

@media screen and (min-width: 1200px) {
  #feds-topnav .feds-searchResults-wrapper {
    left: 0;
    padding: 0 20px 20px;
    position: absolute;
    right: 0;
    top: 100%; } }

@media screen and (min-width: 900px) {
  .feds--smallDesktopEnabled #feds-topnav .feds-searchResults-wrapper {
    left: 0;
    padding: 0 20px 20px;
    position: absolute;
    right: 0;
    top: 100%; } }

#feds-topnav .feds-searchResults {
  max-height: 75vh;
  overflow-x: hidden;
  overflow-y: auto; }

#feds-topnav .feds-search.has-results .feds-searchResults {
  border: 1px solid #d3d3d3;
  border-radius: 0 0 3px 3px;
  border-top: 0 solid #d3d3d3;
  padding: 10px 0; }

#feds-topnav .feds-searchResult-link {
  color: #2d2d2d;
  display: block;
  font-size: 13px;
  font-weight: 700;
  line-height: 1;
  padding: 5px 10px; }

#feds-topnav .feds-searchResult-link:focus, #feds-topnav .feds-searchResult-link:hover {
  background: #f5f5f5; }

#feds-topnav .feds-searchResult-link.feds-focus-ring {
  outline: 2px solid #109cde;
  outline-offset: -2px; }

#feds-topnav .feds-searchResult-highlight {
  font-weight: 400; }

#feds-topnav .feds-advancedSearch {
  border: 1px solid #d3d3d3;
  border-radius: 0 0 3px 3px;
  border-top: 0 solid #d3d3d3;
  display: none;
  padding: 10px 0; }

#feds-topnav .feds-advancedSearch.is-open {
  display: block; }

#feds-topnav .feds-advancedSearch-link {
  color: #2d2d2d;
  display: block;
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
  padding: 5px 10px; }

#feds-topnav .feds-advancedSearch-link.feds-focus-ring {
  outline: 2px solid #109cde;
  outline-offset: -2px; }

/*!
 * navBar v0.65.0 built on Tue, 15 Mar 2022 16:50:21 GMT
 *
 */
.feds-topnav-spacing {
  transition: height .3s ease; }

.feds-header-wrapper {
  position: relative;
  z-index: 10; }

.feds-header-wrapper--sticky {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: transform .3s ease;
  width: 100%; }

.feds-header-wrapper #feds-header {
  position: relative;
  z-index: 2; }

.feds-header-wrapper #feds-header:nth-last-child(2) #feds-topnav {
  min-height: 40px; }

@media screen and (min-width: 1200px) {
  .feds-header-wrapper #feds-header:nth-last-child(2) #feds-topnav {
    min-height: 60px; } }

@media screen and (min-width: 900px) {
  .feds-header-wrapper #feds-header.feds--smallDesktopEnabled:nth-last-child(2) #feds-topnav {
    min-height: 60px; } }

#feds-header .feds-curtainWrapper {
  display: none; }

#feds-header .feds-curtainWrapper .feds-curtain {
  background: #000;
  opacity: .5;
  position: fixed; }

#feds-header .feds-curtainWrapper--open {
  display: block; }

#feds-header .feds-curtainWrapper--open .feds-curtain {
  height: 100%;
  width: 100%; }

#feds-topnav {
  position: relative;
  z-index: 1; }

#feds-footernav .feds-navBar-wrapper, #feds-topnav .feds-navBar-wrapper {
  display: -ms-flexbox;
  display: flex;
  position: relative; }

#feds-footernav > .feds-navBar > .feds-navList-wrapper, #feds-topnav > .feds-navBar > .feds-navList-wrapper {
  min-height: inherit; }

#feds-footernav .feds-navBar, #feds-topnav .feds-navBar {
  -ms-flex-align: inherit;
      align-items: inherit;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: inherit;
      flex-direction: inherit;
  -ms-flex-pack: inherit;
      justify-content: inherit;
  min-height: inherit;
  width: 100%; }

/*!
 * navList v0.65.0 built on Tue, 15 Mar 2022 16:50:21 GMT
 *
 */
#feds-footernav button.feds-navList-hamburger, #feds-topnav button.feds-navList-hamburger {
  background: transparent;
  border: none;
  box-shadow: none;
  color: #2d2d2d;
  cursor: pointer;
  font-size: 20px;
  font-weight: 300;
  margin: 0;
  padding: 0;
  width: 60px; }

#feds-footernav button.feds-navList-hamburger:before, #feds-topnav button.feds-navList-hamburger:before {
  content: "\2630"; }

#feds-footernav button.feds-navList-hamburger:active, #feds-footernav button.feds-navList-hamburger:focus, #feds-footernav button.feds-navList-hamburger:hover, #feds-topnav button.feds-navList-hamburger:active, #feds-topnav button.feds-navList-hamburger:focus, #feds-topnav button.feds-navList-hamburger:hover {
  background: transparent;
  border: none;
  box-shadow: none;
  color: #2d2d2d; }

#feds-footernav button.feds-navList-hamburger.feds-focus-ring, #feds-topnav button.feds-navList-hamburger.feds-focus-ring {
  outline: 2px solid #109cde;
  outline-offset: -2px; }

#feds-footernav button.feds-navList-hamburger[aria-expanded=true]:before, #feds-topnav button.feds-navList-hamburger[aria-expanded=true]:before {
  content: "\2715"; }

#feds-footernav button.feds-navList-hamburger[aria-expanded=true] + .feds-navList-wrapper, #feds-topnav button.feds-navList-hamburger[aria-expanded=true] + .feds-navList-wrapper {
  display: -ms-flexbox;
  display: flex; }

@media screen and (min-width: 1200px) {
  #feds-footernav button.feds-navList-hamburger, #feds-topnav button.feds-navList-hamburger {
    display: none; } }

@media screen and (min-width: 900px) {
  .feds--smallDesktopEnabled #feds-footernav button.feds-navList-hamburger, .feds--smallDesktopEnabled #feds-topnav button.feds-navList-hamburger {
    display: none; } }

#feds-footernav .feds-navList-wrapper, #feds-topnav .feds-navList-wrapper {
  display: -ms-flexbox;
  display: flex;
  line-height: 1;
  width: 100%; }

#feds-footernav .feds-navList-wrapper--main, #feds-topnav .feds-navList-wrapper--main {
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
  display: none;
  height: 100vh;
  left: 0;
  position: absolute;
  top: 100%;
  width: calc(100% - 20px);
  z-index: 1; }

@media screen and (min-width: 1200px) {
  #feds-footernav .feds-navList-wrapper--main, #feds-topnav .feds-navList-wrapper--main {
    box-shadow: none;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    left: auto;
    padding-bottom: 0 !important;
    position: static;
    right: auto;
    top: auto;
    width: 100%; } }

@media screen and (min-width: 900px) {
  .feds--smallDesktopEnabled #feds-footernav .feds-navList-wrapper--main, .feds--smallDesktopEnabled #feds-topnav .feds-navList-wrapper--main {
    box-shadow: none;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    left: auto;
    padding-bottom: 0 !important;
    position: static;
    right: auto;
    top: auto;
    width: 100%; } }

#feds-footernav .feds-navList, #feds-topnav .feds-navList {
  -ms-flex-item-align: stretch;
  -ms-flex-align: inherit;
      align-items: inherit;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: inherit;
      flex-direction: inherit;
  -ms-flex-wrap: inherit;
      flex-wrap: inherit;
  height: 100%;
  -ms-flex-pack: inherit;
      justify-content: inherit;
  margin: 0;
  padding: 0;
  width: 100%; }

#feds-footernav .feds-navList[data-feds-keyboardnavigationenabled], #feds-topnav .feds-navList[data-feds-keyboardnavigationenabled] {
  overflow-y: auto; }

@media screen and (min-width: 1200px) {
  #feds-footernav .feds-navList[data-feds-keyboardnavigationenabled], #feds-topnav .feds-navList[data-feds-keyboardnavigationenabled] {
    overflow-y: visible; } }

@media screen and (min-width: 900px) {
  .feds--smallDesktopEnabled #feds-footernav .feds-navList[data-feds-keyboardnavigationenabled], .feds--smallDesktopEnabled #feds-topnav .feds-navList[data-feds-keyboardnavigationenabled] {
    overflow-y: visible; } }

@media screen and (max-width: 899px) {
  #feds-footernav .feds-navList[data-feds-keyboardnavigationenabled] .feds-navList-item, #feds-topnav .feds-navList[data-feds-keyboardnavigationenabled] .feds-navList-item {
    -ms-flex-negative: 0;
        flex-shrink: 0; } }

@media screen and (max-width: 1199px) {
  #feds-header:not(.feds--smallDesktopEnabled) #feds-footernav .feds-navList[data-feds-keyboardnavigationenabled] .feds-navList-item, #feds-header:not(.feds--smallDesktopEnabled) #feds-topnav .feds-navList[data-feds-keyboardnavigationenabled] .feds-navList-item {
    -ms-flex-negative: 0;
        flex-shrink: 0; } }

#feds-footernav .feds-navList-header, #feds-topnav .feds-navList-header {
  font-weight: 300;
  margin: 0;
  padding: 0;
  white-space: nowrap; }

#feds-footernav .feds-popup .feds-navList-header, #feds-topnav .feds-popup .feds-navList-header {
  display: none; }

@media screen and (min-width: 1200px) {
  #feds-footernav .feds-popup .feds-navList-header, #feds-topnav .feds-popup .feds-navList-header {
    display: -ms-flexbox;
    display: flex; } }

@media screen and (min-width: 900px) {
  .feds--smallDesktopEnabled #feds-footernav .feds-popup .feds-navList-header, .feds--smallDesktopEnabled #feds-topnav .feds-popup .feds-navList-header {
    display: -ms-flexbox;
    display: flex; } }

#feds-footernav .feds-navList-headline, #feds-topnav .feds-navList-headline {
  cursor: pointer;
  margin: 0;
  padding: 0;
  white-space: nowrap; }

#feds-footernav .feds-navList-headline:focus, #feds-topnav .feds-navList-headline:focus {
  outline: none; }

#feds-footernav .feds-navList-headline > .feds-richText, #feds-topnav .feds-navList-headline > .feds-richText {
  position: relative; }

#feds-footernav .feds-navList-headline > .feds-richText:after, #feds-topnav .feds-navList-headline > .feds-richText:after {
  border-color: #2c2c2c;
  border-style: solid;
  border-width: 0 1px 1px 0;
  content: "";
  display: -ms-flexbox;
  display: flex;
  height: 6px;
  margin-top: -3px;
  position: absolute;
  right: 32px;
  top: 50%;
  -ms-transform: rotate(45deg);
      transform: rotate(45deg);
  -ms-transform-origin: 75% 75%;
      transform-origin: 75% 75%;
  transition: transform .1s ease;
  width: 6px; }

[dir=rtl] #feds-footernav .feds-navList-headline > .feds-richText:after, [dir=rtl] #feds-topnav .feds-navList-headline > .feds-richText:after {
  left: 32px;
  right: auto; }

@media screen and (min-width: 1200px) {
  #feds-footernav .feds-navList-headline > .feds-richText:after, #feds-topnav .feds-navList-headline > .feds-richText:after {
    content: none;
    display: none; } }

@media screen and (min-width: 900px) {
  .feds--smallDesktopEnabled #feds-footernav .feds-navList-headline > .feds-richText:after, .feds--smallDesktopEnabled #feds-topnav .feds-navList-headline > .feds-richText:after {
    content: none;
    display: none; } }

@media screen and (max-width: 599px) {
  html:not([dir=rtl]) #feds-footernav .feds-navList-headline > .feds-richText, html:not([dir=rtl]) #feds-topnav .feds-navList-headline > .feds-richText {
    padding-right: 47px !important; }
  html[dir=rtl] #feds-footernav .feds-navList-headline > .feds-richText, html[dir=rtl] #feds-topnav .feds-navList-headline > .feds-richText {
    padding-left: 47px !important; } }

@media screen and (max-width: 899px) {
  html:not([dir=rtl]) .feds--smallDesktopEnabled #feds-footernav .feds-navList-headline > .feds-richText, html:not([dir=rtl]) .feds--smallDesktopEnabled #feds-topnav .feds-navList-headline > .feds-richText {
    padding-right: 47px !important; }
  html[dir=rtl] .feds--smallDesktopEnabled #feds-footernav .feds-navList-headline > .feds-richText, html[dir=rtl] .feds--smallDesktopEnabled #feds-topnav .feds-navList-headline > .feds-richText {
    padding-left: 47px !important; } }

#feds-footernav .feds-navList-headline[aria-expanded=true] .feds-richText:after, #feds-topnav .feds-navList-headline[aria-expanded=true] .feds-richText:after {
  -ms-transform: rotate(-135deg);
      transform: rotate(-135deg); }

#feds-footernav .feds-navList-headline.feds-focus-ring, #feds-topnav .feds-navList-headline.feds-focus-ring {
  outline: 2px solid #109cde;
  outline-offset: -2px; }

@media screen and (max-width: 899px) {
  #feds-footernav .feds-navList-headline u, #feds-topnav .feds-navList-headline u {
    text-decoration: none; }
  #feds-footernav .feds-navList-headline i, #feds-topnav .feds-navList-headline i {
    font-style: normal; } }

@media screen and (max-width: 1199px) {
  #feds-header:not(.feds--smallDesktopEnabled) #feds-footernav .feds-navList-headline u, #feds-header:not(.feds--smallDesktopEnabled) #feds-topnav .feds-navList-headline u {
    text-decoration: none; }
  #feds-header:not(.feds--smallDesktopEnabled) #feds-footernav .feds-navList-headline i, #feds-header:not(.feds--smallDesktopEnabled) #feds-topnav .feds-navList-headline i {
    font-style: normal; } }

#feds-footernav .feds-navList-headline + .feds-navList, #feds-topnav .feds-navList-headline + .feds-navList {
  display: none; }

#feds-footernav .feds-navList-headline[aria-expanded=true] + .feds-navList, #feds-topnav .feds-navList-headline[aria-expanded=true] + .feds-navList {
  display: block; }

@media screen and (min-width: 1200px) {
  #feds-footernav .feds-navList-headline, #feds-topnav .feds-navList-headline {
    cursor: default; }
  #feds-footernav .feds-navList-headline + .feds-navList, #feds-topnav .feds-navList-headline + .feds-navList {
    display: block; } }

@media screen and (min-width: 900px) {
  .feds--smallDesktopEnabled #feds-footernav .feds-navList-headline, .feds--smallDesktopEnabled #feds-topnav .feds-navList-headline {
    cursor: default; }
  .feds--smallDesktopEnabled #feds-footernav .feds-navList-headline + .feds-navList, .feds--smallDesktopEnabled #feds-topnav .feds-navList-headline + .feds-navList {
    display: block; } }

#feds-footernav .feds-navList-item, #feds-topnav .feds-navList-item {
  display: -ms-flexbox;
  display: flex;
  list-style: none; }

#feds-footernav .feds-navList-item--main, #feds-topnav .feds-navList-item--main {
  -ms-flex-order: -1;
      order: -1; }

@media screen and (min-width: 1200px) {
  #feds-footernav .feds-navList-item--main, #feds-topnav .feds-navList-item--main {
    -ms-flex-order: 0;
        order: 0; } }

@media screen and (min-width: 900px) {
  .feds--smallDesktopEnabled #feds-footernav .feds-navList-item--main, .feds--smallDesktopEnabled #feds-topnav .feds-navList-item--main {
    -ms-flex-order: 0;
        order: 0; } }

#feds-footernav .feds-navList-item:only-child, #feds-topnav .feds-navList-item:only-child {
  -ms-flex-pack: inherit;
      justify-content: inherit;
  width: 100%; }

#feds-footernav .feds-navList-item:after, #feds-topnav .feds-navList-item:after {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex; }

#feds-footernav .feds-navList-item:last-child:after, #feds-topnav .feds-navList-item:last-child:after {
  display: none; }

#feds-footernav .feds-navList-item > .xf-content-height, #feds-footernav .feds-spacer, #feds-topnav .feds-navList-item > .xf-content-height, #feds-topnav .feds-spacer {
  width: 100%; }

#feds-topnav .feds-navList-item.feds-dropdown--active {
  position: relative; }

#feds-topnav .feds-navList-item.feds-dropdown--active:before {
  background: #2c2c2c;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  top: 0;
  width: 2px;
  z-index: 1; }

@media screen and (min-width: 1200px) {
  #feds-topnav .feds-navList-item.feds-dropdown--active {
    position: static; }
  #feds-topnav .feds-navList-item.feds-dropdown--active:before {
    content: none; } }

@media screen and (min-width: 900px) {
  .feds--smallDesktopEnabled #feds-topnav .feds-navList-item.feds-dropdown--active {
    position: static; }
  .feds--smallDesktopEnabled #feds-topnav .feds-navList-item.feds-dropdown--active:before {
    content: none; } }

/*!
 * profile v0.65.0 built on Tue, 15 Mar 2022 16:50:21 GMT
 *
 */
#feds-topnav .feds-profile-wrapper {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  position: relative; }

#feds-topnav .feds-profile-wrapper.is-hidden {
  display: none !important;
  visibility: hidden; }

#feds-topnav .feds-profile-wrapper .Profile {
  font-family: inherit;
  position: static; }

#feds-topnav a.feds-login {
  -ms-flex-item-align: center;
      align-self: center;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 4px;
  text-decoration: none;
  transition: color .1s ease; }

#feds-topnav a.feds-login.feds-focus-ring {
  border-radius: 4px;
  outline: 2px solid #109cde;
  outline-offset: 2px; }

#feds-topnav a.feds-login--hidden {
  visibility: hidden; }

#feds-topnav a.feds-login.feds-login--Cta {
  -ms-flex-align: center;
      align-items: center;
  border-radius: 16px;
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  font-size: 15px;
  font-weight: 700;
  height: 32px;
  -ms-flex-pack: center;
      justify-content: center;
  line-height: 0;
  min-width: 72px;
  overflow: visible;
  padding: 0 14px !important;
  transition: background-color .13s ease-out,border-color .13s ease-out;
  white-space: nowrap;
  width: auto; }

#feds-topnav a.feds-login.feds-login--Cta:focus, #feds-topnav a.feds-login.feds-login--Cta:hover {
  box-shadow: none; }

#feds-topnav a.feds-login.feds-login--Cta:focus {
  outline-offset: 0; }

#feds-topnav a.feds-login.feds-login--Cta .feds-login-text, #feds-topnav a.feds-login.feds-login--Cta .feds-navLink-text {
  font-weight: 700;
  transition: color .13s ease-out; }

.feds-header--rebranding #feds-topnav a.feds-login.feds-login--Cta .feds-login-text, .feds-header--rebranding #feds-topnav a.feds-login.feds-login--Cta .feds-navLink-text {
  font-weight: 500; }

#feds-topnav a.feds-login.feds-login--Cta .feds-navLink-description, #feds-topnav a.feds-login.feds-login--Cta .feds-navLink-image {
  display: none;
  visibility: hidden; }

.feds-header--rebranding #feds-topnav a.feds-login.feds-login--Cta {
  font-size: 13px;
  font-weight: 500; }

#feds-topnav a.feds-login.feds-login--Cta[disabled], #feds-topnav a.feds-login.feds-login--Cta[disabled]:focus, #feds-topnav a.feds-login.feds-login--Cta[disabled]:hover {
  background-color: #eaeaea !important;
  border-color: #eaeaea;
  cursor: default;
  pointer-events: none; }

#feds-topnav a.feds-login.feds-login--Cta[disabled] .feds-login-text, #feds-topnav a.feds-login.feds-login--Cta[disabled] .feds-navLink-text, #feds-topnav a.feds-login.feds-login--Cta[disabled]:focus .feds-login-text, #feds-topnav a.feds-login.feds-login--Cta[disabled]:focus .feds-navLink-text, #feds-topnav a.feds-login.feds-login--Cta[disabled]:hover .feds-login-text, #feds-topnav a.feds-login.feds-login--Cta[disabled]:hover .feds-navLink-text {
  color: #b3b3b3 !important; }

#feds-topnav a.feds-login.feds-login--primaryCta {
  background-color: #1473e6;
  border-color: #1473e6; }

#feds-topnav a.feds-login.feds-login--primaryCta:focus, #feds-topnav a.feds-login.feds-login--primaryCta:hover {
  background-color: #0d66d0 !important;
  border-color: #0d66d0; }

#feds-topnav a.feds-login.feds-login--primaryCta .feds-login-text, #feds-topnav a.feds-login.feds-login--primaryCta .feds-navLink-text, #feds-topnav a.feds-login.feds-login--primaryCta:focus .feds-login-text, #feds-topnav a.feds-login.feds-login--primaryCta:focus .feds-navLink-text, #feds-topnav a.feds-login.feds-login--primaryCta:hover .feds-login-text, #feds-topnav a.feds-login.feds-login--primaryCta:hover .feds-navLink-text {
  color: #fff !important; }

#feds-topnav a.feds-login.feds-login--secondaryCta {
  background-color: transparent;
  border-color: #4b4b4b; }

#feds-topnav a.feds-login.feds-login--secondaryCta:focus, #feds-topnav a.feds-login.feds-login--secondaryCta:hover {
  background-color: #4b4b4b !important; }

#feds-topnav a.feds-login.feds-login--secondaryCta:focus .feds-login-text, #feds-topnav a.feds-login.feds-login--secondaryCta:focus .feds-navLink-text, #feds-topnav a.feds-login.feds-login--secondaryCta:hover .feds-login-text, #feds-topnav a.feds-login.feds-login--secondaryCta:hover .feds-navLink-text {
  color: #fff !important; }

#feds-topnav a.feds-login.feds-login--secondaryCta .feds-login-text, #feds-topnav a.feds-login.feds-login--secondaryCta .feds-navLink-text {
  color: #4b4b4b !important; }

#feds-topnav a.feds-login[aria-expanded=true] + .feds-profile-dropdown {
  display: block;
  visibility: visible; }

#feds-topnav .feds-login-text {
  color: inherit; }

#feds-topnav .feds-profile-dropdown {
  display: none;
  position: absolute;
  right: 0;
  top: 100%;
  visibility: hidden; }

html[dir=rtl] #feds-topnav .feds-profile-dropdown {
  left: 0;
  right: auto; }

#feds-topnav .feds-profile a.Profile-thumbnail {
  outline-offset: 2px; }

#feds-topnav .feds-profile a.Profile-thumbnail.feds-focus-ring {
  outline: 2px solid #109cde; }

#feds-topnav .feds-profile .Profile-dropdown {
  top: 100%;
  z-index: 1; }

#feds-topnav .feds-profile .Profile-dropdown a:hover {
  text-decoration: none; }

#feds-topnav .feds-profile .Profile-name {
  font-size: 18px;
  line-height: 1.4;
  margin: -3px 0 0; }

#feds-topnav .feds-profile .Profile-name_long {
  font-size: 14px; }

#feds-topnav .feds-profile .Profile-email {
  font-size: 14px;
  line-height: 1.25;
  margin: -1px 0 12px; }

#feds-topnav .feds-profile .Profile-menu-link {
  color: #4b4b4b; }

/*!
 * regionPicker v0.65.0 built on Tue, 15 Mar 2022 16:50:21 GMT
 *
 */
a.feds-regionPicker {
  fill: #999;
  -ms-flex-align: center;
      align-items: center;
  color: #999;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  transition: color .1s ease,fill .1s ease; }

a.feds-regionPicker:focus, a.feds-regionPicker:hover {
  fill: #fff;
  color: #fff; }

a.feds-regionPicker.feds-focus-ring {
  outline: 2px solid #109cde; }

a.feds-regionPicker:after {
  color: inherit;
  content: "âˆ¨";
  display: block;
  margin-left: 7px;
  -ms-transform: scaleX(2);
      transform: scaleX(2);
  -ms-transform-origin: center center;
      transform-origin: center center; }

html[dir=rtl] a.feds-regionPicker:after {
  margin-left: 0;
  margin-right: 7px; }

.feds-regionPicker-icon {
  height: 20px;
  margin-right: 10px;
  width: 20px; }

html[dir=rtl] .feds-regionPicker-icon {
  margin-left: 10px;
  margin-right: 0; }

.feds-regionPicker-icon > svg {
  fill: inherit;
  display: block;
  height: 100%;
  width: 100%; }

.feds-regionPicker-text {
  color: inherit;
  font-size: inherit;
  font-weight: 400; }

/*!
 * navLink v0.65.0 built on Tue, 15 Mar 2022 16:50:21 GMT
 *
 */
#feds-footernav a.feds-navLink, #feds-topnav a.feds-navLink {
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  line-height: 1.4;
  text-decoration: none;
  transition: background-color .1s ease;
  width: 100%; }

#feds-footernav a.feds-navLink.feds-focus-ring, #feds-topnav a.feds-navLink.feds-focus-ring {
  outline: 2px solid #109cde;
  outline-offset: -2px; }

#feds-footernav a.feds-navLink.feds-navLink--Cta, #feds-topnav a.feds-navLink.feds-navLink--Cta {
  -ms-flex-align: center;
      align-items: center;
  border-radius: 16px;
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  font-size: 15px;
  font-weight: 700;
  height: 32px;
  -ms-flex-pack: center;
      justify-content: center;
  line-height: 0;
  min-width: 72px;
  overflow: visible;
  padding: 0 14px !important;
  transition: background-color .13s ease-out,border-color .13s ease-out;
  white-space: nowrap;
  width: auto; }

#feds-footernav a.feds-navLink.feds-navLink--Cta:focus, #feds-footernav a.feds-navLink.feds-navLink--Cta:hover, #feds-topnav a.feds-navLink.feds-navLink--Cta:focus, #feds-topnav a.feds-navLink.feds-navLink--Cta:hover {
  box-shadow: none; }

#feds-footernav a.feds-navLink.feds-navLink--Cta:focus, #feds-topnav a.feds-navLink.feds-navLink--Cta:focus {
  outline-offset: 0; }

#feds-footernav a.feds-navLink.feds-navLink--Cta .feds-login-text, #feds-footernav a.feds-navLink.feds-navLink--Cta .feds-navLink-text, #feds-topnav a.feds-navLink.feds-navLink--Cta .feds-login-text, #feds-topnav a.feds-navLink.feds-navLink--Cta .feds-navLink-text {
  font-weight: 700;
  transition: color .13s ease-out; }

.feds-header--rebranding #feds-footernav a.feds-navLink.feds-navLink--Cta .feds-login-text, .feds-header--rebranding #feds-footernav a.feds-navLink.feds-navLink--Cta .feds-navLink-text, .feds-header--rebranding #feds-topnav a.feds-navLink.feds-navLink--Cta .feds-login-text, .feds-header--rebranding #feds-topnav a.feds-navLink.feds-navLink--Cta .feds-navLink-text {
  font-weight: 500; }

#feds-footernav a.feds-navLink.feds-navLink--Cta .feds-navLink-description, #feds-footernav a.feds-navLink.feds-navLink--Cta .feds-navLink-image, #feds-topnav a.feds-navLink.feds-navLink--Cta .feds-navLink-description, #feds-topnav a.feds-navLink.feds-navLink--Cta .feds-navLink-image {
  display: none;
  visibility: hidden; }

.feds-header--rebranding #feds-footernav a.feds-navLink.feds-navLink--Cta, .feds-header--rebranding #feds-topnav a.feds-navLink.feds-navLink--Cta {
  font-size: 13px;
  font-weight: 500; }

#feds-footernav a.feds-navLink.feds-navLink--Cta[disabled], #feds-footernav a.feds-navLink.feds-navLink--Cta[disabled]:focus, #feds-footernav a.feds-navLink.feds-navLink--Cta[disabled]:hover, #feds-topnav a.feds-navLink.feds-navLink--Cta[disabled], #feds-topnav a.feds-navLink.feds-navLink--Cta[disabled]:focus, #feds-topnav a.feds-navLink.feds-navLink--Cta[disabled]:hover {
  background-color: #eaeaea !important;
  border-color: #eaeaea;
  cursor: default;
  pointer-events: none; }

#feds-footernav a.feds-navLink.feds-navLink--Cta[disabled] .feds-login-text, #feds-footernav a.feds-navLink.feds-navLink--Cta[disabled] .feds-navLink-text, #feds-footernav a.feds-navLink.feds-navLink--Cta[disabled]:focus .feds-login-text, #feds-footernav a.feds-navLink.feds-navLink--Cta[disabled]:focus .feds-navLink-text, #feds-footernav a.feds-navLink.feds-navLink--Cta[disabled]:hover .feds-login-text, #feds-footernav a.feds-navLink.feds-navLink--Cta[disabled]:hover .feds-navLink-text, #feds-topnav a.feds-navLink.feds-navLink--Cta[disabled] .feds-login-text, #feds-topnav a.feds-navLink.feds-navLink--Cta[disabled] .feds-navLink-text, #feds-topnav a.feds-navLink.feds-navLink--Cta[disabled]:focus .feds-login-text, #feds-topnav a.feds-navLink.feds-navLink--Cta[disabled]:focus .feds-navLink-text, #feds-topnav a.feds-navLink.feds-navLink--Cta[disabled]:hover .feds-login-text, #feds-topnav a.feds-navLink.feds-navLink--Cta[disabled]:hover .feds-navLink-text {
  color: #b3b3b3 !important; }

#feds-footernav a.feds-navLink.feds-navLink--primaryCta, #feds-topnav a.feds-navLink.feds-navLink--primaryCta {
  background-color: #1473e6;
  border-color: #1473e6; }

#feds-footernav a.feds-navLink.feds-navLink--primaryCta:focus, #feds-footernav a.feds-navLink.feds-navLink--primaryCta:hover, #feds-topnav a.feds-navLink.feds-navLink--primaryCta:focus, #feds-topnav a.feds-navLink.feds-navLink--primaryCta:hover {
  background-color: #0d66d0 !important;
  border-color: #0d66d0; }

#feds-footernav a.feds-navLink.feds-navLink--primaryCta .feds-login-text, #feds-footernav a.feds-navLink.feds-navLink--primaryCta .feds-navLink-text, #feds-footernav a.feds-navLink.feds-navLink--primaryCta:focus .feds-login-text, #feds-footernav a.feds-navLink.feds-navLink--primaryCta:focus .feds-navLink-text, #feds-footernav a.feds-navLink.feds-navLink--primaryCta:hover .feds-login-text, #feds-footernav a.feds-navLink.feds-navLink--primaryCta:hover .feds-navLink-text, #feds-topnav a.feds-navLink.feds-navLink--primaryCta .feds-login-text, #feds-topnav a.feds-navLink.feds-navLink--primaryCta .feds-navLink-text, #feds-topnav a.feds-navLink.feds-navLink--primaryCta:focus .feds-login-text, #feds-topnav a.feds-navLink.feds-navLink--primaryCta:focus .feds-navLink-text, #feds-topnav a.feds-navLink.feds-navLink--primaryCta:hover .feds-login-text, #feds-topnav a.feds-navLink.feds-navLink--primaryCta:hover .feds-navLink-text {
  color: #fff !important; }

#feds-footernav a.feds-navLink.feds-navLink--secondaryCta, #feds-topnav a.feds-navLink.feds-navLink--secondaryCta {
  background-color: transparent;
  border-color: #4b4b4b; }

#feds-footernav a.feds-navLink.feds-navLink--secondaryCta:focus, #feds-footernav a.feds-navLink.feds-navLink--secondaryCta:hover, #feds-topnav a.feds-navLink.feds-navLink--secondaryCta:focus, #feds-topnav a.feds-navLink.feds-navLink--secondaryCta:hover {
  background-color: #4b4b4b !important; }

#feds-footernav a.feds-navLink.feds-navLink--secondaryCta:focus .feds-login-text, #feds-footernav a.feds-navLink.feds-navLink--secondaryCta:focus .feds-navLink-text, #feds-footernav a.feds-navLink.feds-navLink--secondaryCta:hover .feds-login-text, #feds-footernav a.feds-navLink.feds-navLink--secondaryCta:hover .feds-navLink-text, #feds-topnav a.feds-navLink.feds-navLink--secondaryCta:focus .feds-login-text, #feds-topnav a.feds-navLink.feds-navLink--secondaryCta:focus .feds-navLink-text, #feds-topnav a.feds-navLink.feds-navLink--secondaryCta:hover .feds-login-text, #feds-topnav a.feds-navLink.feds-navLink--secondaryCta:hover .feds-navLink-text {
  color: #fff !important; }

#feds-footernav a.feds-navLink.feds-navLink--secondaryCta .feds-login-text, #feds-footernav a.feds-navLink.feds-navLink--secondaryCta .feds-navLink-text, #feds-topnav a.feds-navLink.feds-navLink--secondaryCta .feds-login-text, #feds-topnav a.feds-navLink.feds-navLink--secondaryCta .feds-navLink-text {
  color: #4b4b4b !important; }

#feds-footernav a.feds-navLink[hidden], #feds-topnav a.feds-navLink[hidden] {
  display: none;
  visibility: hidden; }

#feds-footernav a.feds-navLink--hoverCaret, #feds-topnav a.feds-navLink--hoverCaret {
  position: relative; }

#feds-footernav a.feds-navLink--hoverCaret:after, #feds-topnav a.feds-navLink--hoverCaret:after {
  border-color: #2c2c2c;
  border-style: solid;
  border-width: 0 1px 1px 0;
  content: "";
  display: -ms-flexbox;
  display: flex;
  height: 6px;
  margin-top: -3px;
  position: absolute;
  right: 20px;
  top: 50%;
  -ms-transform: rotate(45deg);
      transform: rotate(45deg);
  -ms-transform-origin: 75% 75%;
      transform-origin: 75% 75%;
  transition: transform .1s ease;
  width: 6px; }

[dir=rtl] #feds-footernav a.feds-navLink--hoverCaret:after, [dir=rtl] #feds-topnav a.feds-navLink--hoverCaret:after {
  left: 20px;
  right: auto; }

@media screen and (min-width: 1200px) {
  #feds-footernav a.feds-navLink--hoverCaret:after, #feds-topnav a.feds-navLink--hoverCaret:after {
    content: none;
    display: none; } }

@media screen and (min-width: 900px) {
  .feds--smallDesktopEnabled #feds-footernav a.feds-navLink--hoverCaret:after, .feds--smallDesktopEnabled #feds-topnav a.feds-navLink--hoverCaret:after {
    content: none;
    display: none; } }

@media screen and (max-width: 899px) {
  html:not([dir=rtl]) #feds-footernav a.feds-navLink--hoverCaret, html:not([dir=rtl]) #feds-topnav a.feds-navLink--hoverCaret {
    padding-right: 35px !important; }
  html[dir=rtl] #feds-footernav a.feds-navLink--hoverCaret, html[dir=rtl] #feds-topnav a.feds-navLink--hoverCaret {
    padding-left: 35px !important; } }

@media screen and (max-width: 1199px) {
  html:not([dir=rtl]) #feds-header:not(.feds--smallDesktopEnabled) #feds-footernav a.feds-navLink--hoverCaret, html:not([dir=rtl]) #feds-header:not(.feds--smallDesktopEnabled) #feds-topnav a.feds-navLink--hoverCaret {
    padding-right: 35px !important; }
  html[dir=rtl] #feds-header:not(.feds--smallDesktopEnabled) #feds-footernav a.feds-navLink--hoverCaret, html[dir=rtl] #feds-header:not(.feds--smallDesktopEnabled) #feds-topnav a.feds-navLink--hoverCaret {
    padding-left: 35px !important; } }

@media screen and (min-width: 1200px) {
  #feds-footernav a.feds-navLink--hoverCaret .feds-navLink-text:after, #feds-topnav a.feds-navLink--hoverCaret .feds-navLink-text:after {
    border-color: #2c2c2c;
    border-style: solid;
    border-width: 0 1px 1px 0;
    content: "";
    display: -ms-flexbox;
    display: flex;
    height: 6px;
    margin-left: 5px;
    margin-right: 2.25px;
    -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    -ms-transform-origin: 75% 75%;
        transform-origin: 75% 75%;
    transition: transform .1s ease;
    width: 6px; }
  html[dir=rtl] #feds-footernav a.feds-navLink--hoverCaret .feds-navLink-text:after, html[dir=rtl] #feds-topnav a.feds-navLink--hoverCaret .feds-navLink-text:after {
    margin-left: 2.25px;
    margin-right: 5px; } }

@media screen and (min-width: 900px) {
  .feds--smallDesktopEnabled #feds-footernav a.feds-navLink--hoverCaret .feds-navLink-text:after, .feds--smallDesktopEnabled #feds-topnav a.feds-navLink--hoverCaret .feds-navLink-text:after {
    border-color: #2c2c2c;
    border-style: solid;
    border-width: 0 1px 1px 0;
    content: "";
    display: -ms-flexbox;
    display: flex;
    height: 6px;
    margin-left: 5px;
    margin-right: 2.25px;
    -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    -ms-transform-origin: 75% 75%;
        transform-origin: 75% 75%;
    transition: transform .1s ease;
    width: 6px; }
  html[dir=rtl] .feds--smallDesktopEnabled #feds-footernav a.feds-navLink--hoverCaret .feds-navLink-text:after, html[dir=rtl] .feds--smallDesktopEnabled #feds-topnav a.feds-navLink--hoverCaret .feds-navLink-text:after {
    margin-left: 2.25px;
    margin-right: 5px; } }

#feds-footernav a.feds-navLink--hoverCaret[aria-expanded=true]:after, #feds-topnav a.feds-navLink--hoverCaret[aria-expanded=true]:after {
  -ms-transform: rotate(-135deg);
      transform: rotate(-135deg); }

@media screen and (min-width: 1200px) {
  #feds-footernav a.feds-navLink--hoverCaret[aria-expanded=true] .feds-navLink-text:after, #feds-topnav a.feds-navLink--hoverCaret[aria-expanded=true] .feds-navLink-text:after {
    -ms-transform: rotate(-135deg);
        transform: rotate(-135deg); } }

@media screen and (min-width: 900px) {
  .feds--smallDesktopEnabled #feds-footernav a.feds-navLink--hoverCaret[aria-expanded=true] .feds-navLink-text:after, .feds--smallDesktopEnabled #feds-topnav a.feds-navLink--hoverCaret[aria-expanded=true] .feds-navLink-text:after {
    -ms-transform: rotate(-135deg);
        transform: rotate(-135deg); } }

#feds-footernav a.feds-navLink.feds-navLink--hiddenImage .feds-navLink-image, #feds-topnav a.feds-navLink.feds-navLink--hiddenImage .feds-navLink-image {
  display: none;
  visibility: hidden; }

#feds-footernav a.feds-navLink.feds-navLink--hiddenImage .feds-navLink-contentWrapper:nth-child(2):before, #feds-topnav a.feds-navLink.feds-navLink--hiddenImage .feds-navLink-contentWrapper:nth-child(2):before {
  content: none; }

#feds-footernav .feds-navLink-image, #feds-topnav .feds-navLink-image {
  display: block;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 25px; }

#feds-footernav .feds-navLink-image > *, #feds-topnav .feds-navLink-image > * {
  display: block;
  width: 100%; }

@media screen and (max-width: 899px) {
  #feds-footernav .feds-navList-wrapper--main .feds-popup .feds-navLink-image, #feds-topnav .feds-navList-wrapper--main .feds-popup .feds-navLink-image {
    display: none;
    visibility: hidden; }
  #feds-footernav .feds-navList-wrapper--main .feds-popup .feds-navLink-contentWrapper:nth-child(2):before, #feds-topnav .feds-navList-wrapper--main .feds-popup .feds-navLink-contentWrapper:nth-child(2):before {
    content: none; } }

@media screen and (max-width: 1199px) {
  #feds-header:not(.feds--smallDesktopEnabled) #feds-footernav .feds-navList-wrapper--main .feds-popup .feds-navLink-image, #feds-header:not(.feds--smallDesktopEnabled) #feds-topnav .feds-navList-wrapper--main .feds-popup .feds-navLink-image {
    display: none;
    visibility: hidden; }
  #feds-header:not(.feds--smallDesktopEnabled) #feds-footernav .feds-navList-wrapper--main .feds-popup .feds-navLink-contentWrapper:nth-child(2):before, #feds-header:not(.feds--smallDesktopEnabled) #feds-topnav .feds-navList-wrapper--main .feds-popup .feds-navLink-contentWrapper:nth-child(2):before {
    content: none; } }

#feds-footernav .feds-navLink-contentWrapper, #feds-topnav .feds-navLink-contentWrapper {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  position: relative;
  white-space: nowrap; }

#feds-footernav .feds-navLink-contentWrapper:nth-child(2):before, #feds-topnav .feds-navLink-contentWrapper:nth-child(2):before {
  content: "";
  margin-left: 15px; }

#feds-footernav .feds-navLink-content, #feds-topnav .feds-navLink-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

#feds-footernav .feds-navLink-text, #feds-topnav .feds-navLink-text {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  font-weight: 400;
  transition: color .1s ease; }

#feds-footernav .feds-navLink-text > :nth-child(2), #feds-footernav .feds-navLink-text > :nth-child(3), #feds-topnav .feds-navLink-text > :nth-child(2), #feds-topnav .feds-navLink-text > :nth-child(3) {
  display: none; }

#feds-footernav .feds-navLink-text > :first-child, #feds-topnav .feds-navLink-text > :first-child {
  display: block; }

@media screen and (min-width: 900px) and (max-width: 1199px) {
  #feds-footernav .feds-navLink-text.feds-navLink-text--hasTitleVariants > *, #feds-topnav .feds-navLink-text.feds-navLink-text--hasTitleVariants > * {
    display: none; }
  #feds-footernav .feds-navLink-text.feds-navLink-text--hasTitleVariants .feds-navLink-text--tablet, #feds-topnav .feds-navLink-text.feds-navLink-text--hasTitleVariants .feds-navLink-text--tablet {
    display: block; } }

@media screen and (min-width: 600px) and (max-width: 899px) {
  #feds-header:not(.feds--smallDesktopEnabled) #feds-footernav .feds-navLink-text.feds-navLink-text--hasTitleVariants > *, #feds-header:not(.feds--smallDesktopEnabled) #feds-topnav .feds-navLink-text.feds-navLink-text--hasTitleVariants > * {
    display: none; }
  #feds-header:not(.feds--smallDesktopEnabled) #feds-footernav .feds-navLink-text.feds-navLink-text--hasTitleVariants .feds-navLink-text--tablet, #feds-header:not(.feds--smallDesktopEnabled) #feds-topnav .feds-navLink-text.feds-navLink-text--hasTitleVariants .feds-navLink-text--tablet {
    display: block; } }

@media screen and (min-width: 1200px) {
  #feds-footernav .feds-navLink-text.feds-navLink-text--hasTitleVariants > :first-child, #feds-footernav .feds-navLink-text.feds-navLink-text--hasTitleVariants > :nth-child(2), #feds-topnav .feds-navLink-text.feds-navLink-text--hasTitleVariants > :first-child, #feds-topnav .feds-navLink-text.feds-navLink-text--hasTitleVariants > :nth-child(2) {
    display: none; }
  #feds-footernav .feds-navLink-text.feds-navLink-text--hasTitleVariants > :last-child, #feds-topnav .feds-navLink-text.feds-navLink-text--hasTitleVariants > :last-child {
    display: block; } }

#feds-footernav .feds-navLink-description, #feds-topnav .feds-navLink-description {
  display: none;
  font-weight: 300; }

@media screen and (min-width: 1200px) {
  #feds-footernav .feds-navLink-description, #feds-topnav .feds-navLink-description {
    display: block; } }

@media screen and (min-width: 900px) {
  .feds--smallDesktopEnabled #feds-footernav .feds-navLink-description, .feds--smallDesktopEnabled #feds-topnav .feds-navLink-description {
    display: block; } }

@media screen and (min-width: 1200px) {
  #feds-topnav .feds-navLink--active:not(.feds-navLink--Cta) .feds-navLink-contentWrapper:after {
    border-bottom: 2px solid #2c2c2c;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    width: 100%; } }

@media screen and (min-width: 900px) {
  .feds--smallDesktopEnabled #feds-topnav .feds-navLink--active:not(.feds-navLink--Cta) .feds-navLink-contentWrapper:after {
    border-bottom: 2px solid #2c2c2c;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    width: 100%; } }

#feds-topnav .feds-navLink--active:not(.feds-navLink--Cta) .feds-navLink-text {
  font-weight: 600; }

#feds-topnav a.feds-navLink--disabled {
  cursor: default; }

#feds-footernav a.feds-navLink--hoverCaret:after {
  border-color: #fff; }

@media screen and (min-width: 1200px) {
  #feds-footernav a.feds-navLink--hoverCaret .feds-navLink-text:after {
    content: none;
    display: none; } }

@media screen and (min-width: 900px) {
  .feds--smallDesktopEnabled #feds-footernav a.feds-navLink--hoverCaret .feds-navLink-text:after {
    content: none;
    display: none; } }

#feds-footernav .feds-popup-trigger, #feds-topnav .feds-popup-trigger {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  position: relative;
  width: 100%; }

#feds-footernav .feds-popup, #feds-topnav .feds-popup {
  display: none;
  margin: 0;
  padding: 0; }

#feds-footernav .feds-popup--open, #feds-topnav .feds-popup--open {
  display: block; }

@media screen and (min-width: 1200px) {
  #feds-topnav .feds-popup-trigger--fullWidth {
    position: static; }
  #feds-topnav .feds-popup-trigger--fullWidth .feds-popup {
    right: 0;
    width: 100%; } }

@media screen and (min-width: 900px) {
  .feds--smallDesktopEnabled #feds-topnav .feds-popup-trigger--fullWidth {
    position: static; }
  .feds--smallDesktopEnabled #feds-topnav .feds-popup-trigger--fullWidth .feds-popup {
    right: 0;
    width: 100%; } }

@media screen and (min-width: 1200px) {
  #feds-topnav .feds-popup {
    left: 0;
    position: absolute;
    top: 100%; } }

@media screen and (min-width: 900px) {
  .feds--smallDesktopEnabled #feds-topnav .feds-popup {
    left: 0;
    position: absolute;
    top: 100%; } }

@media screen and (min-width: 1200px) {
  #feds-footernav .feds-popup {
    display: block; } }

@media screen and (min-width: 900px) {
  .feds--smallDesktopEnabled #feds-footernav .feds-popup {
    display: block; } }

/*!
 * navIcon v0.65.0 built on Tue, 15 Mar 2022 16:50:21 GMT
 *
 */
#feds-footernav a.feds-logo, #feds-topnav a.feds-logo {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  text-decoration: none; }

#feds-footernav a.feds-logo.feds-focus-ring, #feds-topnav a.feds-logo.feds-focus-ring {
  outline: 2px solid #109cde;
  outline-offset: -2px; }

#feds-footernav .feds-logo-image, #feds-topnav .feds-logo-image {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 25px; }

#feds-footernav .feds-logo-image > *, #feds-topnav .feds-logo-image > * {
  display: block;
  width: 100%; }

#feds-footernav .feds-logo-text, #feds-topnav .feds-logo-text {
  color: inherit;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  font-weight: 400; }

#feds-footernav .feds-logo-text:before, #feds-topnav .feds-logo-text:before {
  content: "";
  margin-left: 10px; }

#feds-footernav .feds-logo-text > :nth-child(2), #feds-footernav .feds-logo-text > :nth-child(3), #feds-topnav .feds-logo-text > :nth-child(2), #feds-topnav .feds-logo-text > :nth-child(3) {
  display: none; }

#feds-footernav .feds-logo-text > :first-child, #feds-topnav .feds-logo-text > :first-child {
  display: block;
  text-decoration: none; }

@media screen and (min-width: 900px) and (max-width: 1199px) {
  #feds-footernav .feds-logo-text.feds-logo-text--hasTitleVariants > *, #feds-topnav .feds-logo-text.feds-logo-text--hasTitleVariants > * {
    display: none; }
  #feds-footernav .feds-logo-text.feds-logo-text--hasTitleVariants .feds-logo-text--tablet, #feds-topnav .feds-logo-text.feds-logo-text--hasTitleVariants .feds-logo-text--tablet {
    display: block; } }

@media screen and (min-width: 600px) and (max-width: 899px) {
  #feds-header:not(.feds--smallDesktopEnabled) #feds-footernav .feds-logo-text.feds-logo-text--hasTitleVariants > *, #feds-header:not(.feds--smallDesktopEnabled) #feds-topnav .feds-logo-text.feds-logo-text--hasTitleVariants > * {
    display: none; }
  #feds-header:not(.feds--smallDesktopEnabled) #feds-footernav .feds-logo-text.feds-logo-text--hasTitleVariants .feds-logo-text--tablet, #feds-header:not(.feds--smallDesktopEnabled) #feds-topnav .feds-logo-text.feds-logo-text--hasTitleVariants .feds-logo-text--tablet {
    display: block; } }

@media screen and (min-width: 1200px) {
  #feds-footernav .feds-logo-text.feds-logo-text--hasTitleVariants > :first-child, #feds-footernav .feds-logo-text.feds-logo-text--hasTitleVariants > :nth-child(2), #feds-topnav .feds-logo-text.feds-logo-text--hasTitleVariants > :first-child, #feds-topnav .feds-logo-text.feds-logo-text--hasTitleVariants > :nth-child(2) {
    display: none; }
  #feds-footernav .feds-logo-text.feds-logo-text--hasTitleVariants > :last-child, #feds-topnav .feds-logo-text.feds-logo-text--hasTitleVariants > :last-child {
    display: block; } }

/*!
 * navImage v0.65.0 built on Tue, 15 Mar 2022 16:50:21 GMT
 *
 */
#feds-footernav .feds-image-link, #feds-topnav .feds-image-link {
  display: block; }

#feds-footernav .feds-image-link.feds-focus-ring, #feds-topnav .feds-image-link.feds-focus-ring {
  outline: 2px solid #109cde; }

#feds-footernav .feds-image-picture, #feds-topnav .feds-image-picture {
  display: block; }

#feds-footernav .feds-image, #feds-topnav .feds-image {
  display: block;
  max-width: 100%; }

#feds-topnav .feds-popup-trigger:not(.feds-popup-trigger--fullWidth) .feds-image {
  max-width: none; }

/*!
 * navText v0.65.0 built on Tue, 15 Mar 2022 16:50:21 GMT
 *
 */
#feds-footernav .feds-richText, #feds-topnav .feds-richText {
  line-height: 1.3; }

#feds-footernav .feds-richText p, #feds-topnav .feds-richText p {
  margin: 0; }

#feds-footernav .feds-richText u, #feds-topnav .feds-richText u {
  display: inline-block; }

#feds-footernav .feds-richText-link, #feds-topnav .feds-richText-link {
  color: inherit; }

#feds-footernav .feds-richText-link.feds-focus-ring, #feds-topnav .feds-richText-link.feds-focus-ring {
  outline: 2px solid #109cde; }

/*!
 * breadcrumbs v0.65.0 built on Tue, 15 Mar 2022 16:50:21 GMT
 *
 */
#feds-footernav .feds-breadcrumbs, #feds-topnav .feds-breadcrumbs {
  display: block;
  width: 100%; }

#feds-footernav .feds-breadcrumbs-wrapper, #feds-topnav .feds-breadcrumbs-wrapper {
  display: none;
  overflow: auto; }

@media screen and (min-width: 1200px) {
  #feds-footernav .feds-breadcrumbs-wrapper, #feds-topnav .feds-breadcrumbs-wrapper {
    display: -ms-flexbox;
    display: flex; } }

@media screen and (min-width: 900px) {
  .feds--smallDesktopEnabled #feds-footernav .feds-breadcrumbs-wrapper, .feds--smallDesktopEnabled #feds-topnav .feds-breadcrumbs-wrapper {
    display: -ms-flexbox;
    display: flex; } }

@media screen and (max-width: 899px) {
  html:not([dir=rtl]) #feds-footernav .feds-breadcrumbs-wrapper, html:not([dir=rtl]) #feds-topnav .feds-breadcrumbs-wrapper {
    padding-right: 0 !important; }
  html[dir=rtl] #feds-footernav .feds-breadcrumbs-wrapper, html[dir=rtl] #feds-topnav .feds-breadcrumbs-wrapper {
    padding-left: 0 !important; } }

@media screen and (max-width: 1199px) {
  html:not([dir=rtl]) #feds-header:not(.feds--smallDesktopEnabled) #feds-footernav .feds-breadcrumbs-wrapper, html:not([dir=rtl]) #feds-header:not(.feds--smallDesktopEnabled) #feds-topnav .feds-breadcrumbs-wrapper {
    padding-right: 0 !important; }
  html[dir=rtl] #feds-header:not(.feds--smallDesktopEnabled) #feds-footernav .feds-breadcrumbs-wrapper, html[dir=rtl] #feds-header:not(.feds--smallDesktopEnabled) #feds-topnav .feds-breadcrumbs-wrapper {
    padding-left: 0 !important; } }

#feds-footernav .feds-breadcrumbs-content, #feds-topnav .feds-breadcrumbs-content {
  display: -ms-flexbox;
  display: flex;
  width: 100%; }

#feds-footernav a.feds-breadcrumbs-logo.feds-focus-ring, #feds-topnav a.feds-breadcrumbs-logo.feds-focus-ring {
  outline: 2px solid #109cde; }

#feds-footernav .feds-breadcrumbs-logo, #feds-topnav .feds-breadcrumbs-logo {
  -ms-flex-item-align: start;
      align-self: flex-start;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 25px; }

#feds-footernav .feds-breadcrumbs-logo > img, #feds-footernav .feds-breadcrumbs-logo > svg, #feds-topnav .feds-breadcrumbs-logo > img, #feds-topnav .feds-breadcrumbs-logo > svg {
  display: block;
  width: 100%; }

#feds-footernav .feds-breadcrumbs-items, #feds-topnav .feds-breadcrumbs-items {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0; }

#feds-footernav .feds-breadcrumbs-element, #feds-topnav .feds-breadcrumbs-element {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  font-weight: 400;
  padding: 5px 10px 5px 0; }

html[dir=rtl] #feds-footernav .feds-breadcrumbs-element, html[dir=rtl] #feds-topnav .feds-breadcrumbs-element {
  padding: 5px 0 5px 10px; }

#feds-footernav .feds-breadcrumbs-element:before, #feds-topnav .feds-breadcrumbs-element:before {
  color: inherit;
  content: "\203a";
  line-height: 1.4;
  padding: 0 10px 0 0; }

html[dir=rtl] #feds-footernav .feds-breadcrumbs-element:before, html[dir=rtl] #feds-topnav .feds-breadcrumbs-element:before {
  padding: 0 0 0 10px; }

#feds-footernav .feds-breadcrumbs-element:first-child:before, #feds-topnav .feds-breadcrumbs-element:first-child:before {
  content: none;
  padding: 0; }

#feds-footernav a.feds-breadcrumbs-link, #feds-topnav a.feds-breadcrumbs-link {
  color: inherit;
  font-weight: inherit;
  line-height: 1.4;
  transition: color .1s ease; }

#feds-footernav a.feds-breadcrumbs-link.feds-focus-ring, #feds-topnav a.feds-breadcrumbs-link.feds-focus-ring {
  outline: 2px solid #109cde; }

#feds-footernav span.feds-breadcrumbs-active, #feds-topnav span.feds-breadcrumbs-active {
  color: inherit;
  font-weight: inherit;
  line-height: 1.4; }

#feds-footernav .feds-breadcrumbs-ellipsis, #feds-topnav .feds-breadcrumbs-ellipsis {
  font-weight: 700; }

#feds-footernav #gnav_hamburger_breadcrumbs, #feds-topnav #gnav_hamburger_breadcrumbs {
  -ms-flex-order: -1;
      order: -1; }

#feds-footernav #gnav_hamburger_breadcrumbs .feds-breadcrumbs-wrapper, #feds-topnav #gnav_hamburger_breadcrumbs .feds-breadcrumbs-wrapper {
  display: -ms-flexbox;
  display: flex; }

@media screen and (min-width: 1200px) {
  #feds-footernav #gnav_hamburger_breadcrumbs .feds-breadcrumbs-wrapper, #feds-topnav #gnav_hamburger_breadcrumbs .feds-breadcrumbs-wrapper {
    display: none; } }

@media screen and (min-width: 900px) {
  .feds--smallDesktopEnabled #feds-footernav #gnav_hamburger_breadcrumbs .feds-breadcrumbs-wrapper, .feds--smallDesktopEnabled #feds-topnav #gnav_hamburger_breadcrumbs .feds-breadcrumbs-wrapper {
    display: none; } }

/*!
 * feds v0.65.0 built on Tue, 15 Mar 2022 16:50:21 GMT
 *
 */
[class*=aem-AuthorLayer] #feds-subnav {
  position: relative; }

.Subnav-wrapper li, [lang] .Subnav-wrapper li {
  margin: 0; }

#AdobeSecondaryNav {
  max-width: 100vw; }

#AdobeSecondaryNav.Subnav-wrapper {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  font-family: inherit;
  -ms-flex-pack: center;
      justify-content: center;
  left: 0;
  min-height: 60px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%;
  transform: translateZ(0);
  transition: height .3s ease;
  z-index: 1; }

#AdobeSecondaryNav.Subnav-wrapper--active {
  opacity: 1;
  overflow: visible; }

#AdobeSecondaryNav.Subnav-wrapper *, #AdobeSecondaryNav.Subnav-wrapper :after, #AdobeSecondaryNav.Subnav-wrapper :before {
  box-sizing: border-box; }

.feds-header--rebranding #AdobeSecondaryNav.Subnav-wrapper {
  font-family: inherit; }

#AdobeSecondaryNav.Subnav-wrapper .Subnav-background {
  background-color: #f8f8f8;
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity .3s ease;
  width: 100%; }

.feds-header--rebranding #AdobeSecondaryNav.Subnav-wrapper .Subnav-background {
  background-color: #fafafa; }

#AdobeSecondaryNav.Subnav-wrapper a {
  text-decoration: none; }

#AdobeSecondaryNav.Subnav-wrapper ul {
  list-style: none; }

@media screen and (min-width: 600px) {
  #AdobeSecondaryNav.Subnav-wrapper {
    height: 80px;
    min-height: auto; }
  .feds-header-wrapper--retracted #AdobeSecondaryNav.Subnav-wrapper {
    height: 60px; } }

#AdobeSecondaryNav .Subnav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-pack: center;
      justify-content: center;
  min-height: inherit;
  position: relative;
  width: 100%; }

#AdobeSecondaryNav .Subnav.is-open .Subnav-logo:after {
  -ms-transform: rotate(-450deg);
      transform: rotate(-450deg); }

#AdobeSecondaryNav .Subnav.is-open .Subnav-menu-wrapper {
  display: -ms-flexbox;
  display: flex;
  visibility: visible; }

@media screen and (min-width: 600px) {
  #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav {
    width: auto; } }

#AdobeSecondaryNav .Subnav-trigger {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  bottom: 0;
  box-shadow: none;
  cursor: pointer;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1; }

#AdobeSecondaryNav .Subnav-trigger:active, #AdobeSecondaryNav .Subnav-trigger:focus, #AdobeSecondaryNav .Subnav-trigger:hover {
  background-color: transparent; }

@media screen and (min-width: 600px) {
  #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-trigger {
    display: none; } }

#AdobeSecondaryNav .Subnav-logo {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  height: auto;
  max-width: 100%;
  padding: 0 25px;
  transition: background-color .1s ease;
  width: auto; }

#AdobeSecondaryNav .Subnav-logo.feds-focus-ring {
  outline: 2px solid #109cde; }

#AdobeSecondaryNav .Subnav-logo:after {
  color: #2d2d2d;
  content: "\203a";
  display: none;
  font: 300 30px/1 inherit;
  margin: -4px 0 0 15px;
  -ms-transform: rotate(-270deg);
      transform: rotate(-270deg);
  -ms-transform-origin: 50% 60%;
      transform-origin: 50% 60%;
  transition: transform .1s ease; }

.feds-header--rebranding #AdobeSecondaryNav .Subnav-logo:after {
  color: #4b4b4b;
  font-family: inherit; }

#AdobeSecondaryNav .Subnav-logo:nth-last-child(2):after {
  display: block; }

@media screen and (min-width: 600px) {
  #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) a.Subnav-logo:focus, #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) a.Subnav-logo:hover {
    background-color: rgba(0, 0, 0, 0.1); }
  #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-logo {
    padding: 0 25px 0 20px; }
  #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-logo:nth-last-child(2):after {
    display: none; } }

#AdobeSecondaryNav .Subnav-logo-image, #AdobeSecondaryNav .Subnav-logo-image:after, #AdobeSecondaryNav .Subnav-logo-image:before {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  font-size: 25px;
  height: 25px;
  line-height: 1; }

@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #AdobeSecondaryNav .Subnav-logo-image, #AdobeSecondaryNav .Subnav-logo-image:after, #AdobeSecondaryNav .Subnav-logo-image:before {
    -ms-flex-preferred-size: 25px;
        flex-basis: 25px; } }

#AdobeSecondaryNav .Subnav-logo-text {
  color: #2d2d2d;
  font-size: 15px;
  font-weight: 900;
  line-height: 1;
  padding: 10px 0;
  text-transform: uppercase; }

#AdobeSecondaryNav .Subnav-logo-text:nth-child(2) {
  margin-left: 15px; }

.feds-header--rebranding #AdobeSecondaryNav .Subnav-logo-text {
  color: #4b4b4b;
  font-weight: 700; }

@media screen and (min-width: 600px) {
  #AdobeSecondaryNav .Subnav-logo-text {
    font-size: 13px; } }

#AdobeSecondaryNav .Subnav-menu-wrapper {
  background-color: #fff;
  box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.2);
  display: none;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 100%;
  visibility: hidden; }

.feds-header--rebranding #AdobeSecondaryNav .Subnav-menu-wrapper {
  background-color: #fafafa; }

@media screen and (min-width: 600px) {
  #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-menu-wrapper {
    background-color: transparent;
    box-shadow: none;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    position: static;
    visibility: visible; } }

#AdobeSecondaryNav .Subnav-menu {
  margin: 0;
  max-height: 50vh;
  overflow-y: auto;
  padding: 10px 0 0;
  width: 100%; }

@media screen and (min-width: 600px) {
  #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-menu {
    display: -ms-flexbox;
    display: flex;
    max-height: none;
    overflow-y: visible;
    padding: 0;
    width: auto; } }

#AdobeSecondaryNav .Subnav-menu-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  padding-bottom: 1px;
  position: relative; }

#AdobeSecondaryNav .Subnav-menu-item:before {
  border-top: 1px solid #f3f3f3;
  bottom: 0;
  content: "";
  left: 0;
  margin: 0 40px;
  position: absolute;
  right: 0; }

#AdobeSecondaryNav .Subnav-menu-item.has-submenu .Subnav-menu-label {
  padding-right: 65px; }

#AdobeSecondaryNav .Subnav-menu-item.has-submenu .Subnav-menu-label:after {
  display: block; }

#AdobeSecondaryNav .Subnav-menu-item.is-open > .Subnav-menu-label {
  background-color: #f4f4f4; }

#AdobeSecondaryNav .Subnav-menu-item.is-open > .Subnav-menu-label:after {
  -ms-transform: rotate(-180deg);
      transform: rotate(-180deg); }

#AdobeSecondaryNav .Subnav-menu-item.is-open > .Subnav-submenus {
  max-height: none;
  visibility: visible; }

@media screen and (min-width: 600px) {
  #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-menu-item {
    -ms-flex-direction: row;
        flex-direction: row;
    padding-bottom: 0; }
  #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-menu-item:before {
    display: none; }
  #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-menu-item.has-submenu .Subnav-menu-label {
    padding-right: 18px; }
  #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-menu-item.has-submenu .Subnav-menu-label:after {
    display: none; }
  #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-menu-item.has-submenu .Subnav-menu-label:focus:after, #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-menu-item.has-submenu .Subnav-menu-label:hover:after {
    display: block; }
  #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-menu-item.is-open > .Subnav-menu-label {
    background-color: rgba(0, 0, 0, 0.1); }
  #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-menu-item.is-open > .Subnav-menu-label:before {
    display: block; }
  #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-menu-item.is-open > .Subnav-menu-label:focus:after, #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-menu-item.is-open > .Subnav-menu-label:hover:after {
    display: none; }
  #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-menu-item.is-open > .Subnav-submenus {
    display: -ms-flexbox;
    display: flex;
    max-height: 50vh; }
  #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-menu-item.is-hiddenOnDesktopLayout {
    display: none;
    visibility: hidden; } }

#AdobeSecondaryNav .Subnav-menu-label {
  -ms-flex-align: center;
      align-items: center;
  color: #2d2d2d;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  height: 55px;
  padding: 0 50px;
  position: relative;
  transform: translateZ(0);
  transition: background-color .1s ease; }

#AdobeSecondaryNav .Subnav-menu-label.feds-focus-ring {
  outline: 2px solid #109cde; }

#AdobeSecondaryNav .Subnav-menu-label.is-active:not([aria-haspopup]) {
  cursor: default;
  pointer-events: none; }

.feds-header--rebranding #AdobeSecondaryNav .Subnav-menu-label {
  color: #4b4b4b;
  font-size: 15px;
  font-weight: 400; }

#AdobeSecondaryNav .Subnav-menu-label:after {
  border-color: #b2b2b2 transparent transparent;
  border-style: solid;
  border-width: 4px 4px 0;
  content: "";
  display: none;
  margin-top: -1.33333333px;
  position: absolute;
  right: 50px;
  top: 50%;
  -ms-transform-origin: 50% 33%;
      transform-origin: 50% 33%;
  transition: transform .1s ease; }

@media screen and (min-width: 600px) {
  #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-menu-label {
    font-size: 14px;
    height: auto;
    padding: 0 18px; }
  #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-menu-label.is-active, #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-menu-label:focus, #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-menu-label:hover {
    background-color: rgba(0, 0, 0, 0.1); }
  .feds-header--rebranding #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-menu-label.is-active, .feds-header--rebranding #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-menu-label:focus, .feds-header--rebranding #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-menu-label:hover {
    background-color: rgba(0, 0, 0, 0.05); }
  .feds-header--rebranding #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-menu-label {
    font-size: 13px;
    font-weight: 500; }
  #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-menu-label:before {
    border-color: transparent transparent #fff;
    border-style: solid;
    border-width: 0 10px 10px;
    bottom: -4px;
    content: "";
    display: none;
    left: 50%;
    margin-left: -10px;
    pointer-events: none;
    position: absolute; }
  .feds-header--rebranding #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-menu-label:before {
    border-color: transparent transparent #fafafa; }
  #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-menu-label:after {
    border-width: 3px 3px 0;
    bottom: 9px;
    display: none;
    left: 50%;
    margin-left: -3px;
    margin-top: auto;
    right: auto;
    top: auto; } }

#AdobeSecondaryNav .Subnav-submenus {
  max-height: 0;
  overflow: hidden;
  visibility: hidden; }

@media screen and (min-width: 600px) {
  #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-submenus {
    background-color: #fff;
    box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.2);
    display: none;
    left: 0;
    overflow-y: auto;
    position: absolute;
    top: calc(100% + 4px);
    transition: none; }
  .feds-header--rebranding #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-submenus {
    background-color: #fafafa; } }

#AdobeSecondaryNav .Subnav-submenu-wrapper {
  padding: 15px 0;
  position: relative; }

@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #AdobeSecondaryNav .Subnav-submenu-wrapper {
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    white-space: normal; } }

#AdobeSecondaryNav .Subnav-submenu-wrapper + .Subnav-submenu-wrapper:before {
  border-top: 1px solid #f3f3f3;
  content: "";
  left: 0;
  margin: 0 40px;
  position: absolute;
  right: 0;
  top: 0; }

@media screen and (min-width: 600px) {
  #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-submenu-wrapper {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    min-width: 240px;
    padding: 25px 0;
    white-space: nowrap; }
  #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-submenu-wrapper + .Subnav-submenu-wrapper {
    border-left: 1px solid #f3f3f3; }
  #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-submenu-wrapper + .Subnav-submenu-wrapper:before {
    display: none; } }

#AdobeSecondaryNav .Subnav-submenu-headline {
  color: #767676;
  display: none;
  font-size: 16px;
  font-weight: 300;
  margin: 0 0 15px;
  overflow: hidden;
  padding: 10px 35px 0; }

.feds-header--rebranding #AdobeSecondaryNav .Subnav-submenu-headline {
  color: #4b4b4b;
  font-size: 13px;
  font-weight: 500; }

@media screen and (min-width: 600px) {
  #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-submenu-headline {
    display: block; } }

#AdobeSecondaryNav .Subnav-submenu {
  padding: 0; }

#AdobeSecondaryNav .Subnav-submenu-link {
  color: #2d2d2d;
  display: block;
  font-size: 15px;
  padding: 10px 65px; }

#AdobeSecondaryNav .Subnav-submenu-link.feds-focus-ring {
  outline: 2px solid #109cde; }

.feds-header--rebranding #AdobeSecondaryNav .Subnav-submenu-link {
  color: #4b4b4b;
  font-size: 14px;
  font-weight: 400; }

@media screen and (min-width: 600px) {
  #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-submenu-link {
    color: #2d2d2d;
    font-size: 14px;
    padding: 10px 35px;
    transition: color .1s ease,background-color .1s ease; }
  #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-submenu-link:focus, #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-submenu-link:hover {
    background-color: #f4f4f4;
    color: #2b9af3; }
  .feds-header--rebranding #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-submenu-link {
    color: #4b4b4b;
    font-size: 13px; }
  .feds-header--rebranding #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-submenu-link:focus, .feds-header--rebranding #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-submenu-link:hover {
    color: #1473e6; } }

#AdobeSecondaryNav .Subnav-submenu-title {
  display: block;
  overflow: hidden; }

#AdobeSecondaryNav .Subnav-submenu-description {
  color: #656565;
  display: none;
  font-size: 13px;
  font-weight: 300;
  overflow: hidden; }

.feds-header--rebranding #AdobeSecondaryNav .Subnav-submenu-description {
  color: #6e6e6e;
  font-size: 12px;
  font-weight: 400; }

@media screen and (min-width: 600px) {
  #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-submenu-description {
    display: block; } }

#AdobeSecondaryNav .Subnav-menu-buttons {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 20px 50px; }

@media screen and (min-width: 600px) {
  #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-menu-buttons {
    -ms-flex-align: center;
        align-items: center;
    padding: 0 20px 0 25px; } }

#AdobeSecondaryNav .Subnav-menu-button {
  -ms-flex-align: center;
      align-items: center;
  background-color: #1473e6;
  border: 2px solid #1473e6;
  border-radius: 16px;
  box-sizing: border-box;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  font-size: 15px;
  font-weight: 700;
  height: 32px;
  -ms-flex-pack: center;
      justify-content: center;
  line-height: 0;
  min-width: 72px;
  overflow: visible;
  padding: 0 14px !important;
  transition: background-color .13s ease-out,border-color .13s ease-out;
  white-space: nowrap;
  width: auto; }

#AdobeSecondaryNav .Subnav-menu-button:focus, #AdobeSecondaryNav .Subnav-menu-button:hover {
  box-shadow: none; }

#AdobeSecondaryNav .Subnav-menu-button:focus {
  outline-offset: 0; }

#AdobeSecondaryNav .Subnav-menu-button .feds-login-text, #AdobeSecondaryNav .Subnav-menu-button .feds-navLink-text {
  font-weight: 700;
  transition: color .13s ease-out; }

.feds-header--rebranding #AdobeSecondaryNav .Subnav-menu-button .feds-login-text, .feds-header--rebranding #AdobeSecondaryNav .Subnav-menu-button .feds-navLink-text {
  font-weight: 500; }

#AdobeSecondaryNav .Subnav-menu-button .feds-navLink-description, #AdobeSecondaryNav .Subnav-menu-button .feds-navLink-image {
  display: none;
  visibility: hidden; }

.feds-header--rebranding #AdobeSecondaryNav .Subnav-menu-button {
  font-size: 13px;
  font-weight: 500; }

#AdobeSecondaryNav .Subnav-menu-button:focus, #AdobeSecondaryNav .Subnav-menu-button:hover {
  background-color: #0d66d0 !important;
  border-color: #0d66d0; }

#AdobeSecondaryNav .Subnav-menu-button .feds-login-text, #AdobeSecondaryNav .Subnav-menu-button .feds-navLink-text, #AdobeSecondaryNav .Subnav-menu-button:focus .feds-login-text, #AdobeSecondaryNav .Subnav-menu-button:focus .feds-navLink-text, #AdobeSecondaryNav .Subnav-menu-button:hover .feds-login-text, #AdobeSecondaryNav .Subnav-menu-button:hover .feds-navLink-text {
  color: #fff !important; }

#AdobeSecondaryNav .Subnav-menu-button, #AdobeSecondaryNav .Subnav-menu-button:focus, #AdobeSecondaryNav .Subnav-menu-button:hover {
  color: #fff; }

#AdobeSecondaryNav .Subnav-menu-button + .Subnav-menu-button {
  margin-left: 15px; }

#AdobeSecondaryNav.Subnav-wrapper.is-dark .Subnav-background {
  background-color: rgba(0, 0, 0, 0.7); }

#AdobeSecondaryNav.Subnav-wrapper.is-dark .Subnav-logo-text, #AdobeSecondaryNav.Subnav-wrapper.is-dark .Subnav-logo:after {
  color: #fff; }

@media screen and (min-width: 600px) {
  #AdobeSecondaryNav.Subnav-wrapper.is-dark:not(.has-mobileLayout) .Subnav-menu-label {
    color: #fff; }
  #AdobeSecondaryNav.Subnav-wrapper.is-dark .Subnav-menu-item.is-open .Subnav-menu-label, #AdobeSecondaryNav.Subnav-wrapper.is-dark .Subnav-menu-label.is-active, #AdobeSecondaryNav.Subnav-wrapper.is-dark .Subnav-menu-label:focus, #AdobeSecondaryNav.Subnav-wrapper.is-dark .Subnav-menu-label:hover, #AdobeSecondaryNav.Subnav-wrapper.is-dark a.Subnav-logo.is-active, #AdobeSecondaryNav.Subnav-wrapper.is-dark a.Subnav-logo:focus, #AdobeSecondaryNav.Subnav-wrapper.is-dark a.Subnav-logo:hover {
    background-color: rgba(255, 255, 255, 0.1); } }

.feds-header-wrapper--scrolled #AdobeSecondaryNav.Subnav-wrapper.has-gradient .Subnav-background {
  opacity: 1 !important; }

[dir=rtl] #AdobeSecondaryNav.Subnav-wrapper {
  direction: rtl; }

[dir=rtl] #AdobeSecondaryNav .Subnav.is-open .Subnav-logo:after {
  -ms-transform: rotate(90deg);
      transform: rotate(90deg); }

[dir=rtl] #AdobeSecondaryNav .Subnav-logo:after {
  margin: -4px 15px 0 0;
  -ms-transform: rotate(-90deg);
      transform: rotate(-90deg); }

@media screen and (min-width: 600px) {
  [dir=rtl] #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-logo {
    padding: 0 20px 0 25px; } }

[dir=rtl] #AdobeSecondaryNav .Subnav-logo-text:nth-child(2) {
  margin-left: 0;
  margin-right: 15px; }

[dir=rtl] #AdobeSecondaryNav .Subnav-menu-item.is-open > .Subnav-menu-label:after {
  -ms-transform: rotate(180deg);
      transform: rotate(180deg); }

[dir=rtl] #AdobeSecondaryNav .Subnav-menu-item.has-submenu .Subnav-menu-label {
  padding-left: 65px;
  padding-right: 50px; }

@media screen and (min-width: 600px) {
  [dir=rtl] #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-menu-item.has-submenu .Subnav-menu-label {
    padding: 0 18px; }
  [dir=rtl] #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-menu-item.has-submenu .Subnav-menu-label:after {
    left: 50%; } }

[dir=rtl] #AdobeSecondaryNav .Subnav-menu-label:after {
  left: 50px;
  right: auto; }

@media screen and (min-width: 600px) {
  [dir=rtl] #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-submenus {
    left: auto;
    right: 0; }
  [dir=rtl] #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-submenu-wrapper + .Subnav-submenu-wrapper {
    border-left: none;
    border-right: 1px solid #f3f3f3; }
  [dir=rtl] #AdobeSecondaryNav.Subnav-wrapper:not(.has-mobileLayout) .Subnav-menu-buttons {
    padding: 0 25px 0 20px; } }

@media screen and (max-width: 599px) {
  .feds-hideOnMobile {
    display: none !important; } }

@media screen and (min-width: 600px) and (max-width: 899px) {
  #feds-header.feds--smallDesktopEnabled .feds-hideOnMobile, #feds-header:not(.feds--smallDesktopEnabled) .feds-hideOnTablet {
    display: none !important; } }

@media screen and (min-width: 900px) and (max-width: 1199px) {
  .feds-hideOnTablet {
    display: none !important; } }

@media screen and (min-width: 1200px) {
  .feds-hideOnDesktop {
    display: none !important; } }

.feds-relativePosition {
  position: relative; }

html.feds-has-customPath body:before {
  background-color: #de2e3e;
  border-radius: 4px 0 0 0;
  bottom: 0;
  color: #fff;
  content: "feds custom path";
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  padding: 8px 15px;
  pointer-events: none;
  position: fixed;
  right: 0;
  text-transform: uppercase;
  z-index: 9999; }

#feds-footer, #feds-header {
  -webkit-font-smoothing: antialiased;
  max-width: 100vw; }

#feds-footer, #feds-footer *, #feds-footer :after, #feds-footer :before, #feds-footer:after, #feds-footer:before, #feds-header, #feds-header *, #feds-header :after, #feds-header :before, #feds-header:after, #feds-header:before {
  box-sizing: border-box; }

#feds-footer a[class^=feds], #feds-footer a[class^=feds]:focus, #feds-footer a[class^=feds]:hover, #feds-header a[class^=feds], #feds-header a[class^=feds]:focus, #feds-header a[class^=feds]:hover {
  text-decoration: none; }

#feds-footer ul, #feds-header ul {
  list-style-type: none; }

#feds-footer {
  overflow: hidden; }

.gnavFooter aside, .gnavFooter li, .gnavFooter p, .gnavTopnav aside, .gnavTopnav li, .gnavTopnav p, [lang] .gnavFooter aside, [lang] .gnavFooter li, [lang] .gnavFooter p, [lang] .gnavTopnav aside, [lang] .gnavTopnav li, [lang] .gnavTopnav p {
  font-size: 14px;
  line-height: 1;
  margin: 0; }

#gnt_1893 .feds-login {
  color: #2C2C2C;
  padding: 10px 4px;
  font-size: 14px; }

#feds-topnav a.feds-search-trigger {
  padding-left: 1rem !important;
  padding-right: 1rem !important; }

.feds-navList-hamburger {
  cursor: pointer !important; }

#feds-topnav {
  z-index: 100 !important; }

#feds-topnav .feds-navList-item.feds-dropdown--active {
  position: unset !important; }

.feds-searchForm {
  display: none !important; }

#feds-topnav a.feds-login {
  padding-right: 1.25rem !important; }

/*! adobe-profile - v1.1.15 - 02-01-2022, 10:00:00 AM

ADOBE CONFIDENTIAL
==================
Copyright 2022 Adobe Systems Incorporated
All Rights Reserved.

NOTICE: All information contained herein is, and remains
the property of Adobe Systems Incorporated and its suppliers,
if any. The intellectual and technical concepts contained
herein are proprietary to Adobe Systems Incorporated and its
suppliers and are protected by trade secret or copyright law.
Dissemination of this information or reproduction of this material
is strictly forbidden unless prior written permission is obtained
from Adobe Systems Incorporated.
*/
.Profile-avatar, .Profile-thumbnail {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }

.Profile-button, .Profile-header-cta, .Profile-menu-link {
  text-decoration: none;
  transition: all 125ms ease-in-out; }

.Profile-header:focus, .Profile-menu-link:focus, .Profile-thumbnail:focus, .accessibility-focus:focus {
  outline-offset: -3px; }

.Profile-thumbnail {
  display: block;
  width: 30.1px;
  height: 30px;
  border-radius: 4px;
  cursor: pointer; }

.Profile-thumbnail.has-notification {
  position: relative; }

.Profile-thumbnail.has-notification:after {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 12px;
  height: 12px;
  border-width: 2px;
  border-style: solid;
  border-color: inherit;
  border-radius: 50%;
  box-sizing: border-box;
  content: ''; }

.Profile-thumbnail.has-notification.is-warning:after {
  background-color: #F53C3C;
  background-clip: content-box; }

.Profile-header {
  text-decoration: none;
  padding: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  border-bottom: 1px solid #E1E1E1; }

.Profile-header:active .Profile-header-cta, .Profile-header:hover .Profile-header-cta {
  color: #2C2C2C;
  box-shadow: inset 0 -1px 0 0 #2C2C2C; }

.Profile-avatar {
  width: 64px;
  height: 64px;
  border-radius: 4px;
  -ms-flex-negative: 0;
  flex-shrink: 0; }

.Profile-data {
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-left: 16px;
  overflow: hidden;
  -ms-flex-order: 1;
  order: 1; }

.Profile-name {
  font-size: 18px;
  line-height: 1.4;
  font-weight: 700;
  color: #2C2C2C;
  padding: 0;
  margin: -3px 0 0;
  word-wrap: break-word; }

.Profile-name_long {
  font-size: 14px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  max-height: 39.2px; }

.Profile-email {
  font-size: 14px;
  margin: -1px 0 12px;
  padding: 0;
  line-height: 1.25;
  color: #707070;
  word-break: break-all; }

.Profile-header-cta {
  font-size: 14px;
  padding-bottom: 1px;
  display: inline-block;
  color: #4B4B4B;
  box-shadow: inset 0 -1px 0 0 #4B4B4B; }

.Profile-notification {
  padding: 20px;
  border-bottom: 1px solid #E1E1E1;
  text-align: center; }

.Profile-notification-details {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
  -ms-flex-align: start;
  align-items: flex-start; }

.Profile-notification-message {
  padding: 0;
  margin: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  color: #4B4B4B;
  line-height: 1.5;
  text-align: left; }

.Profile-button {
  font-size: 15px;
  font-weight: 700;
  display: inline-block;
  padding: 7.5px 16px 9.5px;
  text-transform: capitalize;
  color: #4B4B4B;
  border-radius: 16px;
  background-color: transparent; }

.Profile-button:active, .Profile-button:hover {
  color: #2C2C2C;
  background-color: #F5F5F5; }

.Profile-button.is-warning {
  padding: 5.5px 14px 7.5px;
  color: #F53C3C;
  border: 2px solid #F53C3C; }

.Profile-button.is-warning:active, .Profile-button.is-warning:hover {
  color: #FFF;
  background-color: #F53C3C; }

.Profile-menu {
  margin: 6px 0;
  padding: 0;
  list-style: none; }

.Profile-menu-link {
  display: block;
  padding: 8px 20px;
  font-size: 14px;
  line-height: 1.5;
  color: #4B4B4B; }

.Profile-menu-link:active, .Profile-menu-link:hover {
  color: #2C2C2C;
  background-color: #F5F5F5; }

.Profile-localLinks-menu {
  padding: 6px 0; }

.Profile-localLinks-menu .Profile-menu {
  margin: 0; }

.Profile-localLinks-menu .Profile-menu-text {
  max-height: 42px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  word-wrap: break-word; }

.Profile-localLinks-title {
  padding: 8px 20px;
  color: #707070;
  font-size: 11px;
  line-height: 1.5;
  text-transform: uppercase;
  font-weight: 600; }

.Profile-notification-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  -ms-flex-negative: 0;
  flex-shrink: 0; }

.Profile-notification-icon svg {
  max-width: 100%;
  display: block; }

.Profile-notification-icon.is-warning path {
  fill: #F53C3C; }

.Profile.theme-dark .Profile-dropdown {
  background-color: #161616; }

.Profile.theme-dark .Profile-name {
  color: #FCFCFC; }

.Profile.theme-dark .Profile-email, .Profile.theme-dark .Profile-notification-message {
  color: #999; }

.Profile.theme-dark .Profile-applications, .Profile.theme-dark .Profile-content, .Profile.theme-dark .Profile-dropdown, .Profile.theme-dark .Profile-header, .Profile.theme-dark .Profile-localLinks-menu, .Profile.theme-dark .Profile-notification {
  border-color: #313131; }

.Profile.theme-dark .Profile-button, .Profile.theme-dark .Profile-menu-link {
  color: #B9B9B9; }

.Profile.theme-dark .Profile-button:active, .Profile.theme-dark .Profile-button:hover, .Profile.theme-dark .Profile-menu-link:active, .Profile.theme-dark .Profile-menu-link:hover {
  color: #CDCDCD;
  background-color: #232323; }

.Profile.theme-dark .Profile-button.is-warning {
  color: #F53C3C; }

.Profile.theme-dark .Profile-button.is-warning:active, .Profile.theme-dark .Profile-button.is-warning:hover {
  color: #161616;
  background-color: #F53C3C; }

.Profile.theme-dark .Profile-header-cta {
  color: #FFF;
  box-shadow: inset 0 -1px 0 0 #FFF; }

.Profile.theme-dark .Profile-header:active .Profile-header-cta, .Profile.theme-dark .Profile-header:hover .Profile-header-cta {
  color: #E2E2E2;
  box-shadow: inset 0 -1px 0 0 #E2E2E2; }

.Profile.theme-grey .Profile-dropdown {
  background-color: #282828; }

.Profile.theme-grey .Profile-name {
  color: #FCFCFC; }

.Profile.theme-grey .Profile-email, .Profile.theme-grey .Profile-notification-message {
  color: #999; }

.Profile.theme-grey .Profile-applications, .Profile.theme-grey .Profile-content, .Profile.theme-grey .Profile-dropdown, .Profile.theme-grey .Profile-header, .Profile.theme-grey .Profile-localLinks-menu, .Profile.theme-grey .Profile-notification {
  border-color: #3E3E3E; }

.Profile.theme-grey .Profile-button, .Profile.theme-grey .Profile-menu-link {
  color: #CDCDCD; }

.Profile.theme-grey .Profile-button:active, .Profile.theme-grey .Profile-button:hover, .Profile.theme-grey .Profile-menu-link:active, .Profile.theme-grey .Profile-menu-link:hover {
  color: #FDFDFE;
  background-color: #323232; }

.Profile.theme-grey .Profile-button.is-warning {
  color: #F53C3C; }

.Profile.theme-grey .Profile-button.is-warning:active, .Profile.theme-grey .Profile-button.is-warning:hover {
  color: #282828;
  background-color: #F53C3C; }

.Profile.theme-grey .Profile-header-cta {
  color: #FFF;
  box-shadow: inset 0 -1px 0 0 #FFF; }

.Profile.theme-grey .Profile-header:active .Profile-header-cta, .Profile.theme-grey .Profile-header:hover .Profile-header-cta {
  color: #E2E2E2;
  box-shadow: inset 0 -1px 0 0 #E2E2E2; }

[dir=rtl] .Profile {
  text-align: right; }

[dir=rtl] .Profile-data {
  margin-left: 0;
  margin-right: 16px; }

[dir=rtl] .Profile-dropdown {
  right: auto;
  left: 0; }

.Profile {
  font: 400 14px/1 adobe-clean,sans-serif;
  box-sizing: border-box;
  color: #4B4B4B;
  text-align: left;
  position: relative;
  width: 30px;
  height: 30px;
  display: block;
  letter-spacing: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.Profile *, .Profile :after, .Profile :before {
  box-sizing: inherit; }

.Profile.Profile-hidden .Profile-dropdown {
  display: none;
  visibility: hidden; }

.Profile.Profile-invisible .Profile-dropdown {
  display: block;
  visibility: hidden; }

.Profile.Profile-collapsed .Profile-dropdown {
  animation: profile-collapsed .2s ease 0s 1 both; }

.Profile.Profile-expanded .Profile-dropdown {
  animation: profile-expanded .2s ease 0s 1 both; }

@keyframes profile-collapsed {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes profile-expanded {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.Profile-dropdown {
  width: 320px;
  position: absolute;
  top: 30px;
  right: 0;
  border: 1px solid #E1E1E1;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #FFF;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.1); }

.Profile-applications, .Profile-content, .Profile-localLinks-menu {
  border-bottom: 1px solid #E1E1E1; }

/*
 *  Copyright 2020 Adobe. All rights reserved.
 *  This file is licensed to you under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License. You may obtain a copy
 *  of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software distributed under
 *  the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 *  OF ANY KIND, either express or implied. See the License for the specific language
 *  governing permissions and limitations under the License.
 */
.app-launcher-container html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

.app-launcher-container body {
  margin: 0; }

.app-launcher-container article, .app-launcher-container aside, .app-launcher-container footer, .app-launcher-container header, .app-launcher-container nav, .app-launcher-container section {
  display: block; }

.app-launcher-container h1 {
  font-size: 2em;
  margin: .67em 0; }

.app-launcher-container figcaption, .app-launcher-container figure, .app-launcher-container main {
  display: block; }

.app-launcher-container figure {
  margin: 1em 40px; }

.app-launcher-container hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

.app-launcher-container pre {
  font-family: monospace,monospace;
  font-size: 1em; }

.app-launcher-container a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

.app-launcher-container abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

.app-launcher-container b, .app-launcher-container strong {
  font-weight: inherit; }

.app-launcher-container b, .app-launcher-container strong {
  font-weight: bolder; }

.app-launcher-container code, .app-launcher-container kbd, .app-launcher-container samp {
  font-family: monospace,monospace;
  font-size: 1em; }

.app-launcher-container dfn {
  font-style: italic; }

.app-launcher-container mark {
  background-color: #ff0;
  color: #000; }

.app-launcher-container small {
  font-size: 80%; }

.app-launcher-container sub, .app-launcher-container sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

.app-launcher-container sub {
  bottom: -.25em; }

.app-launcher-container sup {
  top: -.5em; }

.app-launcher-container audio, .app-launcher-container video {
  display: inline-block; }

.app-launcher-container audio:not([controls]) {
  display: none;
  height: 0; }

.app-launcher-container img {
  border-style: none; }

.app-launcher-container svg:not(:root) {
  overflow: hidden; }

.app-launcher-container button, .app-launcher-container input, .app-launcher-container optgroup, .app-launcher-container select, .app-launcher-container textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

.app-launcher-container button, .app-launcher-container input {
  overflow: visible; }

.app-launcher-container button, .app-launcher-container select {
  text-transform: none; }

.app-launcher-container [type=reset], .app-launcher-container [type=submit], .app-launcher-container button, .app-launcher-container html [type=button] {
  -webkit-appearance: button; }

.app-launcher-container [type=button]::-moz-focus-inner, .app-launcher-container [type=reset]::-moz-focus-inner, .app-launcher-container [type=submit]::-moz-focus-inner, .app-launcher-container button::-moz-focus-inner {
  border-style: none;
  padding: 0; }

.app-launcher-container [type=button]:-moz-focusring, .app-launcher-container [type=reset]:-moz-focusring, .app-launcher-container [type=submit]:-moz-focusring, .app-launcher-container button:-moz-focusring {
  outline: 1px dotted ButtonText; }

.app-launcher-container fieldset {
  padding: .35em .75em .625em; }

.app-launcher-container legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

.app-launcher-container progress {
  display: inline-block;
  vertical-align: baseline; }

.app-launcher-container textarea {
  overflow: auto; }

.app-launcher-container [type=checkbox], .app-launcher-container [type=radio] {
  box-sizing: border-box;
  padding: 0; }

.app-launcher-container [type=number]::-webkit-inner-spin-button, .app-launcher-container [type=number]::-webkit-outer-spin-button {
  height: auto; }

.app-launcher-container [type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

.app-launcher-container [type=search]::-webkit-search-cancel-button, .app-launcher-container [type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

.app-launcher-container ::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

.app-launcher-container details, .app-launcher-container menu {
  display: block; }

.app-launcher-container summary {
  display: list-item; }

.app-launcher-container canvas {
  display: inline-block; }

.app-launcher-container template {
  display: none; }

.app-launcher-container [hidden] {
  display: none; }

.app-launcher-container .react-spectrum-provider {
  position: relative; }

.app-launcher-container .spectrum--light {
  background-color: #f5f5f5;
  -webkit-tap-highlight-color: transparent; }

.app-launcher-container .spectrum--dark {
  background-color: #323232;
  -webkit-tap-highlight-color: transparent; }

.app-launcher-container .spectrum {
  font-family: adobe-clean,"Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,sans-serif;
  font-size: 14px; }

.app-launcher-container .spectrum:lang(zh-Hans) {
  font-family: adobe-clean-han-japanese,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,sans-serif; }

.app-launcher-container .spectrum:lang(zh) {
  font-family: adobe-clean-han-japanese,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,sans-serif; }

.app-launcher-container .spectrum:lang(ko) {
  font-family: adobe-clean-han-japanese,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,sans-serif; }

.app-launcher-container .spectrum:lang(ja) {
  font-family: adobe-clean-han-japanese,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,sans-serif; }

.app-launcher-container .spectrum-Body1 {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 16px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Body1 em {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  font-style: italic;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Body1 strong {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Body--large, .app-launcher-container .spectrum-Body2 {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 16px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Body--large em, .app-launcher-container .spectrum-Body2 em {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  font-style: italic;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Body--large strong, .app-launcher-container .spectrum-Body2 strong {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Body3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 16px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Body3 em {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  font-style: italic;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Body3 strong {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Body--secondary, .app-launcher-container .spectrum-Body4 {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 16px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Body--secondary em, .app-launcher-container .spectrum-Body4 em {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  font-style: italic;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Body--secondary strong, .app-launcher-container .spectrum-Body4 strong {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Body--small, .app-launcher-container .spectrum-Body5 {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 16px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Body--small em, .app-launcher-container .spectrum-Body5 em {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  font-style: italic;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Body--small strong, .app-launcher-container .spectrum-Body5 strong {
  font-size: 12px;
  font-weight: 700;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading1 {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Heading1 em {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.3;
  font-style: italic;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading1 strong {
  font-size: 36px;
  font-weight: 900;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading2 {
  font-size: 28px;
  font-weight: 700;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Heading2 em {
  font-size: 28px;
  font-weight: 700;
  line-height: 1.3;
  font-style: italic;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading2 strong {
  font-size: 28px;
  font-weight: 900;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading3 {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Heading3 em {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.3;
  font-style: italic;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading3 strong {
  font-size: 22px;
  font-weight: 900;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading--subtitle1, .app-launcher-container .spectrum-Heading4 {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Heading--subtitle1 em, .app-launcher-container .spectrum-Heading4 em {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.3;
  font-style: italic;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading--subtitle1 strong, .app-launcher-container .spectrum-Heading4 strong {
  font-size: 18px;
  font-weight: 900;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading5 {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Heading5 em {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.3;
  font-style: italic;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading5 strong {
  font-size: 16px;
  font-weight: 900;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading--subtitle2, .app-launcher-container .spectrum-Heading6 {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Heading--subtitle2 em, .app-launcher-container .spectrum-Heading6 em {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.3;
  font-style: italic;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading--subtitle2 strong, .app-launcher-container .spectrum-Heading6 strong {
  font-size: 14px;
  font-weight: 900;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading--subtitle3, .app-launcher-container .spectrum-Subheading {
  font-size: 11px;
  font-weight: 700;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .06em;
  text-transform: uppercase;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Heading--subtitle3 em, .app-launcher-container .spectrum-Subheading em {
  font-size: 11px;
  font-weight: 700;
  line-height: 1.3;
  font-style: italic;
  letter-spacing: .06em;
  text-transform: uppercase; }

.app-launcher-container .spectrum-Heading--subtitle3 strong, .app-launcher-container .spectrum-Subheading strong {
  font-size: 11px;
  font-weight: 900;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .06em;
  text-transform: uppercase; }

.app-launcher-container .spectrum-Detail {
  font-size: 11px;
  font-weight: 400;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .06em;
  text-transform: uppercase;
  margin-bottom: 16px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Detail em {
  font-size: 11px;
  font-weight: 400;
  line-height: 1.5;
  font-style: italic;
  letter-spacing: .06em;
  text-transform: uppercase; }

.app-launcher-container .spectrum-Detail strong {
  font-size: 11px;
  font-weight: 700;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .06em;
  text-transform: uppercase; }

.app-launcher-container .spectrum-Heading1--quiet {
  font-size: 36px;
  font-weight: 300;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Heading1--quiet em {
  font-size: 36px;
  font-weight: 300;
  line-height: 1.3;
  font-style: italic;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading1--quiet strong {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading--pageTitle, .app-launcher-container .spectrum-Heading2--quiet {
  font-size: 28px;
  font-weight: 300;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Heading--pageTitle em, .app-launcher-container .spectrum-Heading2--quiet em {
  font-size: 28px;
  font-weight: 300;
  line-height: 1.3;
  font-style: italic;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading--pageTitle strong, .app-launcher-container .spectrum-Heading2--quiet strong {
  font-size: 28px;
  font-weight: 700;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading1--strong {
  font-size: 36px;
  font-weight: 900;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Heading1--strong em {
  font-size: 36px;
  font-weight: 900;
  line-height: 1.3;
  font-style: italic;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading1--strong strong {
  font-size: 36px;
  font-weight: 900;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading2--strong {
  font-size: 28px;
  font-weight: 900;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Heading2--strong em {
  font-size: 28px;
  font-weight: 900;
  line-height: 1.3;
  font-style: italic;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading2--strong strong {
  font-size: 28px;
  font-weight: 900;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading1--display {
  font-size: 45px;
  font-weight: 700;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Heading1--display em {
  font-size: 45px;
  font-weight: 700;
  line-height: 1.3;
  font-style: italic;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading1--display strong {
  font-size: 45px;
  font-weight: 900;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading2--display {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Heading2--display em {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.3;
  font-style: italic;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading2--display strong {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading1--display.spectrum-Heading1--strong {
  font-size: 45px;
  font-weight: 900;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Heading1--display.spectrum-Heading1--strong em {
  font-size: 45px;
  font-weight: 900;
  line-height: 1.3;
  font-style: italic;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading1--display.spectrum-Heading1--strong strong {
  font-size: 45px;
  font-weight: 900;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading2--display.spectrum-Heading2--strong {
  font-size: 40px;
  font-weight: 900;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Heading2--display.spectrum-Heading2--strong em {
  font-size: 40px;
  font-weight: 900;
  line-height: 1.3;
  font-style: italic;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading2--display.spectrum-Heading2--strong strong {
  font-size: 40px;
  font-weight: 900;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading1--display.spectrum-Heading1--quiet {
  font-size: 45px;
  font-weight: 300;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Heading1--display.spectrum-Heading1--quiet em {
  font-size: 45px;
  font-weight: 300;
  line-height: 1.3;
  font-style: italic;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading1--display.spectrum-Heading1--quiet strong {
  font-size: 45px;
  font-weight: 700;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading--display, .app-launcher-container .spectrum-Heading2--display.spectrum-Heading2--quiet {
  font-size: 40px;
  font-weight: 300;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Heading--display em, .app-launcher-container .spectrum-Heading2--display.spectrum-Heading2--quiet em {
  font-size: 40px;
  font-weight: 300;
  line-height: 1.3;
  font-style: italic;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Heading--display strong, .app-launcher-container .spectrum-Heading2--display.spectrum-Heading2--quiet strong {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: 0;
  text-transform: none; }

.app-launcher-container .spectrum-Article {
  font-family: Adobe Clean Serif,Times New Roman,serif; }

.app-launcher-container .spectrum-Article .spectrum-Body1 {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none;
  margin-bottom: 16px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Article .spectrum-Body1 em {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  font-style: italic;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Body1 strong {
  font-size: 20px;
  font-weight: 900;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Body--large, .app-launcher-container .spectrum-Article .spectrum-Body2 {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none;
  margin-bottom: 16px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Article .spectrum-Body--large em, .app-launcher-container .spectrum-Article .spectrum-Body2 em {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  font-style: italic;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Body--large strong, .app-launcher-container .spectrum-Article .spectrum-Body2 strong {
  font-size: 18px;
  font-weight: 900;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Body3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none;
  margin-bottom: 16px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Article .spectrum-Body3 em {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  font-style: italic;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Body3 strong {
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Body--secondary, .app-launcher-container .spectrum-Article .spectrum-Body4 {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none;
  margin-bottom: 16px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Article .spectrum-Body--secondary em, .app-launcher-container .spectrum-Article .spectrum-Body4 em {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  font-style: italic;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Body--secondary strong, .app-launcher-container .spectrum-Article .spectrum-Body4 strong {
  font-size: 14px;
  font-weight: 900;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Body--small, .app-launcher-container .spectrum-Article .spectrum-Body5 {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none;
  margin-bottom: 16px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Article .spectrum-Body--small em, .app-launcher-container .spectrum-Article .spectrum-Body5 em {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  font-style: italic;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Body--small strong, .app-launcher-container .spectrum-Article .spectrum-Body5 strong {
  font-size: 12px;
  font-weight: 900;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Heading1 {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Article .spectrum-Heading1 em {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.3;
  font-style: italic;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Heading1 strong {
  font-size: 36px;
  font-weight: 900;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Heading2 {
  font-size: 28px;
  font-weight: 700;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Article .spectrum-Heading2 em {
  font-size: 28px;
  font-weight: 700;
  line-height: 1.3;
  font-style: italic;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Heading2 strong {
  font-size: 28px;
  font-weight: 900;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Heading3 {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Article .spectrum-Heading3 em {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.3;
  font-style: italic;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Heading3 strong {
  font-size: 22px;
  font-weight: 900;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Heading--subtitle1, .app-launcher-container .spectrum-Article .spectrum-Heading4 {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Article .spectrum-Heading--subtitle1 em, .app-launcher-container .spectrum-Article .spectrum-Heading4 em {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.3;
  font-style: italic;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Heading--subtitle1 strong, .app-launcher-container .spectrum-Article .spectrum-Heading4 strong {
  font-size: 18px;
  font-weight: 900;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Heading5 {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Article .spectrum-Heading5 em {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.3;
  font-style: italic;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Heading5 strong {
  font-size: 16px;
  font-weight: 900;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Heading--subtitle2, .app-launcher-container .spectrum-Article .spectrum-Heading6 {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Article .spectrum-Heading--subtitle2 em, .app-launcher-container .spectrum-Article .spectrum-Heading6 em {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.3;
  font-style: italic;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Heading--subtitle2 strong, .app-launcher-container .spectrum-Article .spectrum-Heading6 strong {
  font-size: 14px;
  font-weight: 900;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Heading--subtitle3, .app-launcher-container .spectrum-Article .spectrum-Subheading {
  font-size: 11px;
  font-weight: 700;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .06em;
  text-transform: uppercase;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Article .spectrum-Heading--subtitle3 em, .app-launcher-container .spectrum-Article .spectrum-Subheading em {
  font-size: 11px;
  font-weight: 700;
  line-height: 1.3;
  font-style: italic;
  letter-spacing: .06em;
  text-transform: uppercase; }

.app-launcher-container .spectrum-Article .spectrum-Heading--subtitle3 strong, .app-launcher-container .spectrum-Article .spectrum-Subheading strong {
  font-size: 11px;
  font-weight: 900;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .06em;
  text-transform: uppercase; }

.app-launcher-container .spectrum-Article .spectrum-Detail {
  font-size: 11px;
  font-weight: 400;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .06em;
  text-transform: uppercase;
  margin-bottom: 16px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Article .spectrum-Detail em {
  font-size: 11px;
  font-weight: 400;
  line-height: 1.5;
  font-style: italic;
  letter-spacing: .06em;
  text-transform: uppercase; }

.app-launcher-container .spectrum-Article .spectrum-Detail strong {
  font-size: 11px;
  font-weight: 700;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .06em;
  text-transform: uppercase; }

.app-launcher-container .spectrum-Article .spectrum-Heading1--quiet {
  font-size: 36px;
  font-weight: 400;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Article .spectrum-Heading1--quiet em {
  font-size: 36px;
  font-weight: 400;
  line-height: 1.3;
  font-style: italic;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Heading1--quiet strong {
  font-size: 36px;
  font-weight: 900;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Heading--pageTitle, .app-launcher-container .spectrum-Article .spectrum-Heading2--quiet {
  font-size: 28px;
  font-weight: 400;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Article .spectrum-Heading--pageTitle em, .app-launcher-container .spectrum-Article .spectrum-Heading2--quiet em {
  font-size: 28px;
  font-weight: 400;
  line-height: 1.3;
  font-style: italic;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Heading--pageTitle strong, .app-launcher-container .spectrum-Article .spectrum-Heading2--quiet strong {
  font-size: 28px;
  font-weight: 900;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Heading1--display {
  font-size: 45px;
  font-weight: 700;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Article .spectrum-Heading1--display em {
  font-size: 45px;
  font-weight: 700;
  line-height: 1.3;
  font-style: italic;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Heading1--display strong {
  font-size: 45px;
  font-weight: 900;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Heading2--display {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Article .spectrum-Heading2--display em {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.3;
  font-style: italic;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Heading2--display strong {
  font-size: 40px;
  font-weight: 900;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Heading1--display.spectrum-Heading1--quiet {
  font-size: 45px;
  font-weight: 400;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Article .spectrum-Heading1--display.spectrum-Heading1--quiet em {
  font-size: 45px;
  font-weight: 400;
  line-height: 1.3;
  font-style: italic;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Heading1--display.spectrum-Heading1--quiet strong {
  font-size: 45px;
  font-weight: 700;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Heading--display, .app-launcher-container .spectrum-Article .spectrum-Heading2--display.spectrum-Heading2--quiet {
  font-size: 40px;
  font-weight: 400;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum-Article .spectrum-Heading--display em, .app-launcher-container .spectrum-Article .spectrum-Heading2--display.spectrum-Heading2--quiet em {
  font-size: 40px;
  font-weight: 400;
  line-height: 1.3;
  font-style: italic;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum-Article .spectrum-Heading--display strong, .app-launcher-container .spectrum-Article .spectrum-Heading2--display.spectrum-Heading2--quiet strong {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .0125em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Body1, .app-launcher-container .spectrum:lang(ko) .spectrum-Body1, .app-launcher-container .spectrum:lang(zh) .spectrum-Body1 {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.7;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none;
  margin-bottom: 16px;
  margin-top: 8px; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Body1 em, .app-launcher-container .spectrum:lang(ko) .spectrum-Body1 em, .app-launcher-container .spectrum:lang(zh) .spectrum-Body1 em {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.7;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Body1 strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Body1 strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Body1 strong {
  font-size: 20px;
  font-weight: 900;
  line-height: 1.7;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Body--large, .app-launcher-container .spectrum:lang(ja) .spectrum-Body2, .app-launcher-container .spectrum:lang(ko) .spectrum-Body--large, .app-launcher-container .spectrum:lang(ko) .spectrum-Body2, .app-launcher-container .spectrum:lang(zh) .spectrum-Body--large, .app-launcher-container .spectrum:lang(zh) .spectrum-Body2 {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.7;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none;
  margin-bottom: 16px;
  margin-top: 8px; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Body--large em, .app-launcher-container .spectrum:lang(ja) .spectrum-Body2 em, .app-launcher-container .spectrum:lang(ko) .spectrum-Body--large em, .app-launcher-container .spectrum:lang(ko) .spectrum-Body2 em, .app-launcher-container .spectrum:lang(zh) .spectrum-Body--large em, .app-launcher-container .spectrum:lang(zh) .spectrum-Body2 em {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.7;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Body--large strong, .app-launcher-container .spectrum:lang(ja) .spectrum-Body2 strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Body--large strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Body2 strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Body--large strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Body2 strong {
  font-size: 18px;
  font-weight: 900;
  line-height: 1.7;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Body3, .app-launcher-container .spectrum:lang(ko) .spectrum-Body3, .app-launcher-container .spectrum:lang(zh) .spectrum-Body3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none;
  margin-bottom: 16px;
  margin-top: 8px; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Body3 em, .app-launcher-container .spectrum:lang(ko) .spectrum-Body3 em, .app-launcher-container .spectrum:lang(zh) .spectrum-Body3 em {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.7;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Body3 strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Body3 strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Body3 strong {
  font-size: 16px;
  font-weight: 900;
  line-height: 1.7;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Body--secondary, .app-launcher-container .spectrum:lang(ja) .spectrum-Body4, .app-launcher-container .spectrum:lang(ko) .spectrum-Body--secondary, .app-launcher-container .spectrum:lang(ko) .spectrum-Body4, .app-launcher-container .spectrum:lang(zh) .spectrum-Body--secondary, .app-launcher-container .spectrum:lang(zh) .spectrum-Body4 {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.7;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none;
  margin-bottom: 16px;
  margin-top: 8px; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Body--secondary em, .app-launcher-container .spectrum:lang(ja) .spectrum-Body4 em, .app-launcher-container .spectrum:lang(ko) .spectrum-Body--secondary em, .app-launcher-container .spectrum:lang(ko) .spectrum-Body4 em, .app-launcher-container .spectrum:lang(zh) .spectrum-Body--secondary em, .app-launcher-container .spectrum:lang(zh) .spectrum-Body4 em {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.7;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Body--secondary strong, .app-launcher-container .spectrum:lang(ja) .spectrum-Body4 strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Body--secondary strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Body4 strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Body--secondary strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Body4 strong {
  font-size: 14px;
  font-weight: 900;
  line-height: 1.7;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Body--small, .app-launcher-container .spectrum:lang(ja) .spectrum-Body5, .app-launcher-container .spectrum:lang(ko) .spectrum-Body--small, .app-launcher-container .spectrum:lang(ko) .spectrum-Body5, .app-launcher-container .spectrum:lang(zh) .spectrum-Body--small, .app-launcher-container .spectrum:lang(zh) .spectrum-Body5 {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.7;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none;
  margin-bottom: 16px;
  margin-top: 8px; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Body--small em, .app-launcher-container .spectrum:lang(ja) .spectrum-Body5 em, .app-launcher-container .spectrum:lang(ko) .spectrum-Body--small em, .app-launcher-container .spectrum:lang(ko) .spectrum-Body5 em, .app-launcher-container .spectrum:lang(zh) .spectrum-Body--small em, .app-launcher-container .spectrum:lang(zh) .spectrum-Body5 em {
  font-size: 12px;
  font-weight: 700;
  line-height: 1.7;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Body--small strong, .app-launcher-container .spectrum:lang(ja) .spectrum-Body5 strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Body--small strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Body5 strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Body--small strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Body5 strong {
  font-size: 12px;
  font-weight: 900;
  line-height: 1.7;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading1, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading1, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading1 {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading1 em, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading1 em, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading1 em {
  font-size: 32px;
  font-weight: 800;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading1 strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading1 strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading1 strong {
  font-size: 32px;
  font-weight: 900;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading2, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading2, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading2 {
  font-size: 25px;
  font-weight: 700;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading2 em, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading2 em, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading2 em {
  font-size: 25px;
  font-weight: 800;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading2 strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading2 strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading2 strong {
  font-size: 25px;
  font-weight: 900;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading3, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading3, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading3 em, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading3 em, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading3 em {
  font-size: 20px;
  font-weight: 800;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading3 strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading3 strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading3 strong {
  font-size: 20px;
  font-weight: 900;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading--subtitle1, .app-launcher-container .spectrum:lang(ja) .spectrum-Heading4, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading--subtitle1, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading4, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading--subtitle1, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading4 {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading--subtitle1 em, .app-launcher-container .spectrum:lang(ja) .spectrum-Heading4 em, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading--subtitle1 em, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading4 em, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading--subtitle1 em, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading4 em {
  font-size: 18px;
  font-weight: 800;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading--subtitle1 strong, .app-launcher-container .spectrum:lang(ja) .spectrum-Heading4 strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading--subtitle1 strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading4 strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading--subtitle1 strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading4 strong {
  font-size: 18px;
  font-weight: 900;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading5, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading5, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading5 {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading5 em, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading5 em, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading5 em {
  font-size: 16px;
  font-weight: 800;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading5 strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading5 strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading5 strong {
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading--subtitle2, .app-launcher-container .spectrum:lang(ja) .spectrum-Heading6, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading--subtitle2, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading6, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading--subtitle2, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading6 {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading--subtitle2 em, .app-launcher-container .spectrum:lang(ja) .spectrum-Heading6 em, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading--subtitle2 em, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading6 em, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading--subtitle2 em, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading6 em {
  font-size: 14px;
  font-weight: 800;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading--subtitle2 strong, .app-launcher-container .spectrum:lang(ja) .spectrum-Heading6 strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading--subtitle2 strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading6 strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading--subtitle2 strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading6 strong {
  font-size: 14px;
  font-weight: 900;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading--subtitle3, .app-launcher-container .spectrum:lang(ja) .spectrum-Subheading, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading--subtitle3, .app-launcher-container .spectrum:lang(ko) .spectrum-Subheading, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading--subtitle3, .app-launcher-container .spectrum:lang(zh) .spectrum-Subheading {
  font-size: 11px;
  font-weight: 700;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .06em;
  text-transform: uppercase;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading--subtitle3 em, .app-launcher-container .spectrum:lang(ja) .spectrum-Subheading em, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading--subtitle3 em, .app-launcher-container .spectrum:lang(ko) .spectrum-Subheading em, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading--subtitle3 em, .app-launcher-container .spectrum:lang(zh) .spectrum-Subheading em {
  font-size: 11px;
  font-weight: 800;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .06em;
  text-transform: uppercase; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading--subtitle3 strong, .app-launcher-container .spectrum:lang(ja) .spectrum-Subheading strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading--subtitle3 strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Subheading strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading--subtitle3 strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Subheading strong {
  font-size: 11px;
  font-weight: 900;
  line-height: 1.3;
  font-style: normal;
  letter-spacing: .06em;
  text-transform: uppercase; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Detail, .app-launcher-container .spectrum:lang(ko) .spectrum-Detail, .app-launcher-container .spectrum:lang(zh) .spectrum-Detail {
  font-size: 11px;
  font-weight: 400;
  line-height: 1.7;
  font-style: normal;
  letter-spacing: .06em;
  text-transform: uppercase;
  margin-bottom: 16px;
  margin-top: 8px; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Detail em, .app-launcher-container .spectrum:lang(ko) .spectrum-Detail em, .app-launcher-container .spectrum:lang(zh) .spectrum-Detail em {
  font-size: 11px;
  font-weight: 400;
  line-height: 1.7;
  font-style: normal;
  letter-spacing: .06em;
  text-transform: uppercase; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Detail strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Detail strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Detail strong {
  font-size: 11px;
  font-weight: 900;
  line-height: 1.7;
  font-style: normal;
  letter-spacing: .06em;
  text-transform: uppercase; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading1--quiet, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading1--quiet, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading1--quiet {
  font-size: 32px;
  font-weight: 300;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading1--quiet em, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading1--quiet em, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading1--quiet em {
  font-size: 32px;
  font-weight: 400;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading1--quiet strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading1--quiet strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading1--quiet strong {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading--pageTitle, .app-launcher-container .spectrum:lang(ja) .spectrum-Heading2--quiet, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading--pageTitle, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading2--quiet, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading--pageTitle, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading2--quiet {
  font-size: 25px;
  font-weight: 300;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading--pageTitle em, .app-launcher-container .spectrum:lang(ja) .spectrum-Heading2--quiet em, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading--pageTitle em, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading2--quiet em, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading--pageTitle em, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading2--quiet em {
  font-size: 25px;
  font-weight: 400;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading--pageTitle strong, .app-launcher-container .spectrum:lang(ja) .spectrum-Heading2--quiet strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading--pageTitle strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading2--quiet strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading--pageTitle strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading2--quiet strong {
  font-size: 25px;
  font-weight: 700;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading1--strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading1--strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading1--strong {
  font-size: 32px;
  font-weight: 900;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading1--strong em, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading1--strong em, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading1--strong em {
  font-size: 32px;
  font-weight: 900;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading1--strong strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading1--strong strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading1--strong strong {
  font-size: 32px;
  font-weight: 900;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading2--strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading2--strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading2--strong {
  font-size: 25px;
  font-weight: 900;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading2--strong em, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading2--strong em, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading2--strong em {
  font-size: 25px;
  font-weight: 900;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading2--strong strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading2--strong strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading2--strong strong {
  font-size: 25px;
  font-weight: 900;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading1--display, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading1--display, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading1--display {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading1--display em, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading1--display em, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading1--display em {
  font-size: 40px;
  font-weight: 800;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading1--display strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading1--display strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading1--display strong {
  font-size: 40px;
  font-weight: 900;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading2--display, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading2--display, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading2--display {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading2--display em, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading2--display em, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading2--display em {
  font-size: 36px;
  font-weight: 800;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading2--display strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading2--display strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading2--display strong {
  font-size: 36px;
  font-weight: 900;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading1--display.spectrum-Heading1--strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading1--display.spectrum-Heading1--strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading1--display.spectrum-Heading1--strong {
  font-size: 40px;
  font-weight: 900;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading1--display.spectrum-Heading1--strong em, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading1--display.spectrum-Heading1--strong em, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading1--display.spectrum-Heading1--strong em {
  font-size: 40px;
  font-weight: 900;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading1--display.spectrum-Heading1--strong strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading1--display.spectrum-Heading1--strong strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading1--display.spectrum-Heading1--strong strong {
  font-size: 40px;
  font-weight: 900;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading2--display.spectrum-Heading2--strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading2--display.spectrum-Heading2--strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading2--display.spectrum-Heading2--strong {
  font-size: 36px;
  font-weight: 900;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading2--display.spectrum-Heading2--strong em, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading2--display.spectrum-Heading2--strong em, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading2--display.spectrum-Heading2--strong em {
  font-size: 36px;
  font-weight: 900;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading2--display.spectrum-Heading2--strong strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading2--display.spectrum-Heading2--strong strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading2--display.spectrum-Heading2--strong strong {
  font-size: 36px;
  font-weight: 900;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading1--display.spectrum-Heading1--quiet, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading1--display.spectrum-Heading1--quiet, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading1--display.spectrum-Heading1--quiet {
  font-size: 40px;
  font-weight: 300;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading1--display.spectrum-Heading1--quiet em, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading1--display.spectrum-Heading1--quiet em, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading1--display.spectrum-Heading1--quiet em {
  font-size: 40px;
  font-weight: 400;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading1--display.spectrum-Heading1--quiet strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading1--display.spectrum-Heading1--quiet strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading1--display.spectrum-Heading1--quiet strong {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading--display, .app-launcher-container .spectrum:lang(ja) .spectrum-Heading2--display.spectrum-Heading2--quiet, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading--display, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading2--display.spectrum-Heading2--quiet, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading--display, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading2--display.spectrum-Heading2--quiet {
  font-size: 36px;
  font-weight: 300;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none;
  margin-bottom: 8px;
  margin-top: 8px; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading--display em, .app-launcher-container .spectrum:lang(ja) .spectrum-Heading2--display.spectrum-Heading2--quiet em, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading--display em, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading2--display.spectrum-Heading2--quiet em, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading--display em, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading2--display.spectrum-Heading2--quiet em {
  font-size: 36px;
  font-weight: 400;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum:lang(ja) .spectrum-Heading--display strong, .app-launcher-container .spectrum:lang(ja) .spectrum-Heading2--display.spectrum-Heading2--quiet strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading--display strong, .app-launcher-container .spectrum:lang(ko) .spectrum-Heading2--display.spectrum-Heading2--quiet strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading--display strong, .app-launcher-container .spectrum:lang(zh) .spectrum-Heading2--display.spectrum-Heading2--quiet strong {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: .05em;
  text-transform: none; }

.app-launcher-container .spectrum-Code1 {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: 0;
  margin-bottom: 16px;
  margin-top: 8px;
  font-family: Source Code Pro,Monaco,monospace; }

.app-launcher-container .spectrum-Code1 em {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  font-style: italic;
  letter-spacing: 0; }

.app-launcher-container .spectrum-Code1 strong {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: 0; }

.app-launcher-container .spectrum-Code2 {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: 0;
  margin-bottom: 16px;
  margin-top: 8px;
  font-family: Source Code Pro,Monaco,monospace; }

.app-launcher-container .spectrum-Code2 em {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  font-style: italic;
  letter-spacing: 0; }

.app-launcher-container .spectrum-Code2 strong {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: 0; }

.app-launcher-container .spectrum-Code3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: 0;
  margin-bottom: 16px;
  margin-top: 8px;
  font-family: Source Code Pro,Monaco,monospace; }

.app-launcher-container .spectrum-Code3 em {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  font-style: italic;
  letter-spacing: 0; }

.app-launcher-container .spectrum-Code3 strong {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: 0; }

.app-launcher-container .spectrum-Code4 {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: 0;
  margin-bottom: 16px;
  margin-top: 8px;
  font-family: Source Code Pro,Monaco,monospace; }

.app-launcher-container .spectrum-Code4 em {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  font-style: italic;
  letter-spacing: 0; }

.app-launcher-container .spectrum-Code4 strong {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: 0; }

.app-launcher-container .spectrum-Code5 {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: 0;
  margin-bottom: 16px;
  margin-top: 8px;
  font-family: Source Code Pro,Monaco,monospace; }

.app-launcher-container .spectrum-Code5 em {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  font-style: italic;
  letter-spacing: 0; }

.app-launcher-container .spectrum-Code5 strong {
  font-size: 12px;
  font-weight: 700;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: 0; }

.app-launcher-container .spectrum, .app-launcher-container .spectrum-Body {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  font-style: normal; }

.app-launcher-container .spectrum-Body--italic {
  font-style: italic; }

.app-launcher-container .spectrum--light .spectrum-Body1 {
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum-Body2 {
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum-Body3 {
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum-Body4 {
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum-Body5 {
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum-Heading1 {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Heading2 {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Heading3 {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Heading4 {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Heading5 {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Heading6 {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Subheading {
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum-Detail {
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum-Heading1--quiet {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Heading2--quiet {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Heading1--strong {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Heading2--strong {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Heading1--display {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Heading2--display {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Heading1--display.spectrum-Heading1--strong {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Heading2--display.spectrum-Heading2--strong {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Heading1--display.spectrum-Heading1--quiet {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Heading2--display.spectrum-Heading2--quiet {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Article .spectrum-Body1 {
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum-Article .spectrum-Body2 {
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum-Article .spectrum-Body3 {
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum-Article .spectrum-Body4 {
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum-Article .spectrum-Body5 {
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum-Article .spectrum-Heading1 {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Article .spectrum-Heading2 {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Article .spectrum-Heading3 {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Article .spectrum-Heading4 {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Article .spectrum-Heading5 {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Article .spectrum-Heading6 {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Article .spectrum-Subheading {
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum-Article .spectrum-Detail {
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum-Article .spectrum-Heading1--quiet {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Article .spectrum-Heading2--quiet {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Article .spectrum-Heading1--display {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Article .spectrum-Heading2--display {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Article .spectrum-Heading1--display.spectrum-Heading1--quiet {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Article .spectrum-Heading2--display.spectrum-Heading2--quiet {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum:lang(ja) .spectrum-Body1, .app-launcher-container .spectrum--light .spectrum:lang(ko) .spectrum-Body1, .app-launcher-container .spectrum--light .spectrum:lang(zh) .spectrum-Body1 {
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum:lang(ja) .spectrum-Body2, .app-launcher-container .spectrum--light .spectrum:lang(ko) .spectrum-Body2, .app-launcher-container .spectrum--light .spectrum:lang(zh) .spectrum-Body2 {
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum:lang(ja) .spectrum-Body3, .app-launcher-container .spectrum--light .spectrum:lang(ko) .spectrum-Body3, .app-launcher-container .spectrum--light .spectrum:lang(zh) .spectrum-Body3 {
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum:lang(ja) .spectrum-Body4, .app-launcher-container .spectrum--light .spectrum:lang(ko) .spectrum-Body4, .app-launcher-container .spectrum--light .spectrum:lang(zh) .spectrum-Body4 {
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum:lang(ja) .spectrum-Body5, .app-launcher-container .spectrum--light .spectrum:lang(ko) .spectrum-Body5, .app-launcher-container .spectrum--light .spectrum:lang(zh) .spectrum-Body5 {
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum:lang(ja) .spectrum-Heading1, .app-launcher-container .spectrum--light .spectrum:lang(ko) .spectrum-Heading1, .app-launcher-container .spectrum--light .spectrum:lang(zh) .spectrum-Heading1 {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum:lang(ja) .spectrum-Heading2, .app-launcher-container .spectrum--light .spectrum:lang(ko) .spectrum-Heading2, .app-launcher-container .spectrum--light .spectrum:lang(zh) .spectrum-Heading2 {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum:lang(ja) .spectrum-Heading3, .app-launcher-container .spectrum--light .spectrum:lang(ko) .spectrum-Heading3, .app-launcher-container .spectrum--light .spectrum:lang(zh) .spectrum-Heading3 {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum:lang(ja) .spectrum-Heading4, .app-launcher-container .spectrum--light .spectrum:lang(ko) .spectrum-Heading4, .app-launcher-container .spectrum--light .spectrum:lang(zh) .spectrum-Heading4 {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum:lang(ja) .spectrum-Heading5, .app-launcher-container .spectrum--light .spectrum:lang(ko) .spectrum-Heading5, .app-launcher-container .spectrum--light .spectrum:lang(zh) .spectrum-Heading5 {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum:lang(ja) .spectrum-Heading6, .app-launcher-container .spectrum--light .spectrum:lang(ko) .spectrum-Heading6, .app-launcher-container .spectrum--light .spectrum:lang(zh) .spectrum-Heading6 {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum:lang(ja) .spectrum-Subheading, .app-launcher-container .spectrum--light .spectrum:lang(ko) .spectrum-Subheading, .app-launcher-container .spectrum--light .spectrum:lang(zh) .spectrum-Subheading {
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum:lang(ja) .spectrum-Detail, .app-launcher-container .spectrum--light .spectrum:lang(ko) .spectrum-Detail, .app-launcher-container .spectrum--light .spectrum:lang(zh) .spectrum-Detail {
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum:lang(ja) .spectrum-Heading1--quiet, .app-launcher-container .spectrum--light .spectrum:lang(ko) .spectrum-Heading1--quiet, .app-launcher-container .spectrum--light .spectrum:lang(zh) .spectrum-Heading1--quiet {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum:lang(ja) .spectrum-Heading2--quiet, .app-launcher-container .spectrum--light .spectrum:lang(ko) .spectrum-Heading2--quiet, .app-launcher-container .spectrum--light .spectrum:lang(zh) .spectrum-Heading2--quiet {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum:lang(ja) .spectrum-Heading1--strong, .app-launcher-container .spectrum--light .spectrum:lang(ko) .spectrum-Heading1--strong, .app-launcher-container .spectrum--light .spectrum:lang(zh) .spectrum-Heading1--strong {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum:lang(ja) .spectrum-Heading2--strong, .app-launcher-container .spectrum--light .spectrum:lang(ko) .spectrum-Heading2--strong, .app-launcher-container .spectrum--light .spectrum:lang(zh) .spectrum-Heading2--strong {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum:lang(ja) .spectrum-Heading1--display, .app-launcher-container .spectrum--light .spectrum:lang(ko) .spectrum-Heading1--display, .app-launcher-container .spectrum--light .spectrum:lang(zh) .spectrum-Heading1--display {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum:lang(ja) .spectrum-Heading2--display, .app-launcher-container .spectrum--light .spectrum:lang(ko) .spectrum-Heading2--display, .app-launcher-container .spectrum--light .spectrum:lang(zh) .spectrum-Heading2--display {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum:lang(ja) .spectrum-Heading1--display.spectrum-Heading1--strong, .app-launcher-container .spectrum--light .spectrum:lang(ko) .spectrum-Heading1--display.spectrum-Heading1--strong, .app-launcher-container .spectrum--light .spectrum:lang(zh) .spectrum-Heading1--display.spectrum-Heading1--strong {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum:lang(ja) .spectrum-Heading2--display.spectrum-Heading2--strong, .app-launcher-container .spectrum--light .spectrum:lang(ko) .spectrum-Heading2--display.spectrum-Heading2--strong, .app-launcher-container .spectrum--light .spectrum:lang(zh) .spectrum-Heading2--display.spectrum-Heading2--strong {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum:lang(ja) .spectrum-Heading1--display.spectrum-Heading1--quiet, .app-launcher-container .spectrum--light .spectrum:lang(ko) .spectrum-Heading1--display.spectrum-Heading1--quiet, .app-launcher-container .spectrum--light .spectrum:lang(zh) .spectrum-Heading1--display.spectrum-Heading1--quiet {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum:lang(ja) .spectrum-Heading2--display.spectrum-Heading2--quiet, .app-launcher-container .spectrum--light .spectrum:lang(ko) .spectrum-Heading2--display.spectrum-Heading2--quiet, .app-launcher-container .spectrum--light .spectrum:lang(zh) .spectrum-Heading2--display.spectrum-Heading2--quiet {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Code1 {
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum-Code2 {
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum-Code3 {
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum-Code4 {
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum-Code5 {
  color: #4b4b4b; }

.app-launcher-container .spectrum--light, .app-launcher-container .spectrum--light .spectrum-Body {
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum-Body--large {
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum-Body--small {
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum-Body--secondary {
  color: #707070; }

.app-launcher-container .spectrum--light .spectrum-Heading--display {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Heading--pageTitle {
  color: #707070; }

.app-launcher-container .spectrum--light .spectrum-Heading--subtitle1 {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Heading--subtitle2 {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Heading--subtitle3 {
  color: #707070; }

.app-launcher-container .spectrum--dark .spectrum-Body1 {
  color: #cdcdcd; }

.app-launcher-container .spectrum--dark .spectrum-Body2 {
  color: #cdcdcd; }

.app-launcher-container .spectrum--dark .spectrum-Body3 {
  color: #cdcdcd; }

.app-launcher-container .spectrum--dark .spectrum-Body4 {
  color: #cdcdcd; }

.app-launcher-container .spectrum--dark .spectrum-Body5 {
  color: #cdcdcd; }

.app-launcher-container .spectrum--dark .spectrum-Heading1 {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Heading2 {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Heading3 {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Heading4 {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Heading5 {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Heading6 {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Subheading {
  color: #cdcdcd; }

.app-launcher-container .spectrum--dark .spectrum-Detail {
  color: #cdcdcd; }

.app-launcher-container .spectrum--dark .spectrum-Heading1--quiet {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Heading2--quiet {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Heading1--strong {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Heading2--strong {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Heading1--display {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Heading2--display {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Heading1--display.spectrum-Heading1--strong {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Heading2--display.spectrum-Heading2--strong {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Heading1--display.spectrum-Heading1--quiet {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Heading2--display.spectrum-Heading2--quiet {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Article .spectrum-Body1 {
  color: #cdcdcd; }

.app-launcher-container .spectrum--dark .spectrum-Article .spectrum-Body2 {
  color: #cdcdcd; }

.app-launcher-container .spectrum--dark .spectrum-Article .spectrum-Body3 {
  color: #cdcdcd; }

.app-launcher-container .spectrum--dark .spectrum-Article .spectrum-Body4 {
  color: #cdcdcd; }

.app-launcher-container .spectrum--dark .spectrum-Article .spectrum-Body5 {
  color: #cdcdcd; }

.app-launcher-container .spectrum--dark .spectrum-Article .spectrum-Heading1 {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Article .spectrum-Heading2 {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Article .spectrum-Heading3 {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Article .spectrum-Heading4 {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Article .spectrum-Heading5 {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Article .spectrum-Heading6 {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Article .spectrum-Subheading {
  color: #cdcdcd; }

.app-launcher-container .spectrum--dark .spectrum-Article .spectrum-Detail {
  color: #cdcdcd; }

.app-launcher-container .spectrum--dark .spectrum-Article .spectrum-Heading1--quiet {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Article .spectrum-Heading2--quiet {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Article .spectrum-Heading1--display {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Article .spectrum-Heading2--display {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Article .spectrum-Heading1--display.spectrum-Heading1--quiet {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Article .spectrum-Heading2--display.spectrum-Heading2--quiet {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum:lang(ja) .spectrum-Body1, .app-launcher-container .spectrum--dark .spectrum:lang(ko) .spectrum-Body1, .app-launcher-container .spectrum--dark .spectrum:lang(zh) .spectrum-Body1 {
  color: #cdcdcd; }

.app-launcher-container .spectrum--dark .spectrum:lang(ja) .spectrum-Body2, .app-launcher-container .spectrum--dark .spectrum:lang(ko) .spectrum-Body2, .app-launcher-container .spectrum--dark .spectrum:lang(zh) .spectrum-Body2 {
  color: #cdcdcd; }

.app-launcher-container .spectrum--dark .spectrum:lang(ja) .spectrum-Body3, .app-launcher-container .spectrum--dark .spectrum:lang(ko) .spectrum-Body3, .app-launcher-container .spectrum--dark .spectrum:lang(zh) .spectrum-Body3 {
  color: #cdcdcd; }

.app-launcher-container .spectrum--dark .spectrum:lang(ja) .spectrum-Body4, .app-launcher-container .spectrum--dark .spectrum:lang(ko) .spectrum-Body4, .app-launcher-container .spectrum--dark .spectrum:lang(zh) .spectrum-Body4 {
  color: #cdcdcd; }

.app-launcher-container .spectrum--dark .spectrum:lang(ja) .spectrum-Body5, .app-launcher-container .spectrum--dark .spectrum:lang(ko) .spectrum-Body5, .app-launcher-container .spectrum--dark .spectrum:lang(zh) .spectrum-Body5 {
  color: #cdcdcd; }

.app-launcher-container .spectrum--dark .spectrum:lang(ja) .spectrum-Heading1, .app-launcher-container .spectrum--dark .spectrum:lang(ko) .spectrum-Heading1, .app-launcher-container .spectrum--dark .spectrum:lang(zh) .spectrum-Heading1 {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum:lang(ja) .spectrum-Heading2, .app-launcher-container .spectrum--dark .spectrum:lang(ko) .spectrum-Heading2, .app-launcher-container .spectrum--dark .spectrum:lang(zh) .spectrum-Heading2 {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum:lang(ja) .spectrum-Heading3, .app-launcher-container .spectrum--dark .spectrum:lang(ko) .spectrum-Heading3, .app-launcher-container .spectrum--dark .spectrum:lang(zh) .spectrum-Heading3 {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum:lang(ja) .spectrum-Heading4, .app-launcher-container .spectrum--dark .spectrum:lang(ko) .spectrum-Heading4, .app-launcher-container .spectrum--dark .spectrum:lang(zh) .spectrum-Heading4 {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum:lang(ja) .spectrum-Heading5, .app-launcher-container .spectrum--dark .spectrum:lang(ko) .spectrum-Heading5, .app-launcher-container .spectrum--dark .spectrum:lang(zh) .spectrum-Heading5 {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum:lang(ja) .spectrum-Heading6, .app-launcher-container .spectrum--dark .spectrum:lang(ko) .spectrum-Heading6, .app-launcher-container .spectrum--dark .spectrum:lang(zh) .spectrum-Heading6 {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum:lang(ja) .spectrum-Subheading, .app-launcher-container .spectrum--dark .spectrum:lang(ko) .spectrum-Subheading, .app-launcher-container .spectrum--dark .spectrum:lang(zh) .spectrum-Subheading {
  color: #cdcdcd; }

.app-launcher-container .spectrum--dark .spectrum:lang(ja) .spectrum-Detail, .app-launcher-container .spectrum--dark .spectrum:lang(ko) .spectrum-Detail, .app-launcher-container .spectrum--dark .spectrum:lang(zh) .spectrum-Detail {
  color: #cdcdcd; }

.app-launcher-container .spectrum--dark .spectrum:lang(ja) .spectrum-Heading1--quiet, .app-launcher-container .spectrum--dark .spectrum:lang(ko) .spectrum-Heading1--quiet, .app-launcher-container .spectrum--dark .spectrum:lang(zh) .spectrum-Heading1--quiet {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum:lang(ja) .spectrum-Heading2--quiet, .app-launcher-container .spectrum--dark .spectrum:lang(ko) .spectrum-Heading2--quiet, .app-launcher-container .spectrum--dark .spectrum:lang(zh) .spectrum-Heading2--quiet {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum:lang(ja) .spectrum-Heading1--strong, .app-launcher-container .spectrum--dark .spectrum:lang(ko) .spectrum-Heading1--strong, .app-launcher-container .spectrum--dark .spectrum:lang(zh) .spectrum-Heading1--strong {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum:lang(ja) .spectrum-Heading2--strong, .app-launcher-container .spectrum--dark .spectrum:lang(ko) .spectrum-Heading2--strong, .app-launcher-container .spectrum--dark .spectrum:lang(zh) .spectrum-Heading2--strong {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum:lang(ja) .spectrum-Heading1--display, .app-launcher-container .spectrum--dark .spectrum:lang(ko) .spectrum-Heading1--display, .app-launcher-container .spectrum--dark .spectrum:lang(zh) .spectrum-Heading1--display {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum:lang(ja) .spectrum-Heading2--display, .app-launcher-container .spectrum--dark .spectrum:lang(ko) .spectrum-Heading2--display, .app-launcher-container .spectrum--dark .spectrum:lang(zh) .spectrum-Heading2--display {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum:lang(ja) .spectrum-Heading1--display.spectrum-Heading1--strong, .app-launcher-container .spectrum--dark .spectrum:lang(ko) .spectrum-Heading1--display.spectrum-Heading1--strong, .app-launcher-container .spectrum--dark .spectrum:lang(zh) .spectrum-Heading1--display.spectrum-Heading1--strong {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum:lang(ja) .spectrum-Heading2--display.spectrum-Heading2--strong, .app-launcher-container .spectrum--dark .spectrum:lang(ko) .spectrum-Heading2--display.spectrum-Heading2--strong, .app-launcher-container .spectrum--dark .spectrum:lang(zh) .spectrum-Heading2--display.spectrum-Heading2--strong {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum:lang(ja) .spectrum-Heading1--display.spectrum-Heading1--quiet, .app-launcher-container .spectrum--dark .spectrum:lang(ko) .spectrum-Heading1--display.spectrum-Heading1--quiet, .app-launcher-container .spectrum--dark .spectrum:lang(zh) .spectrum-Heading1--display.spectrum-Heading1--quiet {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum:lang(ja) .spectrum-Heading2--display.spectrum-Heading2--quiet, .app-launcher-container .spectrum--dark .spectrum:lang(ko) .spectrum-Heading2--display.spectrum-Heading2--quiet, .app-launcher-container .spectrum--dark .spectrum:lang(zh) .spectrum-Heading2--display.spectrum-Heading2--quiet {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Code1 {
  color: #cdcdcd; }

.app-launcher-container .spectrum--dark .spectrum-Code2 {
  color: #cdcdcd; }

.app-launcher-container .spectrum--dark .spectrum-Code3 {
  color: #cdcdcd; }

.app-launcher-container .spectrum--dark .spectrum-Code4 {
  color: #cdcdcd; }

.app-launcher-container .spectrum--dark .spectrum-Code5 {
  color: #cdcdcd; }

.app-launcher-container .spectrum--dark, .app-launcher-container .spectrum--dark .spectrum-Body {
  color: #cdcdcd; }

.app-launcher-container .spectrum--dark .spectrum-Body--large {
  color: #cdcdcd; }

.app-launcher-container .spectrum--dark .spectrum-Body--small {
  color: #cdcdcd; }

.app-launcher-container .spectrum--dark .spectrum-Body--secondary {
  color: #999; }

.app-launcher-container .spectrum--dark .spectrum-Heading--display {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Heading--pageTitle {
  color: #999; }

.app-launcher-container .spectrum--dark .spectrum-Heading--subtitle1 {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Heading--subtitle2 {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Heading--subtitle3 {
  color: #999; }

.app-launcher-container .spectrum-Icon, .app-launcher-container .spectrum-UIIcon {
  display: inline-block;
  color: inherit;
  fill: currentColor;
  pointer-events: none; }

.app-launcher-container .spectrum-Icon:not(:root), .app-launcher-container .spectrum-UIIcon:not(:root) {
  overflow: hidden; }

.app-launcher-container .spectrum-Icon--sizeXXS, .app-launcher-container .spectrum-Icon--sizeXXS img, .app-launcher-container .spectrum-Icon--sizeXXS svg {
  height: 9px;
  width: 9px; }

.app-launcher-container .spectrum-Icon--sizeXS, .app-launcher-container .spectrum-Icon--sizeXS img, .app-launcher-container .spectrum-Icon--sizeXS svg {
  height: 12px;
  width: 12px; }

.app-launcher-container .spectrum-Icon--sizeS, .app-launcher-container .spectrum-Icon--sizeS img, .app-launcher-container .spectrum-Icon--sizeS svg {
  height: 18px;
  width: 18px; }

.app-launcher-container .spectrum-Icon--sizeM, .app-launcher-container .spectrum-Icon--sizeM img, .app-launcher-container .spectrum-Icon--sizeM svg {
  height: 24px;
  width: 24px; }

.app-launcher-container .spectrum-Icon--sizeL, .app-launcher-container .spectrum-Icon--sizeL img, .app-launcher-container .spectrum-Icon--sizeL svg {
  height: 36px;
  width: 36px; }

.app-launcher-container .spectrum-Icon--sizeXL, .app-launcher-container .spectrum-Icon--sizeXL img, .app-launcher-container .spectrum-Icon--sizeXL svg {
  height: 48px;
  width: 48px; }

.app-launcher-container .spectrum-Icon--sizeXXL, .app-launcher-container .spectrum-Icon--sizeXXL img, .app-launcher-container .spectrum-Icon--sizeXXL svg {
  height: 72px;
  width: 72px; }

.app-launcher-container .spectrum--medium .spectrum-UIIcon--large {
  display: none; }

.app-launcher-container .spectrum--medium .spectrum-UIIcon--medium {
  display: inline; }

.app-launcher-container .spectrum--large .spectrum-UIIcon--medium {
  display: none; }

.app-launcher-container .spectrum--large .spectrum-UIIcon--large {
  display: inline; }

.app-launcher-container .spectrum--large {
  --ui-icon-large-display:block;
  --ui-icon-medium-display:none; }

.app-launcher-container .spectrum--medium {
  --ui-icon-medium-display:block;
  --ui-icon-large-display:none; }

.app-launcher-container .spectrum-UIIcon--large {
  display: var(--ui-icon-large-display); }

.app-launcher-container .spectrum-UIIcon--medium {
  display: var(--ui-icon-medium-display); }

.app-launcher-container .spectrum-UIIcon-AlertMedium {
  width: 18px;
  height: 18px; }

.app-launcher-container .spectrum-UIIcon-AlertSmall {
  width: 14px;
  height: 14px; }

.app-launcher-container .spectrum-UIIcon-ArrowDownSmall {
  width: 8px;
  height: 10px; }

.app-launcher-container .spectrum-UIIcon-ArrowLeftMedium {
  width: 14px;
  height: 10px; }

.app-launcher-container .spectrum-UIIcon-Asterisk {
  width: 8px;
  height: 8px; }

.app-launcher-container .spectrum-UIIcon-CheckmarkMedium {
  width: 12px;
  height: 12px; }

.app-launcher-container .spectrum-UIIcon-CheckmarkSmall {
  width: 10px;
  height: 10px; }

.app-launcher-container .spectrum-UIIcon-ChevronDownMedium {
  width: 10px;
  height: 6px; }

.app-launcher-container .spectrum-UIIcon-ChevronDownSmall {
  width: 8px;
  height: 6px; }

.app-launcher-container .spectrum-UIIcon-ChevronLeftLarge {
  width: 12px;
  height: 16px; }

.app-launcher-container .spectrum-UIIcon-ChevronLeftMedium {
  width: 6px;
  height: 10px; }

.app-launcher-container .spectrum-UIIcon-ChevronRightLarge {
  width: 12px;
  height: 16px; }

.app-launcher-container .spectrum-UIIcon-ChevronRightMedium {
  width: 6px;
  height: 10px; }

.app-launcher-container .spectrum-UIIcon-ChevronRightSmall {
  width: 6px;
  height: 8px; }

.app-launcher-container .spectrum-UIIcon-ChevronUpSmall {
  width: 8px;
  height: 6px; }

.app-launcher-container .spectrum-UIIcon-CornerTriangle {
  width: 5px;
  height: 5px; }

.app-launcher-container .spectrum-UIIcon-CrossLarge {
  width: 12px;
  height: 12px; }

.app-launcher-container .spectrum-UIIcon-CrossMedium {
  width: 8px;
  height: 8px; }

.app-launcher-container .spectrum-UIIcon-CrossSmall {
  width: 8px;
  height: 8px; }

.app-launcher-container .spectrum-UIIcon-DashSmall {
  width: 10px;
  height: 10px; }

.app-launcher-container .spectrum-UIIcon-DoubleGripper {
  width: 16px;
  height: 4px; }

.app-launcher-container .spectrum-UIIcon-FolderBreadcrumb {
  width: 18px;
  height: 18px; }

.app-launcher-container .spectrum-UIIcon-HelpMedium {
  width: 18px;
  height: 18px; }

.app-launcher-container .spectrum-UIIcon-HelpSmall {
  width: 14px;
  height: 14px; }

.app-launcher-container .spectrum-UIIcon-InfoMedium {
  width: 18px;
  height: 18px; }

.app-launcher-container .spectrum-UIIcon-InfoSmall {
  width: 14px;
  height: 14px; }

.app-launcher-container .spectrum-UIIcon-Magnifier {
  width: 16px;
  height: 16px; }

.app-launcher-container .spectrum-UIIcon-SkipLeft {
  width: 9px;
  height: 10px; }

.app-launcher-container .spectrum-UIIcon-SkipRight {
  width: 9px;
  height: 10px; }

.app-launcher-container .spectrum-UIIcon-Star {
  width: 18px;
  height: 18px; }

.app-launcher-container .spectrum-UIIcon-StarOutline {
  width: 18px;
  height: 18px; }

.app-launcher-container .spectrum-UIIcon-SuccessMedium {
  width: 18px;
  height: 18px; }

.app-launcher-container .spectrum-UIIcon-SuccessSmall {
  width: 14px;
  height: 14px; }

.app-launcher-container .spectrum-UIIcon-TripleGripper {
  width: 10px;
  height: 7px; }

.app-launcher-container .spectrum-Underlay {
  visibility: hidden;
  opacity: 0;
  transition: transform 130ms ease-in-out,opacity 130ms ease-in-out,visibility 0s linear 130ms;
  pointer-events: none; }

.app-launcher-container .spectrum-Underlay.is-open {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
  pointer-events: auto; }

.app-launcher-container .spectrum-Underlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
  transition: opacity 190ms cubic-bezier(0.5, 0, 1, 1) 160ms, visibility 0s linear 350ms; }

.app-launcher-container .spectrum-Underlay.is-open {
  transition: opacity 0.3s cubic-bezier(0, 0, 0.4, 1) 0s; }

.app-launcher-container .spectrum--light .spectrum-Underlay {
  background: rgba(0, 0, 0, 0.4); }

.app-launcher-container .spectrum--dark .spectrum-Underlay {
  background: rgba(0, 0, 0, 0.5); }

.app-launcher-container .spectrum-Button--block {
  display: block; }

.app-launcher-container .spectrum-ActionButton, .app-launcher-container .spectrum-Button, .app-launcher-container .spectrum-ClearButton, .app-launcher-container .spectrum-FieldButton, .app-launcher-container .spectrum-LogicButton, .app-launcher-container .spectrum-Tool {
  display: -ms-inline-flexbox;
  display: inline-flex;
  box-sizing: border-box;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: visible;
  margin: 0;
  border-style: solid;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: button;
  vertical-align: top;
  transition: background 130ms ease-out,border-color 130ms ease-out,color 130ms ease-out,box-shadow 130ms ease-out;
  text-decoration: none;
  font-family: adobe-clean-ux,adobe-clean,'Source Sans Pro',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,sans-serif;
  line-height: 1.3;
  cursor: pointer; }

.app-launcher-container .spectrum-ActionButton:focus, .app-launcher-container .spectrum-Button:focus, .app-launcher-container .spectrum-ClearButton:focus, .app-launcher-container .spectrum-FieldButton:focus, .app-launcher-container .spectrum-LogicButton:focus, .app-launcher-container .spectrum-Tool:focus {
  outline: 0; }

.app-launcher-container .spectrum-ActionButton::-moz-focus-inner, .app-launcher-container .spectrum-Button::-moz-focus-inner, .app-launcher-container .spectrum-ClearButton::-moz-focus-inner, .app-launcher-container .spectrum-FieldButton::-moz-focus-inner, .app-launcher-container .spectrum-LogicButton::-moz-focus-inner, .app-launcher-container .spectrum-Tool::-moz-focus-inner {
  border: 0;
  border-style: none;
  padding: 0;
  margin-top: -2PX;
  margin-bottom: -2PX; }

.app-launcher-container .spectrum-ActionButton:disabled, .app-launcher-container .spectrum-Button:disabled, .app-launcher-container .spectrum-ClearButton:disabled, .app-launcher-container .spectrum-FieldButton:disabled, .app-launcher-container .spectrum-LogicButton:disabled, .app-launcher-container .spectrum-Tool:disabled {
  cursor: default; }

.app-launcher-container .spectrum-ActionButton .spectrum-Icon, .app-launcher-container .spectrum-Button .spectrum-Icon, .app-launcher-container .spectrum-ClearButton .spectrum-Icon, .app-launcher-container .spectrum-FieldButton .spectrum-Icon, .app-launcher-container .spectrum-LogicButton .spectrum-Icon, .app-launcher-container .spectrum-Tool .spectrum-Icon {
  max-height: 100%;
  -ms-flex-negative: 0;
  flex-shrink: 0; }

.app-launcher-container .spectrum-Button {
  border-width: 2px;
  border-style: solid;
  border-radius: 16px;
  min-height: 32px;
  height: auto;
  min-width: 72px;
  padding: 4px 14px;
  padding-bottom: 4.5px;
  padding-top: 3.5px;
  font-size: 14px;
  font-weight: 700; }

.app-launcher-container .spectrum-Button:active, .app-launcher-container .spectrum-Button:hover {
  box-shadow: none; }

.app-launcher-container .spectrum-Button .spectrum-Icon + .spectrum-Button-label {
  margin-left: 8px; }

.app-launcher-container .spectrum-Button .spectrum-Button-label + .spectrum-Icon {
  margin-left: 4px; }

.app-launcher-container a.spectrum-ActionButton, .app-launcher-container a.spectrum-Button {
  -webkit-appearance: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.app-launcher-container .spectrum-ActionButton, .app-launcher-container .spectrum-Tool {
  position: relative;
  height: 32px;
  min-width: 32px;
  padding: 0 6px;
  border-width: 1px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400; }

.app-launcher-container .spectrum-ActionButton .spectrum-Icon + .spectrum-ActionButton-label, .app-launcher-container .spectrum-Tool .spectrum-Icon + .spectrum-ActionButton-label {
  padding-left: 7px;
  padding-right: 5px; }

.app-launcher-container .spectrum-ActionButton .spectrum-Icon--sizeS:only-child, .app-launcher-container .spectrum-Tool .spectrum-Icon--sizeS:only-child {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 9px); }

.app-launcher-container .spectrum-ActionButton .spectrum-ActionButton-label:only-child, .app-launcher-container .spectrum-Tool .spectrum-ActionButton-label:only-child {
  padding: 0 5px; }

.app-launcher-container .spectrum-ActionButton-hold {
  position: absolute;
  right: 3px;
  bottom: 3px; }

.app-launcher-container .spectrum-ActionButton-label, .app-launcher-container .spectrum-Button-label {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  justify-self: center;
  text-align: center;
  width: 100%; }

.app-launcher-container .spectrum-ActionButton-label:empty, .app-launcher-container .spectrum-Button-label:empty {
  display: none; }

.app-launcher-container .spectrum-ActionButton-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.app-launcher-container .spectrum-ActionButton--quiet, .app-launcher-container .spectrum-Tool {
  border-width: 1px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400; }

.app-launcher-container .spectrum-LogicButton {
  height: 24px;
  padding: 8px;
  border-width: 2px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  line-height: 0; }

.app-launcher-container .spectrum-FieldButton {
  height: 32px;
  padding: 0 12px;
  font-family: inherit;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  -webkit-font-smoothing: initial;
  cursor: pointer;
  outline: 0; }

.app-launcher-container .spectrum-FieldButton {
  margin: 0;
  padding: 0 12px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  transition: background-color 130ms,box-shadow 130ms,border-color 130ms; }

.app-launcher-container .spectrum-FieldButton.is-disabled, .app-launcher-container .spectrum-FieldButton:disabled {
  border-width: 0;
  cursor: default; }

.app-launcher-container .spectrum-FieldButton.is-open {
  border-width: 1px; }

.app-launcher-container .spectrum-FieldButton--quiet {
  margin: 0;
  padding: 0;
  border-width: 0;
  border-radius: 0; }

.app-launcher-container .spectrum-ClearButton {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  padding: 0;
  margin: 0;
  border: none; }

.app-launcher-container .spectrum-ClearButton > .spectrum-Icon {
  margin: 0 auto; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .app-launcher-container .spectrum-ClearButton > .spectrum-Icon {
    margin: 0; } }

.app-launcher-container .spectrum-ClearButton--small {
  width: 24px;
  height: 24px; }

.app-launcher-container .spectrum-Tool {
  position: relative;
  -ms-flex-pack: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0; }

.app-launcher-container .spectrum-Tool-hold {
  position: absolute;
  right: 3px;
  bottom: 3px; }

.app-launcher-container .spectrum-Button + .spectrum-Button {
  margin-left: 16px; }

.app-launcher-container .spectrum-ActionButton + .spectrum-ActionButton, .app-launcher-container .spectrum-Tool + .spectrum-Tool {
  margin-left: 8px; }

.app-launcher-container .spectrum-Tool + .spectrum-Tool {
  margin-left: 8px; }

.app-launcher-container .spectrum--light .spectrum-Button.focus-ring {
  box-shadow: 0 0 0 1px #1473e6; }

.app-launcher-container .spectrum--light .spectrum-Button:active {
  box-shadow: none; }

.app-launcher-container .spectrum--light .spectrum-ClearButton {
  background-color: transparent;
  color: #6e6e6e; }

.app-launcher-container .spectrum--light .spectrum-ClearButton:hover {
  background-color: transparent;
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-ClearButton:active {
  background-color: transparent;
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-ClearButton.focus-ring {
  background-color: transparent;
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-ClearButton.is-disabled, .app-launcher-container .spectrum--light .spectrum-ClearButton:disabled {
  background-color: transparent;
  color: #cacaca; }

.app-launcher-container .spectrum--light .spectrum-Button--cta {
  background-color: #1473e6;
  border-color: #1473e6;
  color: #fff; }

.app-launcher-container .spectrum--light .spectrum-Button--cta:hover {
  background-color: #0d66d0;
  border-color: #0d66d0;
  color: #fff; }

.app-launcher-container .spectrum--light .spectrum-Button--cta.focus-ring {
  background-color: #1473e6;
  border-color: #1473e6;
  color: #fff; }

.app-launcher-container .spectrum--light .spectrum-Button--cta:active {
  background-color: #0d66d0;
  border-color: #0d66d0;
  color: #fff; }

.app-launcher-container .spectrum--light .spectrum-Button--cta.is-disabled, .app-launcher-container .spectrum--light .spectrum-Button--cta:disabled {
  background-color: #eaeaea;
  border-color: #eaeaea;
  color: #b3b3b3; }

.app-launcher-container .spectrum--light .spectrum-Button--primary {
  background-color: transparent;
  border-color: #4b4b4b;
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum-Button--primary:hover {
  background-color: #4b4b4b;
  border-color: #4b4b4b;
  color: #fff; }

.app-launcher-container .spectrum--light .spectrum-Button--primary.focus-ring {
  background-color: #1473e6;
  border-color: #1473e6;
  color: #fff; }

.app-launcher-container .spectrum--light .spectrum-Button--primary:active {
  background-color: #2c2c2c;
  border-color: #2c2c2c;
  color: #fff; }

.app-launcher-container .spectrum--light .spectrum-Button--primary.is-disabled, .app-launcher-container .spectrum--light .spectrum-Button--primary:disabled {
  background-color: #eaeaea;
  border-color: #eaeaea;
  color: #b3b3b3; }

.app-launcher-container .spectrum--light .spectrum-Button--secondary {
  background-color: transparent;
  border-color: #6e6e6e;
  color: #6e6e6e; }

.app-launcher-container .spectrum--light .spectrum-Button--secondary:hover {
  background-color: #6e6e6e;
  border-color: #6e6e6e;
  color: #fff; }

.app-launcher-container .spectrum--light .spectrum-Button--secondary.focus-ring {
  background-color: #1473e6;
  border-color: #1473e6;
  color: #fff; }

.app-launcher-container .spectrum--light .spectrum-Button--secondary:active {
  background-color: #4b4b4b;
  border-color: #4b4b4b;
  color: #fff; }

.app-launcher-container .spectrum--light .spectrum-Button--secondary.is-disabled, .app-launcher-container .spectrum--light .spectrum-Button--secondary:disabled {
  background-color: #eaeaea;
  border-color: #eaeaea;
  color: #b3b3b3; }

.app-launcher-container .spectrum--light .spectrum-Button--warning {
  background-color: transparent;
  border-color: #c9252d;
  color: #c9252d; }

.app-launcher-container .spectrum--light .spectrum-Button--warning:hover {
  background-color: #c9252d;
  border-color: #c9252d;
  color: #fff; }

.app-launcher-container .spectrum--light .spectrum-Button--warning.focus-ring {
  background-color: #1473e6;
  border-color: #1473e6;
  color: #fff; }

.app-launcher-container .spectrum--light .spectrum-Button--warning:active {
  background-color: #bb121a;
  border-color: #bb121a;
  color: #fff; }

.app-launcher-container .spectrum--light .spectrum-Button--warning.is-disabled, .app-launcher-container .spectrum--light .spectrum-Button--warning:disabled {
  background-color: #eaeaea;
  border-color: #eaeaea;
  color: #b3b3b3; }

.app-launcher-container .spectrum--light .spectrum-Button--overBackground {
  background-color: transparent;
  border-color: #fff;
  color: #fff; }

.app-launcher-container .spectrum--light .spectrum-Button--overBackground:hover {
  background-color: #fff;
  border-color: #fff;
  color: inherit; }

.app-launcher-container .spectrum--light .spectrum-Button--overBackground.focus-ring {
  background-color: #fff;
  border-color: #fff;
  color: inherit;
  box-shadow: 0 0 0 1px #fff; }

.app-launcher-container .spectrum--light .spectrum-Button--overBackground:active {
  background-color: #fff;
  border-color: #fff;
  color: inherit;
  box-shadow: none; }

.app-launcher-container .spectrum--light .spectrum-Button--overBackground.is-disabled, .app-launcher-container .spectrum--light .spectrum-Button--overBackground:disabled {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: transparent;
  color: rgba(255, 255, 255, 0.35); }

.app-launcher-container .spectrum--light .spectrum-Button--overBackground.spectrum-Button--quiet, .app-launcher-container .spectrum--light .spectrum-ClearButton--overBackground {
  background-color: transparent;
  border-color: transparent;
  color: #fff; }

.app-launcher-container .spectrum--light .spectrum-Button--overBackground.spectrum-Button--quiet:hover, .app-launcher-container .spectrum--light .spectrum-ClearButton--overBackground:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: transparent;
  color: #fff; }

.app-launcher-container .spectrum--light .spectrum-Button--overBackground.spectrum-Button--quiet.focus-ring, .app-launcher-container .spectrum--light .spectrum-ClearButton--overBackground.focus-ring {
  background-color: #fff;
  border-color: #fff;
  color: inherit;
  box-shadow: 0 0 0 1px #fff; }

.app-launcher-container .spectrum--light .spectrum-Button--overBackground.spectrum-Button--quiet:active, .app-launcher-container .spectrum--light .spectrum-ClearButton--overBackground:active {
  background-color: rgba(255, 255, 255, 0.15);
  border-color: transparent;
  color: #fff;
  box-shadow: none; }

.app-launcher-container .spectrum--light .spectrum-Button--overBackground.spectrum-Button--quiet.is-disabled, .app-launcher-container .spectrum--light .spectrum-Button--overBackground.spectrum-Button--quiet:disabled, .app-launcher-container .spectrum--light .spectrum-ClearButton--overBackground.is-disabled, .app-launcher-container .spectrum--light .spectrum-ClearButton--overBackground:disabled {
  background-color: transparent;
  border-color: transparent;
  color: rgba(255, 255, 255, 0.15); }

.app-launcher-container .spectrum--light .spectrum-Button--primary.spectrum-Button--quiet {
  background-color: transparent;
  border-color: transparent;
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum-Button--primary.spectrum-Button--quiet:hover {
  background-color: #eaeaea;
  border-color: #eaeaea;
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Button--primary.spectrum-Button--quiet.focus-ring {
  background-color: #1473e6;
  border-color: #1473e6;
  color: #fff; }

.app-launcher-container .spectrum--light .spectrum-Button--primary.spectrum-Button--quiet:active {
  background-color: #e1e1e1;
  border-color: #e1e1e1;
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Button--primary.spectrum-Button--quiet.is-disabled, .app-launcher-container .spectrum--light .spectrum-Button--primary.spectrum-Button--quiet:disabled {
  background-color: transparent;
  border-color: transparent;
  color: #b3b3b3; }

.app-launcher-container .spectrum--light .spectrum-Button--secondary.spectrum-Button--quiet {
  background-color: transparent;
  border-color: transparent;
  color: #6e6e6e; }

.app-launcher-container .spectrum--light .spectrum-Button--secondary.spectrum-Button--quiet:hover {
  background-color: #eaeaea;
  border-color: #eaeaea;
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum-Button--secondary.spectrum-Button--quiet.focus-ring {
  background-color: #1473e6;
  border-color: #1473e6;
  color: #fff; }

.app-launcher-container .spectrum--light .spectrum-Button--secondary.spectrum-Button--quiet:active {
  background-color: #e1e1e1;
  border-color: #e1e1e1;
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum-Button--secondary.spectrum-Button--quiet.is-disabled, .app-launcher-container .spectrum--light .spectrum-Button--secondary.spectrum-Button--quiet:disabled {
  background-color: transparent;
  border-color: transparent;
  color: #b3b3b3; }

.app-launcher-container .spectrum--light .spectrum-ActionButton, .app-launcher-container .spectrum--light .spectrum-Tool {
  background-color: #fafafa;
  border-color: #e1e1e1;
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum-ActionButton .spectrum-Icon, .app-launcher-container .spectrum--light .spectrum-Tool .spectrum-Icon {
  color: #6e6e6e; }

.app-launcher-container .spectrum--light .spectrum-ActionButton .spectrum-ActionButton-hold, .app-launcher-container .spectrum--light .spectrum-Tool .spectrum-ActionButton-hold {
  color: #6e6e6e; }

.app-launcher-container .spectrum--light .spectrum-ActionButton:hover, .app-launcher-container .spectrum--light .spectrum-Tool:hover {
  background-color: #fff;
  border-color: #cacaca;
  box-shadow: none;
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-ActionButton:hover .spectrum-Icon, .app-launcher-container .spectrum--light .spectrum-Tool:hover .spectrum-Icon {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-ActionButton:hover .spectrum-ActionButton-hold, .app-launcher-container .spectrum--light .spectrum-Tool:hover .spectrum-ActionButton-hold {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-ActionButton.focus-ring, .app-launcher-container .spectrum--light .spectrum-Tool.focus-ring {
  background-color: #fff;
  border-color: #2680eb;
  box-shadow: 0 0 0 1px #2680eb;
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-ActionButton.focus-ring .spectrum-Icon, .app-launcher-container .spectrum--light .spectrum-Tool.focus-ring .spectrum-Icon {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-ActionButton.focus-ring .spectrum-ActionButton-hold, .app-launcher-container .spectrum--light .spectrum-Tool.focus-ring .spectrum-ActionButton-hold {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-ActionButton:active, .app-launcher-container .spectrum--light .spectrum-Tool:active {
  background-color: #eaeaea;
  border-color: #cacaca;
  box-shadow: none;
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-ActionButton:active .spectrum-ActionButton-hold, .app-launcher-container .spectrum--light .spectrum-Tool:active .spectrum-ActionButton-hold {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-ActionButton.is-disabled, .app-launcher-container .spectrum--light .spectrum-ActionButton:disabled, .app-launcher-container .spectrum--light .spectrum-Tool:disabled {
  background-color: #eaeaea;
  border-color: #eaeaea;
  color: #b3b3b3; }

.app-launcher-container .spectrum--light .spectrum-ActionButton.is-disabled .spectrum-Icon, .app-launcher-container .spectrum--light .spectrum-ActionButton:disabled .spectrum-Icon, .app-launcher-container .spectrum--light .spectrum-Tool:disabled .spectrum-Icon {
  color: #cacaca; }

.app-launcher-container .spectrum--light .spectrum-ActionButton.is-disabled .spectrum-ActionButton-hold, .app-launcher-container .spectrum--light .spectrum-ActionButton:disabled .spectrum-ActionButton-hold, .app-launcher-container .spectrum--light .spectrum-Tool:disabled .spectrum-ActionButton-hold {
  color: #cacaca; }

.app-launcher-container .spectrum--light .spectrum-ActionButton.is-selected {
  background-color: #eaeaea;
  border-color: #e1e1e1;
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum-ActionButton.is-selected .spectrum-Icon {
  color: #6e6e6e; }

.app-launcher-container .spectrum--light .spectrum-ActionButton.is-selected.focus-ring {
  background-color: #eaeaea;
  border-color: #2680eb;
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-ActionButton.is-selected.focus-ring .spectrum-Icon {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-ActionButton.is-selected:hover {
  background-color: #eaeaea;
  border-color: #cacaca;
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-ActionButton.is-selected:hover .spectrum-Icon {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-ActionButton.is-selected:active {
  background-color: #eaeaea;
  border-color: #cacaca;
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-ActionButton.is-selected:active .spectrum-Icon {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-ActionButton.is-selected.is-disabled, .app-launcher-container .spectrum--light .spectrum-ActionButton.is-selected:disabled {
  background-color: #eaeaea;
  border-color: #eaeaea;
  color: #b3b3b3; }

.app-launcher-container .spectrum--light .spectrum-ActionButton.is-selected.is-disabled .spectrum-Icon, .app-launcher-container .spectrum--light .spectrum-ActionButton.is-selected:disabled .spectrum-Icon {
  color: #cacaca; }

.app-launcher-container .spectrum--light .spectrum-ActionButton--quiet, .app-launcher-container .spectrum--light .spectrum-Tool {
  background-color: transparent;
  border-color: transparent;
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum-ActionButton--quiet:hover, .app-launcher-container .spectrum--light .spectrum-Tool:hover {
  background-color: transparent;
  border-color: transparent;
  color: #2c2c2c;
  box-shadow: none; }

.app-launcher-container .spectrum--light .spectrum-ActionButton--quiet.focus-ring, .app-launcher-container .spectrum--light .spectrum-Tool.focus-ring {
  background-color: transparent;
  box-shadow: 0 0 0 1px #2680eb;
  border-color: #2680eb;
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-ActionButton--quiet:active, .app-launcher-container .spectrum--light .spectrum-Tool:active {
  background-color: #e1e1e1;
  border-color: #e1e1e1;
  color: #2c2c2c;
  box-shadow: none; }

.app-launcher-container .spectrum--light .spectrum-ActionButton--quiet.is-disabled, .app-launcher-container .spectrum--light .spectrum-ActionButton--quiet:disabled, .app-launcher-container .spectrum--light .spectrum-Tool:disabled {
  background-color: transparent;
  border-color: transparent;
  color: #b3b3b3; }

.app-launcher-container .spectrum--light .spectrum-ActionButton--quiet.is-selected {
  background-color: #e1e1e1;
  border-color: #e1e1e1;
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum-ActionButton--quiet.is-selected.focus-ring {
  background-color: #e1e1e1;
  border-color: #2680eb;
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-ActionButton--quiet.is-selected:hover {
  background-color: #e1e1e1;
  border-color: #e1e1e1;
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-ActionButton--quiet.is-selected:active {
  background-color: #e1e1e1;
  border-color: #e1e1e1;
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-ActionButton--quiet.is-selected.is-disabled, .app-launcher-container .spectrum--light .spectrum-ActionButton--quiet.is-selected:disabled {
  background-color: #eaeaea;
  border-color: #eaeaea;
  color: #b3b3b3; }

.app-launcher-container .spectrum--light .spectrum-Button--warning.spectrum-Button--quiet {
  background-color: transparent;
  border-color: transparent;
  color: #c9252d; }

.app-launcher-container .spectrum--light .spectrum-Button--warning.spectrum-Button--quiet:hover {
  background-color: #eaeaea;
  border-color: #eaeaea;
  color: #bb121a; }

.app-launcher-container .spectrum--light .spectrum-Button--warning.spectrum-Button--quiet.focus-ring {
  background-color: #1473e6;
  border-color: #1473e6;
  color: #fff; }

.app-launcher-container .spectrum--light .spectrum-Button--warning.spectrum-Button--quiet:active {
  background-color: #e1e1e1;
  border-color: #e1e1e1;
  color: #bb121a; }

.app-launcher-container .spectrum--light .spectrum-Button--warning.spectrum-Button--quiet.is-disabled, .app-launcher-container .spectrum--light .spectrum-Button--warning.spectrum-Button--quiet:disabled {
  background-color: transparent;
  border-color: transparent;
  color: #b3b3b3; }

.app-launcher-container .spectrum--light .spectrum-LogicButton--and {
  background-color: #1473e6;
  border-color: #1473e6;
  color: #fff; }

.app-launcher-container .spectrum--light .spectrum-LogicButton--and:hover {
  background-color: #095aba;
  border-color: #095aba;
  color: #fff; }

.app-launcher-container .spectrum--light .spectrum-LogicButton--and.focus-ring {
  background-color: #095aba;
  border-color: #2680eb;
  color: #fff; }

.app-launcher-container .spectrum--light .spectrum-LogicButton--and.is-disabled, .app-launcher-container .spectrum--light .spectrum-LogicButton--and:disabled {
  background-color: #eaeaea;
  border-color: #eaeaea;
  color: #b3b3b3; }

.app-launcher-container .spectrum--light .spectrum-LogicButton--or {
  background-color: #ce2783;
  border-color: #ce2783;
  color: #fff; }

.app-launcher-container .spectrum--light .spectrum-LogicButton--or:hover {
  background-color: #ae0e66;
  border-color: #ae0e66;
  color: #fff; }

.app-launcher-container .spectrum--light .spectrum-LogicButton--or.focus-ring {
  background-color: #ae0e66;
  border-color: #2680eb;
  color: #fff; }

.app-launcher-container .spectrum--light .spectrum-LogicButton--or.is-disabled, .app-launcher-container .spectrum--light .spectrum-LogicButton--or:disabled {
  background-color: #eaeaea;
  border-color: #eaeaea;
  color: #b3b3b3; }

.app-launcher-container .spectrum--light .spectrum-FieldButton {
  color: #4b4b4b;
  background-color: #fafafa;
  border-color: #e1e1e1; }

.app-launcher-container .spectrum--light .spectrum-FieldButton:hover {
  color: #2c2c2c;
  background-color: #fff;
  border-color: #cacaca; }

.app-launcher-container .spectrum--light .spectrum-FieldButton.focus-ring, .app-launcher-container .spectrum--light .spectrum-FieldButton.is-focused {
  background-color: #fff;
  border-color: #2680eb;
  box-shadow: 0 0 0 1px #2680eb;
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-FieldButton.focus-ring.is-placeholder, .app-launcher-container .spectrum--light .spectrum-FieldButton.is-focused.is-placeholder {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-FieldButton.is-selected, .app-launcher-container .spectrum--light .spectrum-FieldButton:active {
  background-color: #eaeaea;
  border-color: #cacaca; }

.app-launcher-container .spectrum--light .spectrum-FieldButton.is-selected.focus-ring, .app-launcher-container .spectrum--light .spectrum-FieldButton.is-selected.is-focused, .app-launcher-container .spectrum--light .spectrum-FieldButton:active.focus-ring, .app-launcher-container .spectrum--light .spectrum-FieldButton:active.is-focused {
  box-shadow: none; }

.app-launcher-container .spectrum--light .spectrum-FieldButton.is-invalid {
  border-color: #d7373f; }

.app-launcher-container .spectrum--light .spectrum-FieldButton.is-invalid:hover {
  border-color: #c9252d; }

.app-launcher-container .spectrum--light .spectrum-FieldButton.is-invalid.is-selected, .app-launcher-container .spectrum--light .spectrum-FieldButton.is-invalid:active {
  border-color: #c9252d; }

.app-launcher-container .spectrum--light .spectrum-FieldButton.is-invalid.focus-ring, .app-launcher-container .spectrum--light .spectrum-FieldButton.is-invalid.is-focused {
  border-color: #2680eb;
  box-shadow: 0 0 0 1px #2680eb; }

.app-launcher-container .spectrum--light .spectrum-FieldButton.is-disabled, .app-launcher-container .spectrum--light .spectrum-FieldButton:disabled {
  background-color: #eaeaea;
  color: #b3b3b3; }

.app-launcher-container .spectrum--light .spectrum-FieldButton.is-disabled.focus-ring, .app-launcher-container .spectrum--light .spectrum-FieldButton:disabled.focus-ring {
  box-shadow: none; }

.app-launcher-container .spectrum--light .spectrum-FieldButton.is-disabled .spectrum-Icon, .app-launcher-container .spectrum--light .spectrum-FieldButton:disabled .spectrum-Icon {
  color: #cacaca; }

.app-launcher-container .spectrum--light .spectrum-FieldButton--quiet {
  color: #4b4b4b;
  border-color: transparent;
  background-color: transparent; }

.app-launcher-container .spectrum--light .spectrum-FieldButton--quiet:hover {
  background-color: transparent;
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-FieldButton--quiet.focus-ring, .app-launcher-container .spectrum--light .spectrum-FieldButton--quiet.is-focused {
  background-color: transparent;
  box-shadow: 0 2px 0 0 #2680eb; }

.app-launcher-container .spectrum--light .spectrum-FieldButton--quiet.focus-ring.is-placeholder, .app-launcher-container .spectrum--light .spectrum-FieldButton--quiet.is-focused.is-placeholder {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-FieldButton--quiet.is-selected, .app-launcher-container .spectrum--light .spectrum-FieldButton--quiet:active {
  background-color: transparent;
  border-color: transparent; }

.app-launcher-container .spectrum--light .spectrum-FieldButton--quiet.is-selected.focus-ring, .app-launcher-container .spectrum--light .spectrum-FieldButton--quiet.is-selected.is-focused, .app-launcher-container .spectrum--light .spectrum-FieldButton--quiet:active.focus-ring, .app-launcher-container .spectrum--light .spectrum-FieldButton--quiet:active.is-focused {
  background-color: transparent;
  box-shadow: 0 2px 0 0 #2680eb; }

.app-launcher-container .spectrum--light .spectrum-FieldButton--quiet.is-invalid.focus-ring, .app-launcher-container .spectrum--light .spectrum-FieldButton--quiet.is-invalid.is-focused {
  box-shadow: 0 2px 0 0 #2680eb; }

.app-launcher-container .spectrum--light .spectrum-FieldButton--quiet.is-disabled, .app-launcher-container .spectrum--light .spectrum-FieldButton--quiet:disabled {
  background-color: transparent;
  color: #b3b3b3; }

.app-launcher-container .spectrum--light .spectrum-FieldButton--quiet.is-disabled.focus-ring, .app-launcher-container .spectrum--light .spectrum-FieldButton--quiet:disabled.focus-ring {
  box-shadow: none; }

.app-launcher-container .spectrum--light .spectrum-Tool.is-selected .spectrum-Icon {
  color: #1473e6; }

.app-launcher-container .spectrum--light .spectrum-Tool.is-selected .spectrum-Tool-hold {
  color: #1473e6; }

.app-launcher-container .spectrum--light .spectrum-Tool.is-selected:hover .spectrum-Icon {
  color: #0d66d0; }

.app-launcher-container .spectrum--light .spectrum-Tool.is-selected:hover .spectrum-Tool-hold {
  color: #0d66d0; }

.app-launcher-container .spectrum--light .spectrum-Tool.is-selected:active .spectrum-Icon {
  color: #095aba; }

.app-launcher-container .spectrum--light .spectrum-Tool.is-selected:active .spectrum-Tool-hold {
  color: #095aba; }

.app-launcher-container .spectrum--light .spectrum-Tool.is-selected.focus-ring .spectrum-Icon {
  color: #0d66d0; }

.app-launcher-container .spectrum--light .spectrum-Tool.is-selected.focus-ring .spectrum-Tool-hold {
  color: #0d66d0; }

.app-launcher-container .spectrum--light .spectrum-Tool.is-selected.is-disabled, .app-launcher-container .spectrum--light .spectrum-Tool.is-selected:disabled {
  background-color: transparent;
  border-color: transparent; }

.app-launcher-container .spectrum--light .spectrum-Tool.is-selected.is-disabled .spectrum-Icon, .app-launcher-container .spectrum--light .spectrum-Tool.is-selected:disabled .spectrum-Icon {
  color: #cacaca; }

.app-launcher-container .spectrum--light .spectrum-Tool.is-selected.is-disabled .spectrum-Tool-hold, .app-launcher-container .spectrum--light .spectrum-Tool.is-selected:disabled .spectrum-Tool-hold {
  color: #cacaca; }

.app-launcher-container .spectrum--light .spectrum-Tool .spectrum-Tool-hold {
  color: #6e6e6e; }

.app-launcher-container .spectrum--light .spectrum-Tool:hover .spectrum-Tool-hold {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Tool:active {
  background-color: transparent;
  border-color: transparent; }

.app-launcher-container .spectrum--light .spectrum-Tool:active .spectrum-Tool-hold {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Tool.focus-ring .spectrum-Tool-hold {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Tool.is-disabled .spectrum-Tool-hold, .app-launcher-container .spectrum--light .spectrum-Tool:disabled .spectrum-Tool-hold {
  color: #cacaca; }

.app-launcher-container .spectrum--dark .spectrum-Button.focus-ring {
  box-shadow: 0 0 0 1px #1473e6; }

.app-launcher-container .spectrum--dark .spectrum-Button:active {
  box-shadow: none; }

.app-launcher-container .spectrum--dark .spectrum-ClearButton {
  background-color: transparent;
  color: #b9b9b9; }

.app-launcher-container .spectrum--dark .spectrum-ClearButton:hover {
  background-color: transparent;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-ClearButton:active {
  background-color: transparent;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-ClearButton.focus-ring {
  background-color: transparent;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-ClearButton.is-disabled, .app-launcher-container .spectrum--dark .spectrum-ClearButton:disabled {
  background-color: transparent;
  color: #5a5a5a; }

.app-launcher-container .spectrum--dark .spectrum-Button--cta {
  background-color: #1473e6;
  border-color: #1473e6;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Button--cta:hover {
  background-color: #0d66d0;
  border-color: #0d66d0;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Button--cta.focus-ring {
  background-color: #1473e6;
  border-color: #1473e6;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Button--cta:active {
  background-color: #0d66d0;
  border-color: #0d66d0;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Button--cta.is-disabled, .app-launcher-container .spectrum--dark .spectrum-Button--cta:disabled {
  background-color: #3e3e3e;
  border-color: #3e3e3e;
  color: #6e6e6e; }

.app-launcher-container .spectrum--dark .spectrum-Button--primary {
  background-color: transparent;
  border-color: #e3e3e3;
  color: #e3e3e3; }

.app-launcher-container .spectrum--dark .spectrum-Button--primary:hover {
  background-color: #e3e3e3;
  border-color: #e3e3e3;
  color: #252525; }

.app-launcher-container .spectrum--dark .spectrum-Button--primary.focus-ring {
  background-color: #1473e6;
  border-color: #1473e6;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Button--primary:active {
  background-color: #fff;
  border-color: #fff;
  color: #252525; }

.app-launcher-container .spectrum--dark .spectrum-Button--primary.is-disabled, .app-launcher-container .spectrum--dark .spectrum-Button--primary:disabled {
  background-color: #3e3e3e;
  border-color: #3e3e3e;
  color: #6e6e6e; }

.app-launcher-container .spectrum--dark .spectrum-Button--secondary {
  background-color: transparent;
  border-color: #b9b9b9;
  color: #b9b9b9; }

.app-launcher-container .spectrum--dark .spectrum-Button--secondary:hover {
  background-color: #b9b9b9;
  border-color: #b9b9b9;
  color: #252525; }

.app-launcher-container .spectrum--dark .spectrum-Button--secondary.focus-ring {
  background-color: #1473e6;
  border-color: #1473e6;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Button--secondary:active {
  background-color: #e3e3e3;
  border-color: #e3e3e3;
  color: #252525; }

.app-launcher-container .spectrum--dark .spectrum-Button--secondary.is-disabled, .app-launcher-container .spectrum--dark .spectrum-Button--secondary:disabled {
  background-color: #3e3e3e;
  border-color: #3e3e3e;
  color: #6e6e6e; }

.app-launcher-container .spectrum--dark .spectrum-Button--warning {
  background-color: transparent;
  border-color: #f76d74;
  color: #f76d74; }

.app-launcher-container .spectrum--dark .spectrum-Button--warning:hover {
  background-color: #f76d74;
  border-color: #f76d74;
  color: #252525; }

.app-launcher-container .spectrum--dark .spectrum-Button--warning.focus-ring {
  background-color: #1473e6;
  border-color: #1473e6;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Button--warning:active {
  background-color: #ff7b82;
  border-color: #ff7b82;
  color: #252525; }

.app-launcher-container .spectrum--dark .spectrum-Button--warning.is-disabled, .app-launcher-container .spectrum--dark .spectrum-Button--warning:disabled {
  background-color: #3e3e3e;
  border-color: #3e3e3e;
  color: #6e6e6e; }

.app-launcher-container .spectrum--dark .spectrum-Button--overBackground {
  background-color: transparent;
  border-color: #fff;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Button--overBackground:hover {
  background-color: #fff;
  border-color: #fff;
  color: inherit; }

.app-launcher-container .spectrum--dark .spectrum-Button--overBackground.focus-ring {
  background-color: #fff;
  border-color: #fff;
  color: inherit;
  box-shadow: 0 0 0 1px #fff; }

.app-launcher-container .spectrum--dark .spectrum-Button--overBackground:active {
  background-color: #fff;
  border-color: #fff;
  color: inherit;
  box-shadow: none; }

.app-launcher-container .spectrum--dark .spectrum-Button--overBackground.is-disabled, .app-launcher-container .spectrum--dark .spectrum-Button--overBackground:disabled {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: transparent;
  color: rgba(255, 255, 255, 0.35); }

.app-launcher-container .spectrum--dark .spectrum-Button--overBackground.spectrum-Button--quiet, .app-launcher-container .spectrum--dark .spectrum-ClearButton--overBackground {
  background-color: transparent;
  border-color: transparent;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Button--overBackground.spectrum-Button--quiet:hover, .app-launcher-container .spectrum--dark .spectrum-ClearButton--overBackground:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: transparent;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Button--overBackground.spectrum-Button--quiet.focus-ring, .app-launcher-container .spectrum--dark .spectrum-ClearButton--overBackground.focus-ring {
  background-color: #fff;
  border-color: #fff;
  color: inherit;
  box-shadow: 0 0 0 1px #fff; }

.app-launcher-container .spectrum--dark .spectrum-Button--overBackground.spectrum-Button--quiet:active, .app-launcher-container .spectrum--dark .spectrum-ClearButton--overBackground:active {
  background-color: rgba(255, 255, 255, 0.15);
  border-color: transparent;
  color: #fff;
  box-shadow: none; }

.app-launcher-container .spectrum--dark .spectrum-Button--overBackground.spectrum-Button--quiet.is-disabled, .app-launcher-container .spectrum--dark .spectrum-Button--overBackground.spectrum-Button--quiet:disabled, .app-launcher-container .spectrum--dark .spectrum-ClearButton--overBackground.is-disabled, .app-launcher-container .spectrum--dark .spectrum-ClearButton--overBackground:disabled {
  background-color: transparent;
  border-color: transparent;
  color: rgba(255, 255, 255, 0.15); }

.app-launcher-container .spectrum--dark .spectrum-Button--primary.spectrum-Button--quiet {
  background-color: transparent;
  border-color: transparent;
  color: #e3e3e3; }

.app-launcher-container .spectrum--dark .spectrum-Button--primary.spectrum-Button--quiet:hover {
  background-color: #3e3e3e;
  border-color: #3e3e3e;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Button--primary.spectrum-Button--quiet.focus-ring {
  background-color: #1473e6;
  border-color: #1473e6;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Button--primary.spectrum-Button--quiet:active {
  background-color: #4a4a4a;
  border-color: #4a4a4a;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Button--primary.spectrum-Button--quiet.is-disabled, .app-launcher-container .spectrum--dark .spectrum-Button--primary.spectrum-Button--quiet:disabled {
  background-color: transparent;
  border-color: transparent;
  color: #6e6e6e; }

.app-launcher-container .spectrum--dark .spectrum-Button--secondary.spectrum-Button--quiet {
  background-color: transparent;
  border-color: transparent;
  color: #b9b9b9; }

.app-launcher-container .spectrum--dark .spectrum-Button--secondary.spectrum-Button--quiet:hover {
  background-color: #3e3e3e;
  border-color: #3e3e3e;
  color: #e3e3e3; }

.app-launcher-container .spectrum--dark .spectrum-Button--secondary.spectrum-Button--quiet.focus-ring {
  background-color: #1473e6;
  border-color: #1473e6;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Button--secondary.spectrum-Button--quiet:active {
  background-color: #4a4a4a;
  border-color: #4a4a4a;
  color: #e3e3e3; }

.app-launcher-container .spectrum--dark .spectrum-Button--secondary.spectrum-Button--quiet.is-disabled, .app-launcher-container .spectrum--dark .spectrum-Button--secondary.spectrum-Button--quiet:disabled {
  background-color: transparent;
  border-color: transparent;
  color: #6e6e6e; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton, .app-launcher-container .spectrum--dark .spectrum-Tool {
  background-color: #2f2f2f;
  border-color: #4a4a4a;
  color: #e3e3e3; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton .spectrum-Icon, .app-launcher-container .spectrum--dark .spectrum-Tool .spectrum-Icon {
  color: #b9b9b9; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton .spectrum-ActionButton-hold, .app-launcher-container .spectrum--dark .spectrum-Tool .spectrum-ActionButton-hold {
  color: #b9b9b9; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton:hover, .app-launcher-container .spectrum--dark .spectrum-Tool:hover {
  background-color: #252525;
  border-color: #5a5a5a;
  box-shadow: none;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton:hover .spectrum-Icon, .app-launcher-container .spectrum--dark .spectrum-Tool:hover .spectrum-Icon {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton:hover .spectrum-ActionButton-hold, .app-launcher-container .spectrum--dark .spectrum-Tool:hover .spectrum-ActionButton-hold {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton.focus-ring, .app-launcher-container .spectrum--dark .spectrum-Tool.focus-ring {
  background-color: #252525;
  border-color: #2680eb;
  box-shadow: 0 0 0 1px #2680eb;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton.focus-ring .spectrum-Icon, .app-launcher-container .spectrum--dark .spectrum-Tool.focus-ring .spectrum-Icon {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton.focus-ring .spectrum-ActionButton-hold, .app-launcher-container .spectrum--dark .spectrum-Tool.focus-ring .spectrum-ActionButton-hold {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton:active, .app-launcher-container .spectrum--dark .spectrum-Tool:active {
  background-color: #3e3e3e;
  border-color: #5a5a5a;
  box-shadow: none;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton:active .spectrum-ActionButton-hold, .app-launcher-container .spectrum--dark .spectrum-Tool:active .spectrum-ActionButton-hold {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton.is-disabled, .app-launcher-container .spectrum--dark .spectrum-ActionButton:disabled, .app-launcher-container .spectrum--dark .spectrum-Tool:disabled {
  background-color: #3e3e3e;
  border-color: #3e3e3e;
  color: #6e6e6e; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton.is-disabled .spectrum-Icon, .app-launcher-container .spectrum--dark .spectrum-ActionButton:disabled .spectrum-Icon, .app-launcher-container .spectrum--dark .spectrum-Tool:disabled .spectrum-Icon {
  color: #5a5a5a; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton.is-disabled .spectrum-ActionButton-hold, .app-launcher-container .spectrum--dark .spectrum-ActionButton:disabled .spectrum-ActionButton-hold, .app-launcher-container .spectrum--dark .spectrum-Tool:disabled .spectrum-ActionButton-hold {
  color: #5a5a5a; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton.is-selected {
  background-color: #3e3e3e;
  border-color: #4a4a4a;
  color: #e3e3e3; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton.is-selected .spectrum-Icon {
  color: #b9b9b9; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton.is-selected.focus-ring {
  background-color: #3e3e3e;
  border-color: #2680eb;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton.is-selected.focus-ring .spectrum-Icon {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton.is-selected:hover {
  background-color: #3e3e3e;
  border-color: #5a5a5a;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton.is-selected:hover .spectrum-Icon {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton.is-selected:active {
  background-color: #3e3e3e;
  border-color: #5a5a5a;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton.is-selected:active .spectrum-Icon {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton.is-selected.is-disabled, .app-launcher-container .spectrum--dark .spectrum-ActionButton.is-selected:disabled {
  background-color: #3e3e3e;
  border-color: #3e3e3e;
  color: #6e6e6e; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton.is-selected.is-disabled .spectrum-Icon, .app-launcher-container .spectrum--dark .spectrum-ActionButton.is-selected:disabled .spectrum-Icon {
  color: #5a5a5a; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton--quiet, .app-launcher-container .spectrum--dark .spectrum-Tool {
  background-color: transparent;
  border-color: transparent;
  color: #e3e3e3; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton--quiet:hover, .app-launcher-container .spectrum--dark .spectrum-Tool:hover {
  background-color: transparent;
  border-color: transparent;
  color: #fff;
  box-shadow: none; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton--quiet.focus-ring, .app-launcher-container .spectrum--dark .spectrum-Tool.focus-ring {
  background-color: transparent;
  box-shadow: 0 0 0 1px #2680eb;
  border-color: #2680eb;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton--quiet:active, .app-launcher-container .spectrum--dark .spectrum-Tool:active {
  background-color: #4a4a4a;
  border-color: #4a4a4a;
  color: #fff;
  box-shadow: none; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton--quiet.is-disabled, .app-launcher-container .spectrum--dark .spectrum-ActionButton--quiet:disabled, .app-launcher-container .spectrum--dark .spectrum-Tool:disabled {
  background-color: transparent;
  border-color: transparent;
  color: #6e6e6e; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton--quiet.is-selected {
  background-color: #4a4a4a;
  border-color: #4a4a4a;
  color: #e3e3e3; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton--quiet.is-selected.focus-ring {
  background-color: #4a4a4a;
  border-color: #2680eb;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton--quiet.is-selected:hover {
  background-color: #4a4a4a;
  border-color: #4a4a4a;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton--quiet.is-selected:active {
  background-color: #4a4a4a;
  border-color: #4a4a4a;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-ActionButton--quiet.is-selected.is-disabled, .app-launcher-container .spectrum--dark .spectrum-ActionButton--quiet.is-selected:disabled {
  background-color: #3e3e3e;
  border-color: #3e3e3e;
  color: #6e6e6e; }

.app-launcher-container .spectrum--dark .spectrum-Button--warning.spectrum-Button--quiet {
  background-color: transparent;
  border-color: transparent;
  color: #f76d74; }

.app-launcher-container .spectrum--dark .spectrum-Button--warning.spectrum-Button--quiet:hover {
  background-color: #3e3e3e;
  border-color: #3e3e3e;
  color: #ff7b82; }

.app-launcher-container .spectrum--dark .spectrum-Button--warning.spectrum-Button--quiet.focus-ring {
  background-color: #1473e6;
  border-color: #1473e6;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Button--warning.spectrum-Button--quiet:active {
  background-color: #4a4a4a;
  border-color: #4a4a4a;
  color: #ff7b82; }

.app-launcher-container .spectrum--dark .spectrum-Button--warning.spectrum-Button--quiet.is-disabled, .app-launcher-container .spectrum--dark .spectrum-Button--warning.spectrum-Button--quiet:disabled {
  background-color: transparent;
  border-color: transparent;
  color: #6e6e6e; }

.app-launcher-container .spectrum--dark .spectrum-LogicButton--and {
  background-color: #378ef0;
  border-color: #378ef0;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-LogicButton--and:hover {
  background-color: #5aa9fa;
  border-color: #5aa9fa;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-LogicButton--and.focus-ring {
  background-color: #5aa9fa;
  border-color: #2680eb;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-LogicButton--and.is-disabled, .app-launcher-container .spectrum--dark .spectrum-LogicButton--and:disabled {
  background-color: #3e3e3e;
  border-color: #3e3e3e;
  color: #6e6e6e; }

.app-launcher-container .spectrum--dark .spectrum-LogicButton--or {
  background-color: #e2499d;
  border-color: #e2499d;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-LogicButton--or:hover {
  background-color: #f56bb7;
  border-color: #f56bb7;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-LogicButton--or.focus-ring {
  background-color: #f56bb7;
  border-color: #2680eb;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-LogicButton--or.is-disabled, .app-launcher-container .spectrum--dark .spectrum-LogicButton--or:disabled {
  background-color: #3e3e3e;
  border-color: #3e3e3e;
  color: #6e6e6e; }

.app-launcher-container .spectrum--dark .spectrum-FieldButton {
  color: #e3e3e3;
  background-color: #2f2f2f;
  border-color: #4a4a4a; }

.app-launcher-container .spectrum--dark .spectrum-FieldButton:hover {
  color: #fff;
  background-color: #252525;
  border-color: #5a5a5a; }

.app-launcher-container .spectrum--dark .spectrum-FieldButton.focus-ring, .app-launcher-container .spectrum--dark .spectrum-FieldButton.is-focused {
  background-color: #252525;
  border-color: #2680eb;
  box-shadow: 0 0 0 1px #2680eb;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-FieldButton.focus-ring.is-placeholder, .app-launcher-container .spectrum--dark .spectrum-FieldButton.is-focused.is-placeholder {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-FieldButton.is-selected, .app-launcher-container .spectrum--dark .spectrum-FieldButton:active {
  background-color: #3e3e3e;
  border-color: #5a5a5a; }

.app-launcher-container .spectrum--dark .spectrum-FieldButton.is-selected.focus-ring, .app-launcher-container .spectrum--dark .spectrum-FieldButton.is-selected.is-focused, .app-launcher-container .spectrum--dark .spectrum-FieldButton:active.focus-ring, .app-launcher-container .spectrum--dark .spectrum-FieldButton:active.is-focused {
  box-shadow: none; }

.app-launcher-container .spectrum--dark .spectrum-FieldButton.is-invalid {
  border-color: #ec5b62; }

.app-launcher-container .spectrum--dark .spectrum-FieldButton.is-invalid:hover {
  border-color: #f76d74; }

.app-launcher-container .spectrum--dark .spectrum-FieldButton.is-invalid.is-selected, .app-launcher-container .spectrum--dark .spectrum-FieldButton.is-invalid:active {
  border-color: #f76d74; }

.app-launcher-container .spectrum--dark .spectrum-FieldButton.is-invalid.focus-ring, .app-launcher-container .spectrum--dark .spectrum-FieldButton.is-invalid.is-focused {
  border-color: #2680eb;
  box-shadow: 0 0 0 1px #2680eb; }

.app-launcher-container .spectrum--dark .spectrum-FieldButton.is-disabled, .app-launcher-container .spectrum--dark .spectrum-FieldButton:disabled {
  background-color: #3e3e3e;
  color: #6e6e6e; }

.app-launcher-container .spectrum--dark .spectrum-FieldButton.is-disabled.focus-ring, .app-launcher-container .spectrum--dark .spectrum-FieldButton:disabled.focus-ring {
  box-shadow: none; }

.app-launcher-container .spectrum--dark .spectrum-FieldButton.is-disabled .spectrum-Icon, .app-launcher-container .spectrum--dark .spectrum-FieldButton:disabled .spectrum-Icon {
  color: #5a5a5a; }

.app-launcher-container .spectrum--dark .spectrum-FieldButton--quiet {
  color: #e3e3e3;
  border-color: transparent;
  background-color: transparent; }

.app-launcher-container .spectrum--dark .spectrum-FieldButton--quiet:hover {
  background-color: transparent;
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-FieldButton--quiet.focus-ring, .app-launcher-container .spectrum--dark .spectrum-FieldButton--quiet.is-focused {
  background-color: transparent;
  box-shadow: 0 2px 0 0 #2680eb; }

.app-launcher-container .spectrum--dark .spectrum-FieldButton--quiet.focus-ring.is-placeholder, .app-launcher-container .spectrum--dark .spectrum-FieldButton--quiet.is-focused.is-placeholder {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-FieldButton--quiet.is-selected, .app-launcher-container .spectrum--dark .spectrum-FieldButton--quiet:active {
  background-color: transparent;
  border-color: transparent; }

.app-launcher-container .spectrum--dark .spectrum-FieldButton--quiet.is-selected.focus-ring, .app-launcher-container .spectrum--dark .spectrum-FieldButton--quiet.is-selected.is-focused, .app-launcher-container .spectrum--dark .spectrum-FieldButton--quiet:active.focus-ring, .app-launcher-container .spectrum--dark .spectrum-FieldButton--quiet:active.is-focused {
  background-color: transparent;
  box-shadow: 0 2px 0 0 #2680eb; }

.app-launcher-container .spectrum--dark .spectrum-FieldButton--quiet.is-invalid.focus-ring, .app-launcher-container .spectrum--dark .spectrum-FieldButton--quiet.is-invalid.is-focused {
  box-shadow: 0 2px 0 0 #2680eb; }

.app-launcher-container .spectrum--dark .spectrum-FieldButton--quiet.is-disabled, .app-launcher-container .spectrum--dark .spectrum-FieldButton--quiet:disabled {
  background-color: transparent;
  color: #6e6e6e; }

.app-launcher-container .spectrum--dark .spectrum-FieldButton--quiet.is-disabled.focus-ring, .app-launcher-container .spectrum--dark .spectrum-FieldButton--quiet:disabled.focus-ring {
  box-shadow: none; }

.app-launcher-container .spectrum--dark .spectrum-Tool.is-selected .spectrum-Icon {
  color: #378ef0; }

.app-launcher-container .spectrum--dark .spectrum-Tool.is-selected .spectrum-Tool-hold {
  color: #378ef0; }

.app-launcher-container .spectrum--dark .spectrum-Tool.is-selected:hover .spectrum-Icon {
  color: #4b9cf5; }

.app-launcher-container .spectrum--dark .spectrum-Tool.is-selected:hover .spectrum-Tool-hold {
  color: #4b9cf5; }

.app-launcher-container .spectrum--dark .spectrum-Tool.is-selected:active .spectrum-Icon {
  color: #5aa9fa; }

.app-launcher-container .spectrum--dark .spectrum-Tool.is-selected:active .spectrum-Tool-hold {
  color: #5aa9fa; }

.app-launcher-container .spectrum--dark .spectrum-Tool.is-selected.focus-ring .spectrum-Icon {
  color: #4b9cf5; }

.app-launcher-container .spectrum--dark .spectrum-Tool.is-selected.focus-ring .spectrum-Tool-hold {
  color: #4b9cf5; }

.app-launcher-container .spectrum--dark .spectrum-Tool.is-selected.is-disabled, .app-launcher-container .spectrum--dark .spectrum-Tool.is-selected:disabled {
  background-color: transparent;
  border-color: transparent; }

.app-launcher-container .spectrum--dark .spectrum-Tool.is-selected.is-disabled .spectrum-Icon, .app-launcher-container .spectrum--dark .spectrum-Tool.is-selected:disabled .spectrum-Icon {
  color: #5a5a5a; }

.app-launcher-container .spectrum--dark .spectrum-Tool.is-selected.is-disabled .spectrum-Tool-hold, .app-launcher-container .spectrum--dark .spectrum-Tool.is-selected:disabled .spectrum-Tool-hold {
  color: #5a5a5a; }

.app-launcher-container .spectrum--dark .spectrum-Tool .spectrum-Tool-hold {
  color: #b9b9b9; }

.app-launcher-container .spectrum--dark .spectrum-Tool:hover .spectrum-Tool-hold {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Tool:active {
  background-color: transparent;
  border-color: transparent; }

.app-launcher-container .spectrum--dark .spectrum-Tool:active .spectrum-Tool-hold {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Tool.focus-ring .spectrum-Tool-hold {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Tool.is-disabled .spectrum-Tool-hold, .app-launcher-container .spectrum--dark .spectrum-Tool:disabled .spectrum-Tool-hold {
  color: #5a5a5a; }

.app-launcher-container .spectrum-Dialog.react-spectrum-Dialog {
  visibility: visible; }

.app-launcher-container .react-spectrum-Dialog-buttons {
  white-space: nowrap; }

.app-launcher-container .spectrum-Popover.react-spectrum-Popover {
  visibility: visible; }

.app-launcher-container .spectrum-Popover .spectrum-Dialog-content {
  max-height: initial; }

.app-launcher-container .spectrum-Popover {
  visibility: hidden;
  opacity: 0;
  transition: transform 130ms ease-in-out,opacity 130ms ease-in-out,visibility 0s linear 130ms;
  pointer-events: none; }

.app-launcher-container .spectrum-Popover.is-open {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
  pointer-events: auto; }

.app-launcher-container .spectrum-Popover--bottom.is-open {
  -ms-transform: translateY(6px);
      transform: translateY(6px); }

.app-launcher-container .spectrum-Popover--top.is-open {
  -ms-transform: translateY(-6px);
      transform: translateY(-6px); }

.app-launcher-container .spectrum-Popover--right.is-open {
  -ms-transform: translateX(6px);
      transform: translateX(6px); }

.app-launcher-container .spectrum-Popover--left.is-open {
  -ms-transform: translateX(-6px);
      transform: translateX(-6px); }

.app-launcher-container .spectrum-Popover {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-direction: column;
  flex-direction: column;
  box-sizing: border-box;
  min-width: 32px;
  min-height: 32px;
  position: absolute;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  outline: 0; }

.app-launcher-container .spectrum-Popover-tip {
  position: absolute;
  overflow: hidden;
  width: 21px;
  height: 11px; }

.app-launcher-container .spectrum-Popover-tip::after {
  content: '';
  width: 20px;
  height: 20px;
  border-width: 1px;
  border-style: solid;
  position: absolute;
  -ms-transform: rotate(45deg);
      transform: rotate(45deg);
  top: -18px;
  left: -1px; }

.app-launcher-container .spectrum-Popover--dialog {
  min-width: 270px;
  padding: 30px 29px; }

.app-launcher-container .spectrum-Popover--left.spectrum-Popover--withTip {
  margin-right: 13px; }

.app-launcher-container .spectrum-Popover--left .spectrum-Popover-tip {
  right: -16px;
  -ms-transform: rotate(-90deg);
      transform: rotate(-90deg); }

.app-launcher-container .spectrum-Popover--right.spectrum-Popover--withTip {
  margin-left: 13px; }

.app-launcher-container .spectrum-Popover--right .spectrum-Popover-tip {
  left: -16px;
  -ms-transform: rotate(90deg);
      transform: rotate(90deg); }

.app-launcher-container .spectrum-Popover--left .spectrum-Popover-tip, .app-launcher-container .spectrum-Popover--right .spectrum-Popover-tip {
  top: 50%;
  margin-top: -6px; }

.app-launcher-container .spectrum-Popover--bottom.spectrum-Popover--withTip {
  margin-top: 13px; }

.app-launcher-container .spectrum-Popover--bottom .spectrum-Popover-tip {
  top: -11px;
  -ms-transform: rotate(180deg);
      transform: rotate(180deg); }

.app-launcher-container .spectrum-Popover--top.spectrum-Popover--withTip {
  margin-bottom: 13px; }

.app-launcher-container .spectrum-Popover--top .spectrum-Popover-tip {
  bottom: -11px; }

.app-launcher-container .spectrum-Popover--bottom .spectrum-Popover-tip, .app-launcher-container .spectrum-Popover--top .spectrum-Popover-tip {
  left: 50%;
  margin-left: -12px; }

.app-launcher-container .spectrum--light .spectrum-Popover {
  background-color: #fff;
  border-color: #cacaca;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15); }

.app-launcher-container .spectrum--light .spectrum-Popover .spectrum-Dialog-footer, .app-launcher-container .spectrum--light .spectrum-Popover .spectrum-Dialog-header, .app-launcher-container .spectrum--light .spectrum-Popover .spectrum-Dialog-wrapper {
  background-color: transparent; }

.app-launcher-container .spectrum--light .spectrum-Popover .spectrum-Popover-tip::after {
  background-color: #fff;
  border-color: #cacaca;
  box-shadow: -1px -1px 4px rgba(0, 0, 0, 0.15); }

.app-launcher-container .spectrum--dark .spectrum-Popover {
  background-color: #252525;
  border-color: #5a5a5a;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5); }

.app-launcher-container .spectrum--dark .spectrum-Popover .spectrum-Dialog-footer, .app-launcher-container .spectrum--dark .spectrum-Popover .spectrum-Dialog-header, .app-launcher-container .spectrum--dark .spectrum-Popover .spectrum-Dialog-wrapper {
  background-color: transparent; }

.app-launcher-container .spectrum--dark .spectrum-Popover .spectrum-Popover-tip::after {
  background-color: #252525;
  border-color: #5a5a5a;
  box-shadow: -1px -1px 4px rgba(0, 0, 0, 0.5); }

.app-launcher-container .spectrum-Dialog {
  visibility: hidden;
  opacity: 0;
  transition: transform 130ms ease-in-out,opacity 130ms ease-in-out,visibility 0s linear 130ms;
  pointer-events: none; }

.app-launcher-container .spectrum-Dialog.is-open {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
  pointer-events: auto; }

.app-launcher-container .spectrum-Dialog {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  position: fixed;
  left: 50%;
  top: 50%;
  -ms-transform: translateX(-50%) translateY(-50%) translateY(20px);
      transform: translateX(-50%) translateY(-50%) translateY(20px);
  z-index: 2;
  box-sizing: border-box;
  max-width: 90vw;
  max-height: 90vh;
  width: fit-content;
  min-width: 288px;
  padding: 40px;
  border-radius: 4px;
  outline: 0;
  transition: opacity 130ms cubic-bezier(0.5, 0, 1, 1) 0s, visibility 0s linear 130ms, transform 0s linear 130ms; }

.app-launcher-container .spectrum-Dialog.is-open {
  transition: transform 250ms cubic-bezier(0, 0, 0.4, 1) 160ms, opacity 250ms cubic-bezier(0, 0, 0.4, 1) 160ms;
  -ms-transform: translate(-50%, calc(-50% + -2vh));
      transform: translate(-50%, calc(-50% + -2vh)); }

.app-launcher-container .spectrum-Dialog .spectrum-Dialog-closeButton {
  display: none; }

.app-launcher-container .spectrum-Dialog--small {
  width: 400px; }

.app-launcher-container .spectrum-Dialog--medium {
  width: 480px; }

.app-launcher-container .spectrum-Dialog--large {
  width: 640px; }

.app-launcher-container .spectrum-Dialog-hero {
  height: 128px;
  margin-left: -40px;
  margin-right: -40px;
  margin-top: -40px;
  margin-bottom: 40px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-size: cover;
  background-position: center center; }

.app-launcher-container .spectrum-Dialog-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 4px 4px 0 0;
  outline: 0;
  padding-bottom: 30px; }

.app-launcher-container .spectrum-Dialog-header::after {
  position: absolute;
  bottom: 16px;
  left: 0;
  right: 0;
  content: '';
  height: 2px; }

.app-launcher-container .spectrum-Dialog-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  visibility: hidden;
  pointer-events: none;
  z-index: 2;
  transition: visibility 0s linear 130ms; }

.app-launcher-container .spectrum-Dialog-wrapper.is-open {
  visibility: visible; }

.app-launcher-container .spectrum-Dialog-wrapper .spectrum-Dialog:not(.spectrum-Dialog--fullscreen):not(.spectrum-Dialog--fullscreenTakeover) {
  pointer-events: auto;
  position: relative;
  left: auto;
  top: auto;
  -ms-transform: translateY(20px);
      transform: translateY(20px);
  margin-top: -2vh; }

.app-launcher-container .spectrum-Dialog-wrapper .spectrum-Dialog:not(.spectrum-Dialog--fullscreen):not(.spectrum-Dialog--fullscreenTakeover).is-open {
  -ms-transform: translateY(0);
      transform: translateY(0); }

@media only screen and (max-device-width: 400px), only screen and (max-device-height: 350px) {
  .app-launcher-container .spectrum-Dialog--responsive {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    border-radius: 0; }
  .app-launcher-container .spectrum-Dialog-wrapper .spectrum-Dialog--responsive {
    margin-top: 0; } }

.app-launcher-container .spectrum-Dialog--noDivider .spectrum-Dialog-header::after {
  display: none; }

.app-launcher-container .spectrum-Dialog--dismissible .spectrum-Dialog-footer {
  display: none; }

.app-launcher-container .spectrum-Dialog--dismissible .spectrum-Dialog-closeButton {
  display: initial; }

.app-launcher-container .spectrum-Dialog-closeButton {
  position: absolute;
  top: -28px;
  right: -28px; }

.app-launcher-container .spectrum-Dialog-typeIcon {
  display: block; }

.app-launcher-container .spectrum-Dialog-content {
  display: block;
  box-sizing: border-box;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  outline: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .app-launcher-container .spectrum-Dialog-content {
    max-height: 70vh; } }

.app-launcher-container .spectrum-Dialog-footer {
  border-radius: 0 0 4px 4px;
  padding-top: 28px;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
  outline: 0;
  margin: -8px;
  margin-top: 0; }

.app-launcher-container .spectrum-Dialog-footer > *, .app-launcher-container .spectrum-Dialog-footer > .spectrum-Button + .spectrum-Button {
  margin: 8px; }

.app-launcher-container .spectrum-Dialog-title {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.3;
  outline: 0; }

.app-launcher-container .spectrum-Dialog--alert {
  width: 480px; }

.app-launcher-container .spectrum-Dialog--fullscreen {
  left: 32px;
  top: 32px;
  right: 32px;
  bottom: 32px;
  -ms-transform: translate(0, 20px);
      transform: translate(0, 20px); }

.app-launcher-container .spectrum-Dialog--fullscreen.is-open {
  -ms-transform: translate(0, 0);
      transform: translate(0, 0); }

.app-launcher-container .spectrum-Dialog--fullscreen, .app-launcher-container .spectrum-Dialog--fullscreenTakeover {
  width: auto;
  max-height: none;
  max-width: none;
  padding-top: 30px; }

.app-launcher-container .spectrum-Dialog--fullscreen .spectrum-Dialog-header, .app-launcher-container .spectrum-Dialog--fullscreenTakeover .spectrum-Dialog-header {
  -ms-flex-negative: 0;
  flex-shrink: 0; }

.app-launcher-container .spectrum-Dialog--fullscreen .spectrum-Dialog-title, .app-launcher-container .spectrum-Dialog--fullscreenTakeover .spectrum-Dialog-title {
  font-size: 28px;
  font-weight: 100; }

.app-launcher-container .spectrum-Dialog--fullscreen .spectrum-Dialog-content, .app-launcher-container .spectrum-Dialog--fullscreenTakeover .spectrum-Dialog-content {
  max-height: none; }

.app-launcher-container .spectrum-Dialog--fullscreenTakeover {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  box-sizing: border-box;
  border: none;
  border-radius: 0; }

.app-launcher-container .spectrum-Dialog--fullscreenTakeover, .app-launcher-container .spectrum-Dialog--fullscreenTakeover.is-open {
  -ms-transform: none;
      transform: none; }

.app-launcher-container .spectrum--light .spectrum-Dialog {
  background: #f5f5f5; }

.app-launcher-container .spectrum--light .spectrum-Dialog-header {
  background: #f5f5f5; }

.app-launcher-container .spectrum--light .spectrum-Dialog-header:after {
  background: #e1e1e1; }

.app-launcher-container .spectrum--light .spectrum-Dialog-title {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Dialog-content {
  color: #4b4b4b; }

.app-launcher-container .spectrum--light .spectrum-Dialog-typeIcon {
  color: #2c2c2c; }

.app-launcher-container .spectrum--light .spectrum-Dialog-footer {
  background: #f5f5f5; }

.app-launcher-container .spectrum--light .spectrum-Dialog--error .spectrum-Dialog-title {
  color: #c9252d; }

.app-launcher-container .spectrum--light .spectrum-Dialog--error .spectrum-Dialog-typeIcon {
  color: #c9252d; }

.app-launcher-container .spectrum--dark .spectrum-Dialog {
  background: #323232; }

.app-launcher-container .spectrum--dark .spectrum-Dialog-header {
  background: #323232; }

.app-launcher-container .spectrum--dark .spectrum-Dialog-header:after {
  background: #4a4a4a; }

.app-launcher-container .spectrum--dark .spectrum-Dialog-title {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Dialog-content {
  color: #e3e3e3; }

.app-launcher-container .spectrum--dark .spectrum-Dialog-typeIcon {
  color: #fff; }

.app-launcher-container .spectrum--dark .spectrum-Dialog-footer {
  background: #323232; }

.app-launcher-container .spectrum--dark .spectrum-Dialog--error .spectrum-Dialog-title {
  color: #f76d74; }

.app-launcher-container .spectrum--dark .spectrum-Dialog--error .spectrum-Dialog-typeIcon {
  color: #f76d74; }

.app-launcher-container .applauncher-provider {
  background-color: inherit; }

.app-launcher-container .app-launcher {
  border: 2px solid transparent;
  background-color: inherit;
  display: -ms-flexbox;
  display: flex; }

.app-launcher-container .app-launcher:focus {
  outline: 0; }

.app-launcher-container .app-launcher.focus-ring {
  border: 2px solid #2680eb !important;
  border-radius: 4px;
  outline: 0; }

@media screen and (max-width: 320px) {
  .app-launcher-container .app-launcher {
    display: none; } }

.app-launcher-container .app-launcher * {
  box-sizing: content-box; }

.app-launcher-container .app-launcher .app-launcher-icon {
  cursor: pointer;
  display: -ms-flexbox;
  display: flex; }

.app-launcher-container .app-launcher svg {
  vertical-align: middle; }

.app-launcher-container .app-launcher-overlay-container {
  height: 98vh;
  width: 100vw;
  position: absolute;
  left: 0;
  pointer-events: none;
  background: 0 0; }

.app-launcher-container .app-launcher-popover {
  padding: 0 !important;
  width: 21rem;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; }

@media screen and (max-width: 320px) {
  .app-launcher-container .app-launcher-popover {
    display: none; } }

.app-launcher-container .app-launcher-popover .spectrum-Dialog-content {
  padding: 0; }

.app-launcher-container .app-launcher-popover .un-apps-container ul {
  list-style: none;
  overflow: hidden;
  padding: 0;
  margin: 0 0 .0625rem 0; }

.app-launcher-container .app-launcher-popover .un-apps-container ul li {
  float: left;
  width: 33.333333%;
  height: 6.9375rem;
  display: table;
  text-align: center; }

.app-launcher-container .app-launcher-popover .un-apps-container ul li a {
  box-sizing: content-box;
  vertical-align: middle;
  display: inline-block;
  text-decoration: none;
  text-transform: capitalize;
  padding-top: 1.5rem;
  padding-bottom: 1.25rem;
  height: 4.688rem;
  width: 100%; }

.app-launcher-container .app-launcher-popover .un-apps-container ul li a img {
  width: 3rem;
  height: 3rem; }

.app-launcher-container .app-launcher-popover .un-apps-container ul li a span {
  display: block;
  line-height: 1.0625rem;
  margin-top: .25rem; }

.app-launcher-container .app-launcher-popover .more-apps {
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  font-weight: 700;
  padding: 1rem .3125rem;
  box-sizing: border-box;
  height: 3rem; }

.app-launcher-container .app-launcher-popover .apps-header {
  padding: .5rem .875rem;
  position: relative;
  text-align: center;
  font-weight: 700;
  font-size: .6875rem;
  line-height: .875rem;
  display: -ms-flexbox;
  display: flex; }

.app-launcher-container .app-launcher-popover .apps-header span {
  line-height: 14px;
  -ms-flex: 1;
      flex: 1; }

.app-launcher-container .app-launcher-popover .apps-header .info-icon {
  cursor: pointer;
  position: absolute;
  right: .875rem;
  top: .3125rem;
  border: 2px solid transparent; }

.app-launcher-container .app-launcher-popover .apps-header .info-icon .info-icon-svg {
  height: .875rem;
  width: .875rem; }

.app-launcher-container .app-launcher-popover .apps-header .info-icon:focus {
  outline: 0; }

.app-launcher-container .app-launcher-popover .apps-header .info-icon.focus-ring {
  border: 2px solid #2680eb !important;
  border-radius: 4px;
  outline: 0; }

.app-launcher-container .app-launcher-popover .info-container {
  padding: 0 3.25rem;
  box-sizing: border-box; }

.app-launcher-container .app-launcher-popover .info-container .header {
  font-size: 1.125rem;
  font-weight: 700;
  padding: 5rem 0 1.5rem;
  min-height: 2rem; }

.app-launcher-container .app-launcher-popover .info-container .footer {
  text-align: right;
  margin: 3rem 0; }

.app-launcher-container .app-launcher-popover .info-container .footer .confirm {
  box-sizing: border-box; }

.app-launcher-container .app-launcher-popover .un-more-apps > .header {
  font-size: .6875rem;
  font-weight: 700;
  text-align: center;
  padding: 1.5rem; }

.app-launcher-container .app-launcher-popover .un-more-apps .un-more-apps-list > div {
  padding: 0 2rem 1.125rem; }

.app-launcher-container .app-launcher-popover .un-more-apps .un-more-apps-info {
  float: left;
  line-height: normal;
  max-width: 11rem;
  overflow: hidden; }

.app-launcher-container .app-launcher-popover .un-more-apps .un-more-apps-info .header {
  font-size: .875rem; }

.app-launcher-container .app-launcher-popover .un-more-apps .un-more-apps-info .description {
  font-size: .625rem; }

.app-launcher-container .app-launcher-popover .un-more-apps .un-more-apps-btn {
  box-sizing: border-box;
  float: right;
  font-size: .75rem; }

.app-launcher-container .app-launcher-popover .un-more-apps .clearfix {
  clear: both; }

.app-launcher-container .app-launcher-popover .un-more-apps.un-apps-expanded-view .un-more-apps-info {
  width: 100%;
  max-width: none; }

.app-launcher-container .app-launcher-popover .un-more-apps.un-apps-expanded-view .un-more-apps-btn {
  float: none;
  margin-top: .5rem; }

.app-launcher-container .spectrum:lang(ja) .app-launcher-popover .un-apps-container ul li a, .app-launcher-container .spectrum:lang(ja) .app-launcher-popover .un-more-apps .un-more-apps-info .header, .app-launcher-container .spectrum:lang(ko) .app-launcher-popover .un-apps-container ul li a, .app-launcher-container .spectrum:lang(ko) .app-launcher-popover .un-more-apps .un-more-apps-info .header, .app-launcher-container .spectrum:lang(zh) .app-launcher-popover .un-apps-container ul li a, .app-launcher-container .spectrum:lang(zh) .app-launcher-popover .un-more-apps .un-more-apps-info .header {
  font-size: .8125rem; }

.app-launcher-container .spectrum:lang(ja) .app-launcher-popover .un-more-apps .un-more-apps-info .description, .app-launcher-container .spectrum:lang(ko) .app-launcher-popover .un-more-apps .un-more-apps-info .description, .app-launcher-container .spectrum:lang(zh) .app-launcher-popover .un-more-apps .un-more-apps-info .description {
  font-size: .5625rem; }

.app-launcher-container .app-launcher-ja_JP .info-container .content {
  font-size: .775rem; }

.app-launcher-container .spectrum-Popover-tip::after {
  -webkit-transform: rotate(45deg); }

.app-launcher-container .spectrum-Popover-tip::before {
  -webkit-transform: rotate(180deg); }

.app-launcher-container .app-launcher-dark .app-launcher-icon {
  color: #b3b3b3; }

.app-launcher-container .app-launcher-dark .app-launcher-icon:hover {
  color: #eaeaea; }

.app-launcher-container .app-launcher-dark.spectrum--dark {
  color: #d3d3d3; }

.app-launcher-container .app-launcher-dark .app-launcher-popover {
  background: #1d1d1d; }

.app-launcher-container .app-launcher-dark .app-launcher-popover .apps-header {
  box-shadow: 0 0 .0625rem 0 #313131;
  -webkit-box-shadow: 0 0 .0625rem 0 #313131;
  -moz-box-shadow: 0 0 .0625rem 0 #313131; }

.app-launcher-container .app-launcher-dark .app-launcher-popover .cloud-apps {
  box-shadow: 0 .0625rem .0625rem -.0625rem #313131;
  -webkit-box-shadow: 0 .0625rem .0625rem -.0625rem #313131;
  -moz-box-shadow: 0 .0625rem .0625rem -.0625rem #313131; }

.app-launcher-container .app-launcher-dark .app-launcher-popover .un-more-apps {
  box-shadow: 0 0 .0625rem 0 #313131;
  -webkit-box-shadow: 0 0 .0625rem 0 #313131;
  -moz-box-shadow: 0 0 .0625rem 0 #313131; }

.app-launcher-container .app-launcher-dark .app-launcher-popover .un-apps-container ul li:hover {
  background: #2a2a2a; }

.app-launcher-container .app-launcher-dark .app-launcher-popover .un-apps-container ul li:hover a {
  color: #fff; }

.app-launcher-container .app-launcher-dark .app-launcher-popover .un-apps-container ul li a {
  color: #d3d3d3; }

.app-launcher-container .app-launcher-dark .app-launcher-popover .un-apps-container ul li a:focus {
  background: #2a2a2a;
  color: #fff;
  outline: 0; }

.app-launcher-container .app-launcher-dark .app-launcher-popover .more-apps {
  background: #1d1d1d; }

.app-launcher-container .app-launcher-dark .app-launcher-popover .more-apps:hover {
  background: #2a2a2a; }

.app-launcher-container .app-launcher-dark .app-launcher-popover .more-apps:focus {
  background: #2a2a2a;
  outline: 0; }

.app-launcher-container .app-launcher-light .app-launcher-icon {
  color: #707070; }

.app-launcher-container .app-launcher-light .app-launcher-icon:hover {
  color: #2c2c2c; }

.app-launcher-container .app-launcher-light.spectrum--light {
  color: #505050; }

.app-launcher-container .app-launcher-light .app-launcher-popover {
  background: #fff; }

.app-launcher-container .app-launcher-light .app-launcher-popover .apps-header {
  box-shadow: 0 0 .0625rem 0 #eaeaea;
  -webkit-box-shadow: 0 0 .0625rem 0 #eaeaea;
  -moz-box-shadow: 0 0 .0625rem 0 #eaeaea; }

.app-launcher-container .app-launcher-light .app-launcher-popover .cloud-apps {
  box-shadow: 0 .0625rem .0625rem -.0625rem #eaeaea;
  -webkit-box-shadow: 0 .0625rem .0625rem -.0625rem #eaeaea;
  -moz-box-shadow: 0 .0625rem .0625rem -.0625rem #eaeaea; }

.app-launcher-container .app-launcher-light .app-launcher-popover .un-more-apps {
  box-shadow: 0 0 .0625rem 0 #eaeaea;
  -webkit-box-shadow: 0 0 .0625rem 0 #eaeaea;
  -moz-box-shadow: 0 0 .0625rem 0 #eaeaea; }

.app-launcher-container .app-launcher-light .app-launcher-popover .un-apps-container ul li:hover {
  background: #f4f4f4; }

.app-launcher-container .app-launcher-light .app-launcher-popover .un-apps-container ul li:hover a {
  color: #000; }

.app-launcher-container .app-launcher-light .app-launcher-popover .un-apps-container ul li a {
  color: #505050; }

.app-launcher-container .app-launcher-light .app-launcher-popover .un-apps-container ul li a:focus {
  background: #f4f4f4;
  color: #000;
  outline: 0; }

.app-launcher-container .app-launcher-light .app-launcher-popover .more-apps {
  background: #fff; }

.app-launcher-container .app-launcher-light .app-launcher-popover .more-apps:hover {
  background: #f4f4f4; }

.app-launcher-container .app-launcher-light .app-launcher-popover .more-apps:focus {
  background: #f4f4f4;
  outline: 0; }

body > header {
  /*margin-top: 7rem;*/
  z-index: 1;
  width: 100%;
  background: transparent url("../images/paypal/banner-image.jpg") no-repeat right top;
  background-size: cover; }
  body > header .container {
    width: 100%;
    /*background: #fe0000;*/
    max-width: 1095px;
    margin: 0 auto;
    padding-top: 4rem; }
    body > header .container .headerTxt {
      text-align: left;
      width: 44%;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
          justify-content: space-between;
      /*margin:0 auto;*/
      max-width: 1200px;
      /*background:transparent url('../images/adobe/header-image.jpg') no-repeat right bottom;*/
      background: #00308c;
      background: linear-gradient(90deg, rgba(0, 48, 140, 0.89) 0%, rgba(0, 94, 192, 0.89) 80%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00308c",endColorstr="#005ec0",GradientType=1); }
      body > header .container .headerTxt .copy {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
            flex-direction: column;
        -ms-flex-pack: center;
            justify-content: center; }
      body > header .container .headerTxt h1 {
        text-align: left;
        font-size: 40px;
        line-height: 42px;
        font-weight: 400;
        padding-bottom: 1rem;
        color: white;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
            flex-direction: column;
        -ms-flex-pack: center;
            justify-content: center;
        font-family: 'Paypal Big Regular';
        font-style: normal;
        width: 85%;
        padding: 2.5rem 2.5rem 0.5rem; }
        @media screen and (max-width: 900px) {
          body > header .container .headerTxt h1 {
            font-size: 30px;
            line-height: 32px; } }
        @media screen and (max-width: 400px) {
          body > header .container .headerTxt h1 {
            font-size: 25px;
            line-height: 25px; } }
      body > header .container .headerTxt p {
        color: white;
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        padding: 0rem 2.5rem 0.5rem; }
      body > header .container .headerTxt ul {
        list-style-position: inside;
        list-style-type: none;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
            justify-content: space-between;
        -ms-flex-align: center;
            align-items: center;
        padding: 3rem 2.5rem 0.5rem; }
        body > header .container .headerTxt ul li {
          color: white;
          font-size: 18px;
          font-weight: bold;
          line-height: 28px;
          padding-bottom: 0.25rem;
          margin-right: 3.5rem; }
          body > header .container .headerTxt ul li:last-of-type {
            margin-right: 0; }
          body > header .container .headerTxt ul li img {
            width: 100%;
            height: auto;
            opacity: 1; }
      body > header .container .headerTxt a.button {
        background-color: #1473E6;
        color: white;
        -ms-flex-item-align: start;
            align-self: flex-start;
        text-decoration: none;
        text-align: center;
        font-size: 22px;
        padding: 5px 45px 7px;
        border-radius: 20px;
        margin-top: 4rem; }
      body > header .container .headerTxt img {
        width: 45%;
        max-width: 540px;
        height: 38rem;
        opacity: 0; }
        @media screen and (min-width: 1650px) {
          body > header .container .headerTxt img {
            height: 38rem; } }
        @media screen and (max-width: 1300px) {
          body > header .container .headerTxt img {
            height: 37rem; } }
        @media screen and (max-width: 1200px) {
          body > header .container .headerTxt img {
            height: 34rem; } }
        @media screen and (max-width: 1000px) {
          body > header .container .headerTxt img {
            display: none; } }
        @media screen and (max-width: 640px) {
          body > header .container .headerTxt img {
            -ms-flex-order: 1;
                order: 1;
            width: 100%;
            max-width: 100%; } }
      @media screen and (max-width: 767px) {
        body > header .container .headerTxt {
          width: 75%; } }
      @media screen and (max-width: 510px) {
        body > header .container .headerTxt {
          width: 90%; } }
    @media screen and (max-width: 1100px) {
      body > header .container {
        width: 95%; } }

.intro {
  padding: 5rem 0;
  background: #ffffff; }
  .intro .container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    width: 100%;
    max-width: 1140px;
    margin: 0 auto; }
    .intro .container p {
      width: 100%;
      max-width: 1000px;
      margin: 0 auto;
      font-size: 20px;
      line-height: 30px;
      text-align: center; }
  @media screen and (max-width: 1140px) {
    .intro .container {
      padding: 0 1rem; } }
  @media screen and (max-width: 640px) {
    .intro .container {
      padding: 0 2.5rem; } }

.two-columns {
  padding: 0rem 0;
  background-color: #D8D8D8; }
  .two-columns.rightToLeft .container {
    -ms-flex-direction: row-reverse;
        flex-direction: row-reverse; }
    .two-columns.rightToLeft .container .right {
      padding: 0; }
      .two-columns.rightToLeft .container .right .title {
        width: 95%;
        max-width: 570px;
        margin: 0 0 0 auto;
        padding-right: 4rem; }
        @media screen and (max-width: 950px) {
          .two-columns.rightToLeft .container .right .title {
            margin: 0;
            padding-right: 0;
            max-width: 100%;
            width: 100%; }
            .two-columns.rightToLeft .container .right .title br {
              display: none; } }
      .two-columns.rightToLeft .container .right .copy {
        width: 95%;
        max-width: 570px;
        margin: 0 0 0 auto;
        padding-right: 4rem; }
        @media screen and (max-width: 950px) {
          .two-columns.rightToLeft .container .right .copy {
            margin: 0;
            padding-right: 0;
            max-width: 100%;
            width: 100%; } }
      .two-columns.rightToLeft .container .right .buttonWrap {
        width: 95%;
        max-width: 570px;
        margin: 0 0 0 auto;
        padding-right: 4rem; }
        .two-columns.rightToLeft .container .right .buttonWrap a {
          display: inline-block; }
        @media screen and (max-width: 950px) {
          .two-columns.rightToLeft .container .right .buttonWrap {
            margin: 0;
            padding-right: 0;
            max-width: 100%;
            width: 100%; } }
      @media screen and (max-width: 1024px) {
        .two-columns.rightToLeft .container .right {
          /*padding: 0 4rem 0 3rem;*/ } }
      @media screen and (max-width: 950px) {
        .two-columns.rightToLeft .container .right {
          padding: 4rem; } }
      @media screen and (max-width: 640px) {
        .two-columns.rightToLeft .container .right {
          padding: 2.5rem; } }
    @media screen and (max-width: 950px) {
      .two-columns.rightToLeft .container {
        -ms-flex-direction: column;
            flex-direction: column; } }
  .two-columns.video .container .left .play {
    display: block; }
  .two-columns .container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto; }
    .two-columns .container .left {
      width: 50%;
      position: relative;
      background-size: cover;
      background-color: #D8D8D8; }
      .two-columns .container .left .image {
        visibility: hidden;
        width: 100%; }
      .two-columns .container .left .play {
        display: none;
        position: absolute;
        max-width: 85px;
        left: 50%;
        top: 50%;
        -ms-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%); }
      @media screen and (max-width: 950px) {
        .two-columns .container .left {
          width: 100%; } }
    .two-columns .container .right {
      width: 50%;
      background: #1E2323;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-pack: center;
          justify-content: center;
      padding: 0 7rem 0 3rem; }
      .two-columns .container .right .title {
        font-weight: 700;
        font-size: 20px;
        padding-bottom: 3rem;
        color: white;
        text-align: left; }
      .two-columns .container .right .copy {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: white;
        text-align: left;
        font-style: normal;
        padding-bottom: 3rem; }
      .two-columns .container .right .buttonWrap {
        -ms-flex-item-align: start;
            align-self: flex-start; }
        .two-columns .container .right .buttonWrap a {
          font-size: 15px;
          font-weight: 700;
          /*letter-spacing: 2px;*/
          color: #ee3882;
          display: block;
          text-align: center;
          background: #ffffff;
          padding: 12px 49px 12px 49px;
          border-radius: 10px;
          text-transform: none;
          text-decoration: none;
          transition: all 0.3s ease;
          /*@media screen and (max-width: 640px) {
        width: 70%;
        padding: 1rem 0;
        text-align: center;
    }*/
          background: #ee3882;
          color: #ffffff;
          -ms-flex-item-align: start;
              align-self: flex-start; }
          .two-columns .container .right .buttonWrap a:hover {
            background: #ee3882;
            color: #ffffff;
            transition: all 0.3s ease; }
          .two-columns .container .right .buttonWrap a:hover {
            background: #ffffff;
            color: #ee3882; }
      @media screen and (max-width: 1024px) {
        .two-columns .container .right {
          padding: 0 3rem 0 3rem; } }
      @media screen and (max-width: 950px) {
        .two-columns .container .right {
          width: 100%;
          padding: 4rem 4rem; } }
      @media screen and (max-width: 640px) {
        .two-columns .container .right {
          padding: 2.5rem; } }
    @media screen and (max-width: 950px) {
      .two-columns .container {
        -ms-flex-direction: column;
            flex-direction: column; } }
  .two-columns .modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: none;
    background: rgba(0, 0, 0, 0.85);
    padding-top: 3rem;
    z-index: 2000; }
    .two-columns .modal.active {
      display: block; }
    .two-columns .modal .close {
      position: absolute;
      width: 40px;
      height: 40px;
      top: 20px;
      right: 20px; }
      .two-columns .modal .close img {
        width: 100%; }
    .two-columns .modal .container {
      width: 90%;
      /*padding-top: 26.25%;*/
      max-width: 700px;
      position: relative; }
      .two-columns .modal .container video {
        /*position: absolute;*/
        width: 100%;
        /*height: 100%;*/
        /*top: 0;*/
        /*left: 0;*/ }
      .two-columns .modal .container .video-js {
        width: 100%;
        padding-top: 56.25%; }
      .two-columns .modal .container .vjs-big-play-button {
        left: 50%;
        top: 50%;
        -ms-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%); }

.vjs-wavesurfer.vjs-fluid wave.vjs-wavedisplay {
  width: 100% !important;
  height: 0 !important;
  padding-top: 56.25%; }

.vjs-wavesurfer.vjs-fluid wave.vjs-wavedisplay wave {
  /*width: 100%!important;*/
  height: 0 !important;
  padding-top: 56.25%; }

.vjs-wavesurfer.vjs-fluid wave.vjs-wavedisplay canvas {
  /*width: 100%!important;*/ }

.feds-header-wrapper {
  z-index: 2001 !important; }

.slick-prev {
  position: absolute;
  left: -0px;
  bottom: 50%;
  -ms-transform: translateY(50%) translateX(-100%);
      transform: translateY(50%) translateX(-100%);
  z-index: 10;
  background: transparent url("../images/adobe/prev-white.png") no-repeat center;
  background-size: 100%;
  width: 45px;
  height: 51px;
  text-indent: -9999px;
  transition: all 0.3s ease; }
  .slick-prev.slick-disabled {
    opacity: 0.35; }
  @media screen and (max-width: 1270px) {
    .slick-prev {
      -ms-transform: translateY(50%) translateX(-10%);
          transform: translateY(50%) translateX(-10%);
      width: 35px; } }
  @media screen and (max-width: 767px) {
    .slick-prev {
      /*bottom: 18%;*/
      -ms-transform: translateY(50%) translateX(10%);
          transform: translateY(50%) translateX(10%); } }
  @media screen and (max-width: 600px) {
    .slick-prev {
      width: 35px;
      height: 41px; } }
  .slick-prev:hover {
    -ms-transform: translateY(50%) translateX(-107%);
        transform: translateY(50%) translateX(-107%);
    transition: all 0.3s ease; }
    @media screen and (max-width: 1270px) {
      .slick-prev:hover {
        -ms-transform: translateY(50%) translateX(-17%);
            transform: translateY(50%) translateX(-17%); } }
    @media screen and (max-width: 767px) {
      .slick-prev:hover {
        -ms-transform: translateY(50%) translateX(3%);
            transform: translateY(50%) translateX(3%); } }

.slick-next {
  position: absolute;
  right: 0%;
  bottom: 50%;
  -ms-transform: translateY(50%) translateX(100%);
      transform: translateY(50%) translateX(100%);
  z-index: 10;
  background: transparent url("../images/adobe/next-white.png") no-repeat center;
  background-size: 100%;
  width: 45px;
  height: 51px;
  text-indent: -9999px;
  transition: all 0.3s ease; }
  .slick-next.slick-disabled {
    opacity: 0.35; }
  @media screen and (max-width: 1270px) {
    .slick-next {
      -ms-transform: translateY(50%) translateX(10%);
          transform: translateY(50%) translateX(10%);
      width: 35px; } }
  @media screen and (max-width: 1200px) {
    .slick-next {
      /*right: 10px;*/ } }
  @media screen and (max-width: 767px) {
    .slick-next {
      -ms-transform: translateY(50%) translateX(-10%);
          transform: translateY(50%) translateX(-10%); } }
  @media screen and (max-width: 600px) {
    .slick-next {
      width: 35px;
      height: 41px; } }
  .slick-next:hover {
    -ms-transform: translateY(50%) translateX(107%);
        transform: translateY(50%) translateX(107%);
    transition: all 0.3s ease; }
    @media screen and (max-width: 1270px) {
      .slick-next:hover {
        -ms-transform: translateY(50%) translateX(17%);
            transform: translateY(50%) translateX(17%); } }
    @media screen and (max-width: 767px) {
      .slick-next:hover {
        -ms-transform: translateY(50%) translateX(-3%);
            transform: translateY(50%) translateX(-3%); } }

.modals {
  z-index: 2002;
  position: relative; }

.modal {
  position: fixed;
  z-index: 200;
  background: rgba(0, 0, 0, 0.85);
  height: 100vh;
  width: 100vw;
  transition: all 0.4s ease;
  -ms-transform: scale(0);
      transform: scale(0);
  opacity: 1;
  z-index: 10;
  -ms-transform-origin: top left;
      transform-origin: top left;
  border-radius: 50vw; }
  .modal.active {
    -ms-transform: scale(1);
        transform: scale(1);
    opacity: 1;
    transition: all 0.4s ease;
    -ms-transform-origin: top left;
        transform-origin: top left;
    border-radius: 0; }
  .modal .close {
    position: absolute;
    right: 30px;
    top: 30px;
    text-decoration: none;
    color: #ffffff;
    border: 1px solid #ffffff;
    width: 35px;
    height: 35px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    border-radius: 50px; }
  .modal .container {
    max-width: 650px;
    margin: 0 auto;
    margin-top: 3rem;
    width: 90%; }
    .modal .container .embed-container {
      width: 100%; }
      .modal .container .embed-container .videoObject {
        width: 100%;
        height: auto;
        padding-top: 56.25%; }
        .modal .container .embed-container .videoObject .vjs-big-play-button {
          left: 50%;
          top: 50%;
          -ms-transform: translateX(-50%) translateY(-50%);
              transform: translateX(-50%) translateY(-50%); }
        .modal .container .embed-container .videoObject .vjs-control-bar {
          z-index: 100; }
      .modal .container .embed-container .podcast {
        background-color: #fe0000; }
      .modal .container .embed-container video {
        width: 100%; }
      .modal .container .embed-container canvas {
        -ms-transform: scale(1);
            transform: scale(1);
        /*width: 100%!important;*/ }

.videos {
  z-index: 2000;
  position: relative; }
  .videos.section2 {
    background: #efefef; }
    .videos.section2 .container .fade {
      background: #efefef;
      /*background: linear-gradient(90deg, rgba(239,239,239,0) 0%, rgba(239,239,239,1) 50%);*/
      position: absolute;
      right: -20%;
      top: 0;
      display: block;
      height: 100%;
      width: 22%;
      z-index: 1;
      display: none; }
    .videos.section2 .container .video-slider .slick-track .slick-slide:first-of-type .video h3 {
      color: white; }
    .videos.section2 .container .video-slider .slick-track .slick-slide:last-of-type .video h3 {
      color: #323232; }
  .videos.section3 .container .video-slider .slick-track .slick-slide:first-of-type .video h3 {
    color: #ffffff; }
  .videos .container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    -ms-flex-direction: column;
        flex-direction: column;
    padding: 3rem 0 1.75rem;
    position: relative; }
    .videos .container h2 {
      margin-left: 10px;
      font-size: 36px;
      z-index: 2;
      position: relative;
      color: #2C2C2C; }
      @media screen and (max-width: 1270px) {
        .videos .container h2 {
          width: 93%;
          margin: 0 auto; } }
      @media screen and (max-width: 1200px) {
        .videos .container h2 {
          padding-right: 10px; } }
      @media screen and (max-width: 767px) {
        .videos .container h2 {
          width: 83%;
          margin: 0 auto; } }
    .videos .container p {
      width: 100%;
      max-width: 1000px;
      margin: 0 auto;
      font-size: 18px;
      line-height: 30px;
      text-align: left;
      margin-left: 10px;
      z-index: 2;
      position: relative; }
      @media screen and (max-width: 1270px) {
        .videos .container p {
          width: 93%;
          margin: 0 auto; } }
      @media screen and (max-width: 1200px) {
        .videos .container p {
          padding-right: 10px; } }
      @media screen and (max-width: 767px) {
        .videos .container p {
          width: 83%;
          margin: 0 auto; } }
    .videos .container .fade {
      background: white;
      /*background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%);*/
      position: absolute;
      right: -30%;
      top: 0;
      display: block;
      height: 100%;
      width: 29.4%;
      z-index: 1;
      /*display: none;*/ }
      @media screen and (max-width: 1270px) {
        .videos .container .fade {
          width: 32%; } }
      @media screen and (max-width: 767px) {
        .videos .container .fade {
          display: none; } }
    .videos .container .video-slider {
      width: 100%;
      padding: 2rem 0; }
      .videos .container .video-slider .slick-list {
        /*width: 80%;*/
        overflow: visible; }
        @media screen and (max-width: 1270px) {
          .videos .container .video-slider .slick-list {
            width: 95%;
            margin: 0 auto; } }
        @media screen and (max-width: 767px) {
          .videos .container .video-slider .slick-list {
            width: 85%;
            overflow: hidden;
            padding: 0 0 !important;
            margin: 0 auto; } }
      .videos .container .video-slider .slick-track {
        /*width: 100%!important;*/
        float: left;
        /*&:after {
					background: rgb(255,255,255);
					background: linear-gradient(90deg, rgba(255,255,255,0) 18%, rgba(255,255,255,1) 76%);
					height: 130%;
					width: 50%;
					position: absolute;
					right:-10%;
					top: -15%;
				}*/ }
        .videos .container .video-slider .slick-track .slick-slide {
          margin: 10px;
          -ms-transform: scale(1);
              transform: scale(1);
          transition: all 0.4s ease; }
          .videos .container .video-slider .slick-track .slick-slide.active {
            transition: all 0.4s ease;
            -ms-transform: scale(1.05);
                transform: scale(1.05);
            -ms-transform-origin: center;
                transform-origin: center;
            z-index: 100;
            position: relative; }
            .videos .container .video-slider .slick-track .slick-slide.active .video.podcast .screenshot {
              opacity: 1; }
            .videos .container .video-slider .slick-track .slick-slide.active .video.gated .screenshot {
              opacity: 1; }
            .videos .container .video-slider .slick-track .slick-slide.active .video.gated .play {
              width: 15px;
              height: 19px;
              background: transparent url("../images/adobe/play.svg") no-repeat center center;
              background-size: 100%;
              position: absolute;
              right: 13px;
              bottom: 10px;
              z-index: 100;
              display: block; }
              .videos .container .video-slider .slick-track .slick-slide.active .video.gated .play.pause {
                background: transparent url("../images/adobe/play.svg") no-repeat center center;
                display: block; }
            .videos .container .video-slider .slick-track .slick-slide.active .video video {
              opacity: 1;
              transition: all 0.3s ease; }
            .videos .container .video-slider .slick-track .slick-slide.active .asset .screenshot {
              opacity: 1; }
            .videos .container .video-slider .slick-track .slick-slide.active .screenshot {
              transition: all 0.8s ease;
              opacity: 0; }
            .videos .container .video-slider .slick-track .slick-slide.active h3 {
              padding-left: 20px;
              transition: all 0.8s ease;
              /*color: #323232;*/ }
            .videos .container .video-slider .slick-track .slick-slide.active .play {
              display: block; }
            .videos .container .video-slider .slick-track .slick-slide.active .unmute {
              display: block; }
            .videos .container .video-slider .slick-track .slick-slide.active .enlarge {
              display: block; }
            .videos .container .video-slider .slick-track .slick-slide.active:last-of-type h3 {
              padding-left: 10px;
              transition: all 0.8s ease; }
            .videos .container .video-slider .slick-track .slick-slide.active:last-of-type .type {
              padding-right: 20px;
              transition: all 0.8s ease; }
          .videos .container .video-slider .slick-track .slick-slide:last-of-type .video h3 {
            color: white; }
          .videos .container .video-slider .slick-track .slick-slide:first-of-type .video h3 {
            color: #323232; }
      .videos .container .video-slider .video {
        cursor: pointer;
        transition: all 0.4s ease;
        -ms-transform: scale(1);
            transform: scale(1);
        -ms-transform-origin: center;
            transform-origin: center;
        background: #cccccc;
        height: 100%;
        /*&.active {
					transition: all 0.4s ease;
					transform: scale(1.1);
					transform-origin: center;
					.screenshot {
						transition: all 0.8s ease;
						opacity: 0;
					}
					h3 {
						padding-left: 20px;
						transition: all 0.8s ease;
					}
				}*/ }
        .videos .container .video-slider .video a {
          display: block; }
        .videos .container .video-slider .video .modal2 {
          width: 100vw;
          height: 100vh;
          background: rgba(0, 0, 0, 0.85);
          position: fixed;
          left: 0;
          top: 0;
          display: none;
          z-index: 3000; }
        .videos .container .video-slider .video.asset .screenshot {
          position: relative;
          width: 100%;
          height: auto; }
        .videos .container .video-slider .video.podcast .screenshot {
          position: relative;
          width: 100%;
          height: 100%; }
        .videos .container .video-slider .video video {
          width: 100%;
          display: block;
          z-index: 1;
          opacity: 0;
          transition: all 0.3s ease; }
        .videos .container .video-slider .video .type {
          background: #000000;
          position: absolute;
          right: 0;
          top: 10px;
          font-size: 12px;
          padding: 3px 10px;
          z-index: 3;
          color: white;
          transition: all 0.8s ease; }
        .videos .container .video-slider .video h3 {
          position: absolute;
          left: 0;
          bottom: 10px;
          font-size: 14px;
          padding: 3px 10px 3px 20px;
          z-index: 3;
          color: #323232;
          color: #ffffff;
          transition: all 0.8s ease; }
        .videos .container .video-slider .video .screenshot {
          position: absolute;
          left: 0;
          top: 0;
          /*width: 100%;*/
          height: 100%;
          display: block;
          transition: all 0.8s ease;
          opacity: 1;
          z-index: 2; }
        .videos .container .video-slider .video .videoObject {
          width: 100%;
          height: auto; }
        .videos .container .video-slider .video .play {
          width: 15px;
          height: 19px;
          background: transparent url("../images/adobe/play.svg") no-repeat center center;
          background-size: 100%;
          position: absolute;
          right: 13px;
          bottom: 10px;
          z-index: 100;
          display: none; }
          .videos .container .video-slider .video .play.pause {
            background: transparent url("../images/adobe/pause.svg") no-repeat center center; }
        .videos .container .video-slider .video .unmute {
          width: 20px;
          height: 25px;
          background: transparent url("../images/adobe/unmute.svg") no-repeat center center;
          background-size: 100%;
          position: absolute;
          right: 40px;
          bottom: 8px;
          z-index: 100;
          display: none; }
          .videos .container .video-slider .video .unmute.mute {
            background: transparent url("../images/adobe/mute.svg") no-repeat center center; }
        .videos .container .video-slider .video .enlarge {
          width: 17px;
          height: 17px;
          background: transparent url("../images/adobe/enlarge.svg") no-repeat center center;
          background-size: 100%;
          position: absolute;
          right: 70px;
          bottom: 10px;
          z-index: 100;
          display: none; }
  @media screen and (max-width: 1140px) {
    .videos .container {
      /*padding:0 1rem;*/ } }
  @media screen and (max-width: 640px) {
    .videos .container {
      /*padding:0 2.5rem;*/ } }

.downloads {
  z-index: 2000;
  position: relative;
  background: #ffffff; }
  .downloads.section2 {
    background: #efefef; }
    .downloads.section2 .container .download-slider .slick-track .slick-slide h3 {
      color: #323232; }
    .downloads.section2 .container .download-slider .slick-track .slick-slide:nth-child(3) h3 {
      color: #ffffff; }
    .downloads.section2 .container .download-slider .slick-track .slick-slide:last-of-type h3 {
      color: #ffffff; }
  .downloads.section3 {
    background: #ffffff; }
    .downloads.section3 .container .download-slider .slick-track .slick-slide h3 {
      color: #323232; }
    .downloads.section3 .container .download-slider .slick-track .slick-slide:nth-child(2) h3 {
      color: #ffffff; }
    .downloads.section3 .container .download-slider .slick-track .slick-slide:last-of-type h3 {
      color: #323232; }
  .downloads .container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    -ms-flex-direction: column;
        flex-direction: column;
    padding: 3rem 0;
    padding-top: 0;
    position: relative; }
    .downloads .container h2 {
      margin-left: 10px;
      font-size: 36px; }
    .downloads .container p {
      width: 100%;
      max-width: 1000px;
      margin: 0 auto;
      font-size: 18px;
      line-height: 30px;
      text-align: left;
      margin-left: 10px; }
    .downloads .container .modal {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 200;
      background: rgba(0, 0, 0, 0.85);
      height: 100vh;
      width: 100vw;
      transition: all 0.4s ease;
      -ms-transform: scale(1) translateY(-101%);
          transform: scale(1) translateY(-101%);
      opacity: 1;
      z-index: 10; }
      .downloads .container .modal.active {
        -ms-transform: scale(1) translateY(0%);
            transform: scale(1) translateY(0%);
        opacity: 1;
        transition: all 0.4s ease; }
      .downloads .container .modal .close {
        position: absolute;
        right: 30px;
        top: 30px;
        text-decoration: none;
        color: #ffffff;
        border: 1px solid #ffffff;
        width: 35px;
        height: 35px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
            justify-content: center;
        -ms-flex-align: center;
            align-items: center;
        border-radius: 50px; }
      .downloads .container .modal .container {
        max-width: 650px;
        margin: 0 auto;
        margin-top: 3rem;
        width: 90%; }
        .downloads .container .modal .container .embed-container {
          width: 100%; }
          .downloads .container .modal .container .embed-container .videoObject {
            width: 100%;
            height: auto;
            padding-top: 56.25%; }
            .downloads .container .modal .container .embed-container .videoObject .vjs-big-play-button {
              left: 50%;
              top: 50%;
              -ms-transform: translateX(-50%) translateY(-50%);
                  transform: translateX(-50%) translateY(-50%); }
            .downloads .container .modal .container .embed-container .videoObject .vjs-control-bar {
              z-index: 100; }
          .downloads .container .modal .container .embed-container .podcast {
            background-color: #F26868; }
          .downloads .container .modal .container .embed-container video {
            width: 100%; }
          .downloads .container .modal .container .embed-container canvas {
            -ms-transform: scale(1);
                transform: scale(1);
            /*width: 100%!important;*/ }
    .downloads .container .fade {
      background: #efefef;
      background: linear-gradient(90deg, rgba(239, 239, 239, 0.95) 10%, #efefef 25%);
      position: absolute;
      right: -20%;
      top: 0;
      display: block;
      height: 100%;
      width: 42%;
      z-index: 2;
      display: none; }
    .downloads .container .download-slider {
      width: 100%;
      padding: 2rem 0;
      padding-top: 0; }
      @media screen and (max-width: 767px) {
        .downloads .container .download-slider {
          padding-bottom: 0; } }
      .downloads .container .download-slider .slick-list {
        width: 100%;
        overflow: visible;
        margin: 0 auto; }
        @media screen and (max-width: 1270px) {
          .downloads .container .download-slider .slick-list {
            width: 95%; } }
        @media screen and (max-width: 767px) {
          .downloads .container .download-slider .slick-list {
            width: 85%;
            overflow: hidden;
            margin: 0 auto; } }
      .downloads .container .download-slider .slick-track {
        /*width: 100%!important;*/
        float: left;
        /*&:after {
					background: rgb(255,255,255);
					background: linear-gradient(90deg, rgba(255,255,255,0) 18%, rgba(255,255,255,1) 76%);
					height: 130%;
					width: 50%;
					position: absolute;
					right:-10%;
					top: -15%;
				}*/ }
        .downloads .container .download-slider .slick-track .slick-slide {
          margin: 10px;
          -ms-transform: scale(1);
              transform: scale(1);
          transition: all 0.4s ease; }
          .downloads .container .download-slider .slick-track .slick-slide.active {
            transition: all 0.4s ease;
            -ms-transform: scale(1.1);
                transform: scale(1.1);
            -ms-transform-origin: center;
                transform-origin: center; }
            .downloads .container .download-slider .slick-track .slick-slide.active .video.podcast .screenshot {
              opacity: 1; }
            .downloads .container .download-slider .slick-track .slick-slide.active .screenshot {
              transition: all 0.8s ease;
              opacity: 1; }
            .downloads .container .download-slider .slick-track .slick-slide.active h3 {
              padding-left: 20px;
              transition: all 0.8s ease; }
            .downloads .container .download-slider .slick-track .slick-slide.active:last-of-type h3 {
              padding-left: 10px;
              transition: all 0.8s ease; }
            .downloads .container .download-slider .slick-track .slick-slide.active:last-of-type .type {
              padding-right: 20px;
              transition: all 0.8s ease; }
          .downloads .container .download-slider .slick-track .slick-slide:first-of-type h3 {
            color: #ffffff; }
          .downloads .container .download-slider .slick-track .slick-slide:nth-child(2) h3 {
            color: #323232; }
          .downloads .container .download-slider .slick-track .slick-slide:last-of-type h3 {
            color: #ffffff; }
      .downloads .container .download-slider .download {
        cursor: pointer;
        transition: all 0.4s ease;
        -ms-transform: scale(1);
            transform: scale(1);
        -ms-transform-origin: center;
            transform-origin: center;
        background: #cccccc;
        height: 100%; }
        .downloads .container .download-slider .download a {
          display: block; }
        .downloads .container .download-slider .download.active {
          transition: all 0.4s ease;
          -ms-transform: scale(1.1);
              transform: scale(1.1);
          -ms-transform-origin: center;
              transform-origin: center;
          /*h3 {
						padding-left: 20px;
						transition: all 0.8s ease;
					}*/ }
          .downloads .container .download-slider .download.active .screenshot {
            transition: all 0.8s ease;
            opacity: 1; }
        .downloads .container .download-slider .download.podcast .screenshot {
          position: relative;
          width: 100%;
          height: 100%; }
        .downloads .container .download-slider .download video {
          width: 100%;
          display: block;
          z-index: 1; }
        .downloads .container .download-slider .download .type {
          background: #000000;
          position: absolute;
          right: 0;
          top: 10px;
          font-size: 12px;
          padding: 3px 10px;
          z-index: 3;
          color: white;
          transition: all 0.8s ease; }
        .downloads .container .download-slider .download h3 {
          position: absolute;
          left: 0;
          bottom: 10px;
          font-size: 14px;
          padding: 3px 10px 3px 20px;
          z-index: 3;
          color: #323232;
          transition: all 0.8s ease; }
        .downloads .container .download-slider .download .screenshot {
          position: relative;
          left: 0;
          top: 0;
          /*width: 100%;*/
          width: 100%;
          display: block;
          transition: all 0.8s ease;
          opacity: 1;
          z-index: 2; }
        .downloads .container .download-slider .download .videoObject {
          width: 100%;
          height: auto; }
  @media screen and (max-width: 1140px) {
    .downloads .container {
      /*padding:0 1rem;*/ } }
  @media screen and (max-width: 640px) {
    .downloads .container {
      /*padding:0 2.5rem;*/ } }

.CMform {
  z-index: 2; }
  .CMform .container {
    max-width: 1095px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    margin: 0 auto; }
    @media screen and (max-width: 1100px) {
      .CMform .container {
        width: 95%; } }
    @media screen and (max-width: 767px) {
      .CMform .container {
        -ms-flex-direction: column;
            flex-direction: column; } }
    .CMform .container .left {
      padding-top: 2.5rem;
      width: 48%; }
      .CMform .container .left p {
        font-size: 18px;
        color: #3A3A3A;
        margin-bottom: 1rem; }
      .CMform .container .left .itinery {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: start;
            justify-content: flex-start;
        margin-bottom: 1.75rem; }
        .CMform .container .left .itinery .time {
          font-size: 18px;
          color: #3A3A3A;
          margin-right: 1rem;
          min-width: 80px; }
        .CMform .container .left .itinery .item {
          font-size: 18px;
          color: #3A3A3A; }
      @media screen and (max-width: 767px) {
        .CMform .container .left {
          width: 95%;
          margin: 0 auto; } }
    .CMform .container .right {
      padding: 2.5rem;
      padding-top: 4rem;
      background: white;
      width: 43%;
      margin-top: -22rem;
      box-shadow: 0px 5px 13px 5px rgba(0, 0, 0, 0.3);
      border-radius: 6px; }
      @media screen and (max-width: 767px) {
        .CMform .container .right {
          margin-top: 0; } }
      .CMform .container .right h1 {
        color: #3A3A3A !important;
        font-family: 'Paypal Big Regular';
        font-size: 17px;
        font-weight: normal;
        margin-bottom: 1rem; }
      .CMform .container .right form fieldset {
        border: 0;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        margin-top: 1.25rem;
        margin-bottom: 1.25rem; }
        .CMform .container .right form fieldset > label {
          width: 100%;
          margin-bottom: 0.5rem; }
        .CMform .container .right form fieldset .checkbox {
          -ms-flex-item-align: start;
              align-self: flex-start;
          width: 40%; }
        .CMform .container .right form fieldset:last-child .checkbox {
          width: 100%; }
          .CMform .container .right form fieldset:last-child .checkbox input {
            width: 20px;
            -ms-flex-item-align: start;
                align-self: flex-start; }
          .CMform .container .right form fieldset:last-child .checkbox label {
            width: 95%; }
      .CMform .container .right form div div label {
        display: none; }
      .CMform .container .right form div div input {
        border: 1px solid #979797;
        border-radius: 6px;
        width: 100%;
        padding: 1rem 0;
        padding-left: 1.25rem;
        margin-bottom: 0.75rem; }
        .CMform .container .right form div div input[type="checkbox"] {
          width: 20px;
          height: 20px;
          padding: 0; }
          .CMform .container .right form div div input[type="checkbox"]:checked {
            background: #0070BA; }
      .CMform .container .right form div div select {
        border: 1px solid #979797;
        border-radius: 6px;
        width: 100%;
        padding: 1rem 1.25rem;
        margin-bottom: 0.75rem; }
      .CMform .container .right form div div.checkbox {
        display: -ms-flexbox;
        display: flex; }
        .CMform .container .right form div div.checkbox input {
          margin-right: 0.5rem; }
        .CMform .container .right form div div.checkbox label {
          display: block; }
      .CMform .container .right form div div.select label {
        display: block;
        margin-bottom: 0.5rem; }
      .CMform .container .right form button {
        background: #0070BA;
        color: white;
        width: 100%;
        text-align: center;
        padding: 0.75rem 0;
        border-radius: 1.25rem;
        transition: all 0.3s ease; }
        .CMform .container .right form button:hover {
          background: #014d7f;
          transition: all 0.3s ease; }
      @media screen and (max-width: 767px) {
        .CMform .container .right {
          width: 95%;
          margin: 0 auto;
          margin-top: 2rem; } }

.thumbs {
  /*margin-top: 7rem;*/
  z-index: 1;
  width: 100%; }
  .thumbs .container {
    width: 100%;
    /*background: #fe0000;*/
    /*max-width: 1095px;*/
    margin: 0 auto; }
    .thumbs .container ul {
      list-style-position: inside;
      list-style-type: none;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
          justify-content: space-between;
      -ms-flex-align: center;
          align-items: center;
      padding: 4rem 0rem; }
      .thumbs .container ul li {
        color: white;
        font-size: 18px;
        font-weight: bold;
        line-height: 28px; }
        .thumbs .container ul li img {
          width: 100%;
          height: auto;
          opacity: 1; }

*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

html {
  overflow-x: hidden; }
  @media screen and (min-width: 901px) and (max-width: 1200px) {
    html {
      font-size: 1.33333vw; } }
  @media screen and (min-width: 641px) and (max-width: 900px) {
    html {
      font-size: 2vw; } }
  @media screen and (max-width: 640px) {
    html {
      font-size: 4vw; } }
  @media screen and (min-width: 1201px) {
    html {
      font-size: 16px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Adobe Clean Reg';
  font-family: 'Paypal Big Light';
  font-weight: 400;
  font-style: normal;
  overflow-x: hidden; }

button, input, textarea {
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
          appearance: none;
  border: none;
  outline: none;
  background: none; }

button {
  cursor: pointer; }

a > img {
  border: none; }

h2.contactsHeader {
  text-align: center;
  padding: 2% 0;
  color: #c00;
  padding-bottom: 3.5rem; }

.mktoGen {
  width: 100%; }
